class FormValidator {

    constructor(form) {
        this.form = JSON.parse(JSON.stringify(form));
        this.isValid = true;
    }


    validate = () => {
        this.isValid = true;

        for (let field in this.form) {

            for (let rule in this.form[field]['validationRules']) {

                switch (rule) {
                    case 'minLength': 
                        this.form[field].valid = this.form[field].valid && this.minLengthValidator(this.form[field]['value'], this.form[field]['validationRules'][rule]);
                    break;

                    case 'isRequired': 
                        this.form[field].valid = this.form[field].valid && this.requiredValidator(this.form[field]['value']); 
                    break;
		    
                    case 'isEmail': 
                        this.form[field].valid = this.form[field].valid && this.emailValidator(this.form[field]['value']);
                    break;
                
                    default: 
                        this.form[field].valid = true;
                }
        
            }

        }
        
        for (let field in this.form) {

            if (this.form[field].valid === false) {
                this.isValid = false
            }

        }
        
        return this.isValid
        
    }
      
    minLengthValidator = (value, minLength) => {
        return value.length >= minLength;
    }

    requiredValidator = value => {
        return value.trim() !== '';	
    }

    emailValidator = value => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
    }
    

}


export default FormValidator;