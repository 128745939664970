import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import BookingSetDriverPopup from '../popups/booking_set_driver';
import moment from 'moment'
import env from '../../env'
import swal from 'sweetalert';
import DatePicker, { registerLocale } from "react-datepicker";
import Fr from "date-fns/locale/fr";


class BookingScreen extends Component {

    constructor(props) {
        super(props)

        this.state = {
            booking: null
        }

        registerLocale("Fr", Fr);

    }

    componentDidMount() {
        this.reloadBooking()
    }

    reloadBooking() {
        EZEEApi.getBooking(this.props.match.params.bookingId).then((booking) => {
            this.setState({ booking: booking })
        })
    }

    render() {

        if (this.state.booking == null) {
            return (<div></div>)
        }

        return (
            <div className="content-area-wrapper pt-5 pb-5">

                <div className="body-bg body-bg1"></div>
                <div className="body-bg body-bg2"></div>

                <div className="container">
                    <div className="row">
                        <div className="visit-trajet">
                            <NavLink to={`/bookings`} ><img src="/images/arrow-left-f.svg" alt="" />Retour aux réservations</NavLink>
                        </div>
                    </div>

                    <div className="white-bg-section shadow-shape2 mb-5">
                        <div className="row detail-table-header-border">
                            <div className="col-12">
                                <div className="detail-table-row-header detail-table-header">
                                    <h4>Détail de la réservation</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row detail-table-content-wrapper">
                            <div className="col-12">
                                <div className="detail-table full-width">
                                    <div className="detail-table-row empty-row row m-0 align-items-center">
                                    </div>
                                </div>
                                {
                                    this.state.booking.amount_to_pay_with_voucher == 0 && (
                                        <div className="detail-table-row detail-table-data-row row m-0 align-items-center" style={{ textAlign: 'center' }}>
                                            <div className="col-12 p-0">
                                                <div className="detail-table-cell">

                                                    <div className="alert alert-info" role="alert">
                                                        Cette course a été créée sans Transaction. Aucun débit n'a eu lieu grace à un code promo de -100%
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    )
                                }


                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Ref</h6></div>
                                        <div className="detail-table-cell">{this.state.booking.id}</div>

                                    </div>
                                </div>

                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Chauffeur</h6></div>

                                        {this.state.booking.Driver ?

                                            <>
                                                <div className="detail-table-cell">
                                                    <span className="t-client-icon">
                                                        <S3Picture type="user-profil" file={(this.state.booking.Driver).picture} />
                                                    </span>
                                                    {(this.state.booking.Driver).lastname} {(this.state.booking.Driver).firstname}
                                                </div>
                                                <div className="detail-table-cell right btn-media">
                                                    <NavLink to={`/drivers/${this.state.booking.Driver.Driver.id}/summary`} className="view-profile-btn " style={{ marginRight: '10px' }}>Voir le profil chauffeur</NavLink>

                                                </div>
                                            </>

                                            :
                                            <>
                                                <div className="detail-table-cell" style={{ color: 'red' }}>
                                                    {/* <span className="t-client-icon">
                                                        <S3Picture type="user-profil" file={(this.state.booking.CreditedUser || { picture: 'default.jpg' }).picture} />
                                                    </span> */}
                                                    {/* {(this.state.booking.CreditedUser || { lastname: "Booking" }).lastname} {(this.state.booking.CreditedUser || { firstname: "Booking" }).firstname}
                                                 */} Aucun chauffeur n'a été choisi
                                                </div>
                                                <div className="detail-table-cell right btn-media">

                                                    {this.state.booking.status != 9 && (
                                                        <BookingSetDriverPopup
                                                            style={{ marginRight: '10px' }}
                                                            className="detail-button popup-info_open"
                                                            booking={this.state.booking}
                                                            onClose={() => this.reloadBooking()}
                                                        >Définir un chauffeur</BookingSetDriverPopup>
                                                    )}

                                                    {/* <NavLink to={`/drivers/${this.state.booking.driver_id}/summary`} className="view-profile-btn " ></NavLink> */}

                                                </div>
                                            </>
                                        }


                                        <div className="clear"></div>
                                    </div>
                                </div>

                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Client</h6></div>
                                        <div className="detail-table-cell">
                                            <span className="t-client-icon"><S3Picture type="user-profil" file={this.state.booking.Passenger.picture} /> </span>

                                            {
                                                this.state.booking.Passenger.is_professional ?
                                                    ` Pro - ${this.state.booking.Passenger.professional_name} pour ${this.state.booking.UserProfessionalCollaborator.firstname} ${this.state.booking.UserProfessionalCollaborator.lastname}` :
                                                    ` ${this.state.booking.Passenger.firstname} ${this.state.booking.Passenger.lastname} ${this.state.booking.user_company_id ? `(${this.state.booking.UserCompany.name}) ` : ''}`
                                            }

                                            {/* {this.state.booking.DebitedUser.lastname} {this.state.booking.DebitedUser.firstname} */}
                                        </div>
                                        <div className="detail-table-cell right btn-media">
                                            <NavLink to={`/users/${this.state.booking.Passenger.id}/profil`} className="view-profile-btn ">Voir le profil</NavLink>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>

                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Statut</h6></div>
                                        <div className="detail-table-cell">

                                            {
                                                (this.state.booking.status === 2 && 'Payé sans chauffeur') ||
                                                (this.state.booking.status === 3 && 'En attente de la course') ||
                                                (this.state.booking.status === 31 && 'Le chauffeur est proche') ||
                                                (this.state.booking.status === 32 && 'Le client a été pris en charge') ||
                                                (this.state.booking.status === 4 && 'Terminée') ||
                                                (this.state.booking.status === 9 && 'Annulée') ||
                                                (this.state.booking.status === 99 && 'Remboursée')
                                            }
                                        </div>

                                        {
                                            this.state.booking.status === 2 && (
                                                <div className="detail-table-cell right btn-media">
                                                    <button className="view-profile-btn " onClick={() => {

                                                        if (this.state.booking.is_one_way) {

                                                            swal({
                                                                title: "Confirmation",
                                                                text: "Êtes vous sur de vouloir annuler cette course ?",
                                                                icon: "warning",
                                                                buttons: true,
                                                                dangerMode: true
                                                            }).then((confirmed) => {
                                                                if (confirmed) {
                                                                    EZEEApi.deleteBooking(this.state.booking.id).then(() => {
                                                                        this.reloadBooking()
                                                                    })
                                                                }
                                                            })

                                                        } else {

                                                            swal({
                                                                title: "Confirmation",
                                                                text: `Êtes vous sur de vouloir annuler cette course ? Celle-ci annulera aussi la course ${this.state.booking.booking_outbound_id ? "Aller" : "Retour"} que le client a commandé !`,
                                                                icon: "warning",
                                                                buttons: true,
                                                                dangerMode: true
                                                            }).then((confirmed) => {
                                                                if (confirmed) {
                                                                    EZEEApi.deleteBooking(this.state.booking.id).then(() => {
                                                                        EZEEApi.deleteBooking(this.state.booking.booking_outbound_id || this.state.booking.booking_return_id).then(() => {
                                                                            this.reloadBooking()
                                                                        })
                                                                    })
                                                                }
                                                            })

                                                        }

                                                    }}>Annuler la course</button>
                                                </div>
                                            )
                                        }


                                        <div className="clear"></div>
                                    </div>
                                </div>

                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Date de création</h6></div>
                                        <div className="detail-table-cell"><Moment format="LLLL">{this.state.booking.created_at}</Moment></div>
                                    </div>
                                </div>



                                {/*  */}



                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Prix de la course</h6></div>
                                        <div className="detail-table-cell">{this.state.booking.amount_to_pay / 100} €</div>
                                    </div>
                                </div>

                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Type</h6></div>
                                        <div className="detail-table-cell">
                                            {
                                                this.state.booking.is_one_way ? "Aller " : "Aller / Retour "
                                            }
                                            {
                                                this.state.booking.vehicle_type_id === 1 ? "en Moto " : "en Voiture "
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Date de la course</h6></div>
                                        <div className="detail-table-cell">
                                            {/* {moment(this.state.booking.trip_date).format('Do MMMM  YYYY, HH:mm:ss')}  */}

                                            <DatePicker
                                                locale="Fr"
                                                selected={new Date(this.state.booking.trip_date)}
                                                timeIntervals={15}
                                                showTimeSelect
                                                minDate={moment().toDate()}

                                                // minTime={(moment()).hours(8).minutes(0).toDate()}
                                                // maxTime={(moment()).hours(20).minutes(0).toDate()}
                                                timeCaption="Horaire"
                                                onChange={(date) => {
                                                    // 
                                                    this.state.booking.trip_date = date
                                                    this.setState({ booking: this.state.booking })
                                                }}
                                                className="p-textbox"
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                style={{ 'min-width': '500px' }}
                                            />



                                        </div>
                                    </div>
                                </div>

                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Adresse</h6></div>
                                        <div className="detail-table-cell">
                                            <div className="detail-user-info"><span className="client-route black">Départ</span>
                                                <span className="address-media">
                                                    <input style={{ 'min-width': "500px" }} value={this.state.booking.address_from} className="p-textbox" onChange={(e) => this.setState({ booking: { ...this.state.booking, address_from: e.target.value } })} />
                                                </span>
                                            </div>
                                            <div className="detail-user-info"><span className=" client-route black">Arrivée</span>
                                                <span className="address-media">
                                                    {/* {this.state.booking.address_to} */}
                                                    <input style={{ 'min-width': "500px" }} value={this.state.booking.address_to} className="p-textbox" onChange={(e) => this.setState({ booking: { ...this.state.booking, address_to: e.target.value } })} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {

                                    (() => {

                                        if (this.state.booking.booking_return_id) {

                                            return <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                                <div className="col-12 p-0">
                                                    <div className="detail-table-cell detail-title-cell"><h6>Cette course a un retour</h6></div>
                                                    <div className="detail-table-cell">
                                                        <span
                                                            style={{ color: "#00b456", cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.props.history.push(`/bookings/${this.state.booking.booking_return_id}`);
                                                                setTimeout(() => {
                                                                    this.reloadBooking()
                                                                }, 1)

                                                            }} > Charger la course retour (Ref°{this.state.booking.booking_return_id})</span>
                                                    </div>
                                                </div>
                                            </div>

                                        } else if (this.state.booking.booking_outbound_id) {

                                            return <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                                <div className="col-12 p-0">
                                                    <div className="detail-table-cell detail-title-cell"><h6>Cette course a un aller</h6></div>
                                                    <div className="detail-table-cell">
                                                        <span
                                                            style={{ color: "#00b456", cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.props.history.push(`/bookings/${this.state.booking.booking_outbound_id}`);
                                                                setTimeout(() => {
                                                                    this.reloadBooking()
                                                                }, 1)
                                                            }} > Charger la course aller (Ref°{this.state.booking.booking_outbound_id})</span>
                                                    </div>
                                                </div>
                                            </div>

                                        }

                                    })()

                                }

                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Top case ? </h6></div>
                                        <div className="detail-table-cell">{this.state.booking.baggages ? "Oui" : "Non"}</div>
                                    </div>
                                </div>

                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Commentaire </h6></div>
                                        <div className="detail-table-cell">{this.state.booking.comment}</div>
                                    </div>
                                </div>

                                {
                                    this.state.booking.Transaction &&

                                    <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                        <div className="col-12 p-0">
                                            <div className="detail-table-cell detail-title-cell"><h6>Transaction</h6></div>
                                            <div className="detail-table-cell"> La transaction a été effectué, vous pouvez la rembrouser  </div>
                                            <div className="detail-table-cell right btn-media">
                                                <NavLink to={`/transactions/${this.state.booking.Transaction.id}`} className="view-profile-btn " style={{ marginRight: '10px' }}>Voir la transaction</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    ([2, 3].includes(this.state.booking.status)) && (
                                        <div className="detail-table-row detail-table-data-row row m-0 align-items-center" style={{ 'text-align': 'right' }}>
                                            <div className="col-12 p-0">
                                                <div className="detail-table-cell"> <button className="detail-button popup-info_open" onClick={() => {

                                                    swal({
                                                        title: "Confirmation",
                                                        text: `Êtes vous sur de vouloir modifier cette course ? Un mail va être envoyé avec les modifications !`,
                                                        icon: "warning",
                                                        buttons: true,
                                                        dangerMode: true
                                                    }).then((confirmed) => {
                                                        if (confirmed) {
                                                            EZEEApi.putBooking(this.state.booking.id, {
                                                                address_from: this.state.booking.address_from,
                                                                address_to: this.state.booking.address_to,
                                                                trip_date: this.state.booking.trip_date
                                                            })
                                                        }
                                                    })



                                                }}> Sauvegarder les modifications </button> </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default BookingScreen;