import React, { Component } from 'react';

class TextInputComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeholder: props.placeholder,
            value: props.value,
            label: props.label
        }

    }

    onChange = (event) => {
        this.props.onChange(event)

        this.setState({
            value: event.target.value
        })

    }

    render() {

        return (
            <div className="form-group">
                <label>{this.state.label}</label>
                <input 
                    type={this.props.type} 
                    className="s-conn-textbox"  
                    name={this.props.name}
                    placeholder={this.state.placeholder}
                    value={this.state.value}
                    onChange={this.onChange}
                />
            </div>
        );

    }

}

export default TextInputComponent;