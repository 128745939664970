import React, { Component } from 'react';
import Popup from "reactjs-popup";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import Select from 'react-select';
class TransactionRefundPopup extends Component {

    constructor(props) {
        super(props)

        this.sclose = null

        this.state = {
            left_reason: '',
            choices: 1,
            driver: null,
            drivers: [],
            driver_id: null,
            index: null
        }
    }

    componentDidMount() {
        // console.log(this.props)

        EZEEApi.getDrivers({
            filter: {
                'status': 'eq true',
                'vehicle_type_id': `eq ${this.props.booking.vehicle_type_id}`
            },
            limit: 200,
            sort: 'id DESC',
            page: 1
        }).then((data) => {
            this.setState({
                drivers: data.rows
            })
        })

    }

    onLeftReasonChange(event) {
        this.setState({
            left_reason: event.target.value
        })
    }

    refund() {

        EZEEApi.putBooking(this.props.booking.id, { status: 3, driver_id: this.state.driver.user_id }).then(() => {
            this.props.onClose()
            this.sclose()
        })

    }

    handleChange = (data) => {

        this.setState({
            driver: data.value,
            driver_id: data
        });

    };

    render() {

        return (
            <Popup trigger={<button className={this.props.className}> {this.props.children} </button>} modal>

                {(close) => {
                    this.sclose = close
                    return (

                        <div className="popup-box align-center">
                            <div className="close-popup popup-info_close"><img src="/images/close-icon.svg" alt="Close" onClick={close} /></div>
                            <div className="popup-box-inner">
                                <div className="popup-title">
                                    <h3>Quel chauffeur souhaitez vous mettre sur cette course ? </h3>
                                </div>
                                <div className="popup-main">
                                    <div className="row sky-bg-section align-left">
                                        <div className="col-12">
                                            <br />

                                            <p>Veuillez choisir un chauffeur pour cette course, attention cette action est irreversible ! </p>

                                            <Select
                                                placeholder="Séléctionnez un chauffeur"
                                                value={this.state.driver_id}
                                                onChange={this.handleChange}
                                                options={this.state.drivers.map((driver, i) => {
                                                    return {
                                                        value: driver,
                                                        label: <>
                                                            <span class="t-client-icon">
                                                                <S3Picture type="user-profil" file={driver.User.picture} />
                                                            </span>
                                                            <span>
                                                                {driver.User.firstname} {driver.User.lastname}
                                                            </span>
                                                        </>
                                                    }
                                                })}
                                            />
                                            <br />

                                            <p>Après avoir définit le chauffeur, le passager sera débité et recevera un email avec la facture, soyez sur du choix que vous faites ! </p>

                                            <p>

                                                {
                                                    this.state.driver ? (

                                                        <>
                                                            <span>Société : {this.state.driver.structure_social_reason} </span> <br />
                                                            <span>Chauffeur: {this.state.driver.User.firstname} {this.state.driver.User.lastname} </span> <br />
                                                            <span> N° SIREN : {this.state.driver.structure_registration_number}</span> <br />
                                                            <span>TVA INTRA : {this.state.driver.structure_tva_number}</span> <br />
                                                        </>
                                                    ) : (
                                                            <>
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <br />
                                                            </>)

                                                }

                                                {/* Chauffeur: {driver} */}

                                            </p>




                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    {
                                        this.state.driver && <a className="theme-button1 theme-button-green" onClick={() => this.refund()} style={{ color: 'white' }}>Définir le chauffeur ! </a>
                                    }
                                </div>
                            </div>
                        </div>

                    )
                }}

            </Popup>
        );

    }
}

export default TransactionRefundPopup;