import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import UserSummaryScreen from './user_summary'
import _ from 'underscore'
class UserScreen extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activities: {}
        }
    }

    componentDidMount() {
        EZEEApi.getUserActivities(this.props.user.id).then((activities) => {

            this.setState({activities: 

                _.groupBy(activities, (item) => {

                    let date = item.created_at.substring(0,10)

                    if (date === (new Date()).toISOString().substring(0,10) ) {
                        return "Aujourd'hui"
                    } else {
                        return item.created_at.substring(0,10);
                    }

                })

            })

        })
        
    }
    
    

  render() {
    let sample = {
        'padding-left': '2px',
        'color': '#f5a623'
    }

    return (
                    
        <div className="col-md-8">
            <div className="shadow-shape2 white-bg-section pb-4">
                <div className="s-padding-sec">
                    <h3>Activité</h3>
                </div>
                <div className="notify-section notify-quick-links">

                    {Object.keys(this.state.activities).map((date) => {
                        return (
                            <div>
                            <p className="n-sub-title">{date}</p>
                            <ul>

                            {this.state.activities[date].map( (activity) => {
                                return (<li>
                                    <a>

                                        <S3Picture  type="user-profil" file={this.props.user.picture} /><span>{this.props.user.firstname} {this.props.user.lastname}</span>  {activity.message}. <br />
                                        
                                        {activity.type === "trip" && <NavLink style={sample} to={`/trips/${activity.arguments.trip.id}`} >Voir le trajet</NavLink> }
                                        {activity.type === "trip.passenger" && <NavLink style={sample} to={`/trips/${activity.arguments.passenger.trip.id}`} >Voir le trajet</NavLink> }
                                        {activity.type === "transaction" && <NavLink style={sample} to={`/transactions/${activity.arguments.transaction.id}`} >Voir la transaction</NavLink> }
                                    </a>
                                </li>)
                            })}
                                
                            </ul>
                            </div>
                        )
                    })}

                        
                        
                        {/* <div className="align-center pt-5 pb-4"><a   className="theme-button1">Voir plus d’activité</a></div> */}
                    </div>
                            
            </div>
        </div>

    );
  }
}

export default UserScreen;