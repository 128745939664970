import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import moment from 'moment';

import DatePicker from 'react-datepicker';

import { toast } from 'react-toastify';

class TransactionsScreen extends Component {

    constructor(props) {
        super(props)

        this.state = {
            voucher: {
                code: '',
                description: '',
                status: true,
                amount: 0,

                date_from: new Date(),
                date_to: moment().endOf('month').toDate()

            },
            redirect: false

        }

    }

    createVoucher() {

        let amount = this.state.voucher.amount

        if (typeof amount == 'string') {
            amount = parseFloat(amount.replace(",", "."))

            if (isNaN(amount)) {
                return toast.error('Le montant est invalide (Surement un problème de format).', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        }

        EZEEApi.postVoucher({
            ...this.state.voucher,
            ...{
                amount: amount * 100
            }
        }).then((voucher) => {
            toast.success('Le code promo a été créé.', {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({
                redirect: true
            })
        })
    }

    changeVoucher(name, value) {
        this.state.voucher[name] = value
        this.setState({
            voucher: this.state.voucher
        })
    }

    onDateFromChange(date) {
        if (date > this.state.voucher.date_to) {
            return toast.error('La date de départ ne peut pas être supérieur à la date de fin.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        this.state.voucher.date_from = date;

        this.setState({
            qs: this.state.qs
        });
    }

    onDateToChange(date) {
        if (date < this.state.voucher.date_from) {
            return toast.error('La date de fin ne peut pas être antérieur à la date de début.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        this.state.voucher.date_to = date;

        this.setState({
            qs: this.state.qs
        });
    }

    formIsValid() {

        return (
            this.state.voucher.code != '' &&
            this.state.voucher.description != '' &&
            this.state.voucher.amount != 0
        )

    }

    render() {

        if (this.state.redirect === true) {
            return (<Redirect to='/vouchers'></Redirect>)
        }

        return (
            <div className="content-area-wrapper pt-5 pb-5">
                <div className="body-bg body-bg1"></div>
                <div className="body-bg body-bg2"></div>

                <div className="container">
                    <div className="row">
                        <div className="visit-trajet mb-5 col-12">

                            <NavLink to={`/vouchers`}>
                                <img src="/images/arrow-left-f.svg" alt="" />Retour aux codes promo
                                </NavLink>
                        </div>
                        <div className="col-12 pt-3 pb-4">
                            <h3>Création d’un code promo</h3>
                        </div>
                    </div>

                    <div className="white-bg-section shadow-shape2 mb-5 pt-3 mt-minus1 col-12">

                        <div className={`s-tab-content s-padding-sec3 black tab-content1-show`}>
                            <form className="pt-3" action="#" method="post">

                                <div className="row align-items-center">
                                    <div className="col-md-3 col-sm-12 pb-3 pt-3" ><p>Statut</p></div>
                                    <div className="col-md-9 col-sm-12 pb-3 pt-3" >
                                        <input type="radio" className="s-ezee-radio" name="p-status" id="actif" checked={this.state.voucher.status === true} /><label htmlFor="actif" onClick={() => { this.changeVoucher('status', true) }}>Actif</label>
                                        <input type="radio" className="s-ezee-radio" name="p-status" id="non-actif" checked={this.state.voucher.status === false} /><label htmlFor="non-actif" onClick={() => { this.changeVoucher('status', false) }}>Non actif</label>
                                    </div>
                                </div>

                                <hr />

                                <div className="row align-items-center">
                                    <div className="col-md-3 col-sm-12 pb-3 pt-3" ><p>Nom du code</p></div>
                                    <div className="col-md-9 col-sm-12 pb-3 pt-3" ><input type="text" className="p-textbox" placeholder="ex : OFF20" value={this.state.voucher.code} onChange={(e) => { this.changeVoucher('code', e.target.value) }} /></div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-md-3 col-sm-12 pb-3 pt-3" ><p>Raison</p></div>
                                    <div className="col-md-9 col-sm-12 pb-3 pt-3" ><input type="text" className="p-textbox" placeholder="ex : offre de bienvenue" value={this.state.voucher.description} onChange={(e) => { this.changeVoucher('description', e.target.value) }} /></div>
                                </div>

                                <hr />

                                <div className="row align-items-center">
                                    <div className="col-md-3 col-sm-12 pb-3 pt-3" ><p>Validité</p></div>
                                    <div className="col-md-9 col-sm-12 pb-3 pt-3" >
                                        <div className="row">
                                            <div className="col-sm-6 mb-3">

                                                <div className="dropdown-arrow date-picker-wrap"><span>Du</span>

                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        className="p-textbox datepicker"
                                                        selected={this.state.voucher.date_from}
                                                        onChange={this.onDateFromChange.bind(this)}
                                                        locale="fr"
                                                    />

                                                    {/* <input type="text" className="" placeholder="Veuillez indiquer le nom du client"  /> */}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <div className="dropdown-arrow date-picker-wrap"><span>Au</span>
                                                    {/* <input type="text" className="p-textbox datepicker" placeholder="Veuillez indiquer le nom du client"  /> */}
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        className="p-textbox datepicker"
                                                        selected={this.state.voucher.date_to}
                                                        onChange={this.onDateToChange.bind(this)}
                                                        locale="fr"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="row align-items-center">
                                    <div className="col-md-3 col-sm-12 pb-3 pt-3" ><p>Montant en Euros </p></div>
                                    <div className="col-md-9 col-sm-12 pb-3 pt-3"
                                    ><input
                                            type="number"
                                            className="p-textbox"
                                            placeholder="Veuillez indiquer le montant"
                                            value={this.state.voucher.amount}
                                            // onKeyPress={(event) => {
                                            //     const keyCode = event.keyCode || event.which;
                                            //     const keyValue = String.fromCharCode(keyCode);
                                            //     if (!(/[0-9]/.test(keyValue))) {
                                            //         event.preventDefault();
                                            //     }
                                            // }}
                                            onChange={(e) => {
                                                this.changeVoucher('amount', e.target.value)
                                            }}
                                        /></div>
                                </div>

                                <div className="row align-items-center mt-5">
                                    <div className="col-md-12 col-sm-12 pb-3 align-right">
                                        <input
                                            type="button" className="p-submit-btn theme-button1" value="Valider le code"
                                            disabled={!this.formIsValid()} onClick={this.createVoucher.bind(this)} /></div>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default TransactionsScreen;