import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Pagination from '../Pagination';
import TableComponent from '../TableComponent';
import Moment from 'react-moment';
import LoadingOverlay from 'react-loading-overlay';
import VoucherEditPopup from '../popups/voucher_edit'

class TransactionsScreen extends TableComponent {

	componentDidMount() {
		super.componentDidMount();
		this.reloadData(true);
	}

	reloadData(instant) {
		super.reloadData(() => {
			EZEEApi.getVouchers(this.state.qs).then((transactions) => {
				this.setState({
					count: transactions.count,
					data: transactions.rows,
					called: true
				});
			});
		}, instant);
	}

	render() {
		if (!this.state.called) {
			return <div />;
		}

		return (
			<LoadingOverlay active={this.state.contentLoading} spinner text="Chargement des données...">
				<div className="content-area-wrapper pt-5 pb-5">
					<div className="body-bg body-bg1" />
					<div className="body-bg body-bg2" />
					<div className="body-bg body-bg3" />

					<div className="container">

						<div className="row align-items-center">
							<div className="col-lg-5">
								<h2>Code promo ({this.state.count})</h2>
							</div>
							<div className="col-lg-3 align-center">
								<NavLink
									className="exp-btn text-center"
									to={`/vouchers/create`}
								> Créer un code promo </NavLink>
							</div>
							<div className="col-lg-4 search-form">
								<form action="#" method="post" onSubmit={(event) => event.preventDefault()}>
									<input
										type="text"
										className="s-textbox"
										placeholder="Rechercher par Reference"
										onChange={this.onSearchValueChange.bind(this)}
										value={this.state.searchValue}
									/>
									<input type="submit" className="s-search-btn" />
								</form>
							</div>
						</div>

						<div className="row ml-0 mr-0 s-filter-sec mt-4 mb-4 align-items-center">
							{this.generateFilter([20, 50, 100])}
							{this.generateDateFilter()}
						</div>

						{this.state.count !== 0 ? (
							<div>
								<div className="row s-table-content-wrapper">
									<div className="col-12">
										<div className="d-table full-width s-table-content">
											<div className="d-table-row table-header">
												{this.generateTableHeader({ name: 'REF', column: 'id' })}
												{this.generateTableHeader({
													name: 'CODE'
												})}
												{this.generateTableHeader({ name: 'DATE', column: 'created_at' })}
												{this.generateTableHeader({
													name: 'UTILISATIONS',
													column: 'usage_count'
												})}
												{this.generateTableHeader({ name: 'MONTANT', column: 'amount' })}
												{this.generateTableHeader({
													name: <font>STATUT</font>
												})}
												{this.generateTableHeader({ name: <span>&nbsp;</span> })}
											</div>

											{this.state.data.map((voucher, i) => {
												return (
													<div className="d-table-row table-data-row" key={voucher.id}>
														<div className="d-table-cell">{voucher.id}</div>
														<div className="d-table-cell">{voucher.code}</div>
														<div className="d-table-cell">
															<Moment format="DD/MM/YYYY">
																{voucher.created_at}
															</Moment>
														</div>
														<div className="d-table-cell">
															{voucher.usage_count}
														</div>
														<div className="d-table-cell">

															{
																(() => {
																	if (voucher.discount_type == 1) {
																		return `- ${voucher.amount / 100} €`
																	} else if (voucher.discount_type == 2) {
																		return `- ${voucher.amount} %`
																	}
																})()
															}

														</div>
														<div className="d-table-cell">
															{voucher.status ? 'Actif' : 'Non actif'}
														</div>
														<div className="d-table-cell">
															{/* <NavLink
																className="voir-button"
																to={`/vouchers/${voucher.id}`}
															> Voir </NavLink> */}
															<VoucherEditPopup
																className="voir-button"
																voucher={voucher}
																onClose={(data) => {
																	this.reloadData(true);
																}}
															>
																Voir
															</VoucherEditPopup>

														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>

								<Pagination
									count={this.state.count}
									page={this.state.qs.page}
									limit={this.state.qs.limit}
									onPageChange={(page) => {
										this.onPageChange(page);
									}}
								/>
							</div>
						) : (
								<div className="TableComponentsEmpty">
									<img src="/images/Icon_search.png" alt="icon search" />
									<span>Nous sommes désolés,</span>
									<span>Aucun résultat n'a été trouvé !</span>
								</div>
							)}
					</div>
				</div>
			</LoadingOverlay>
		);
	}
}

export default TransactionsScreen;
