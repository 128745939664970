import React, { Component } from 'react';
import Popup from 'reactjs-popup';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import EZEEApi from '../../services/EZEEApi';
import { toast } from 'react-toastify';

class UserBanPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reason: ''
		};
	}

	unban() {
		EZEEApi.unbanUser(this.props.user.id).then(() => {
			this.props.onClose();
			this.sclose();
			toast.success("L'utilisateur a été débannis.", {
				position: toast.POSITION.TOP_RIGHT
			});
		});
	}

	render() {
		return (
			<Popup trigger={this.props.children} modal>
				{(close) => {
					this.sclose = close;
					return (
						<div className="popup-box align-center">
							<div className="close-popup popup-info_close">
								<img
									src="/images/close-icon.svg"
									alt="Close"
									onClick={() => {
										this.props.onClose();
										close();
									}}
								/>
							</div>
							<div className="popup-box-inner">
								<div className="popup-title">
									<div className="p-title-icon">
										<img src="/images/cross-icon.svg" alt="" />
									</div>
									<h3>Vous avez banni cet utilisateur, en voici la raison :</h3>
								</div>
								<div className="popup-main two-btns-wrap">
									<div className="s-box-text s-box-edit-icon mt-5 mb-5">
										<p>{this.props.user.UserBan.reason}</p>
									</div>

									<a
										style={{ color: 'white' }}
										className="theme-button1 theme-button-red"
										onClick={() => {
											this.unban();
										}}
									>
										Débannir cet utilisateur
									</a>
									<a
										style={{ color: 'white' }}
										className="theme-button1 theme-button-gray"
										onClick={() => {
											this.props.onClose();
											close();
										}}
									>
										Quitter
									</a>
								</div>
							</div>
						</div>
					);
				}}
			</Popup>
		);
	}
}

export default UserBanPopup;
