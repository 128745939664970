import React, { Component } from 'react';
import Popup from 'reactjs-popup';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import { toast } from 'react-toastify';

class UserBanPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reason: ''
		};
	}

	ban() {
		EZEEApi.banUser(this.props.user.id, this.state.reason).then(() => {
			this.props.onClose();
			this.sclose();
			toast.success("L'utilisateur a été bannis.", {
				position: toast.POSITION.TOP_RIGHT
			});
		});
	}

	render() {
		return (
			<Popup trigger={this.props.children} modal>
				{(close) => {
					this.sclose = close;
					return (
						<div className="popup-box align-center">
							<div className="close-popup popup-info_close">
								<img
									src="/images/close-icon.svg"
									alt="Close"
									onClick={() => {
										this.props.onClose();
										close();
									}}
								/>
							</div>
							<div className="popup-box-inner">
								<div className="popup-title">
									<div className="p-title-icon">
										<img src="/images/cross-icon.svg" alt="" />
									</div>
									<h3>Souhaitez-vous bannir cet utilisateur ?</h3>
								</div>
								<div className="popup-main two-btns-wrap">
									<textarea
										className="s-textbox s-choice-textarea"
										name="your-choice"
										value={this.state.reason}
										onChange={(event) => this.setState({ reason: event.target.value })}
										placeholder="Raison du ban"
									/>
									<button
										style={{ color: 'white' }}
										className="theme-button1 theme-button-red"
										onClick={() => {
											this.ban();
										}}
										disabled={this.state.reason === ''}
										style={{ cursor: this.state.reason === '' ? 'no-drop' : 'pointer' }}
									>
										Bannir cet utilisateur
									</button>
									<button
										style={{ color: 'white' }}
										className="theme-button1 theme-button-gray"
										onClick={() => {
											this.props.onClose();
											close();
										}}
									>
										La prochaine fois peut-être.
									</button>
								</div>
							</div>
						</div>
					);
				}}
			</Popup>
		);
	}
}

export default UserBanPopup;
