import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';

import Pagination from '../Pagination';
import TableComponent from '../TableComponent';

import Moment from 'react-moment';

import LoadingOverlay from 'react-loading-overlay';
class DriversScreen extends TableComponent {
	componentDidMount() {
		super.componentDidMount();
		this.reloadData(true);
	}

	reloadData(instant) {
		super.reloadData(() => {
			EZEEApi.getDrivers(this.state.qs).then((drivers) => {
				this.setState({
					count: drivers.count,
					data: drivers.rows,
					called: true
				});
			});
		}, instant);
	}

	searchValueChanged(value) {
		this.onFilterChange('firstname', `%${value}%`, 'iLike');
		this.onFilterChange('lastname', `%${value}%`, 'iLike');
		this.onFilterChange('professional_name', `%${value}%`, 'iLike');
	}

	render() {
		if (!this.state.called) {
			return <div />;
		}

		return (
			<LoadingOverlay active={this.state.contentLoading} spinner text="Chargement des données...">
				<div className="content-area-wrapper pt-5 pb-5">
					<div className="body-bg body-bg1" />
					<div className="body-bg body-bg2" />
					<div className="body-bg body-bg3" />

					<div className="container">
						<div className="row">
							<div className="col-lg-8">
								<h2>Chauffeurs ({this.state.count})</h2>
							</div>
							<div className="col-lg-4 search-form">
								<form action="#" method="post">
									<input
										type="text"
										className="s-textbox"
										placeholder="Rechercher un chauffeur"
										onChange={this.onSearchValueChange.bind(this)}
										value={this.state.searchValue}
									/>
									<input type="submit" value="SEND" className="s-search-btn" />
								</form>
							</div>
						</div>

						<div className="row ml-0 mr-0 s-filter-sec mt-4 mb-4 align-items-center">
							{this.generateFilter([20, 50, 100])}
							{this.generateDateFilter()}
						</div>

						{this.state.count !== 0 ? (
							<div>
								<div className="row s-table-content-wrapper">
									<div className="col-12">
										<div className="d-table full-width s-table-content">
											<div className="d-table-row table-header">
												<div className="d-table-cell">
													<input type="checkbox" className="s-checkbox" id="user-id" />
													<label htmlFor="user-id">&nbsp;</label>
												</div>

												{this.generateTableHeader({ name: 'REF', column: 'id' })}
												{this.generateTableHeader({ name: 'NOM' })}
												{this.generateTableHeader({ name: 'CRÉATION', column: 'created_at' })}
												{this.generateTableHeader({ name: 'NOTE', column: 'driver_rate' })}
												{this.generateTableHeader({ name: 'COURSES', column: 'trips_amount' })}
												{this.generateTableHeader({
													name: 'CA GÉNÉRÉ',
													column: 'credited_amount'
												})}
												{this.generateTableHeader({
													name: 'VEHICULE',
													column: 'vehicle_type_id'
												})}
												{this.generateTableHeader({ name: 'STATUS', column: 'status' })}

												{this.generateTableHeader({ name: <span>&nbsp;</span> })}
											</div>

											{this.state.data.map((driver) => {
												return (
													<div className="d-table-row table-data-row">
														<div className="d-table-cell">
															<input
																type="checkbox"
																className="s-checkbox"
																id="user-id1"
															/>
															<label htmlFor="user-id1">&nbsp;</label>
														</div>
														<div className="d-table-cell">{driver.id} </div>
														<div className="d-table-cell s-user-info">
															<span className="t-client-icon">
																<NavLink to={`/drivers/${driver.id}/summary`}>
																	{' '}
																	<S3Picture
																		type="user-profil"
																		file={driver.User.picture}
																	/>{' '}
																</NavLink>
															</span>
															<NavLink to={`/drivers/${driver.id}/summary`}>
																{' '}
																{driver.User.firstname} {driver.User.lastname}{' '}
															</NavLink>
														</div>
														<div className="d-table-cell">
															<Moment format="DD/MM/YYYY">{driver.created_at}</Moment>{' '}
														</div>
														<div className="d-table-cell">
															{Math.round(driver.driver_rate * 100) / 100} / 5
														</div>
														<div className="d-table-cell">{driver.trips_amount}</div>
														<div className="d-table-cell">
															{driver.credited_amount / 100} €
														</div>
														<div className="d-table-cell">
															{driver.vehicle_type_id === 1 ? 'Moto' : 'Voiture'}
														</div>
														<div className="d-table-cell">
															{driver.status ? (
																'Actif'
															) : (
																`Inactif (${Object.keys(
																	driver.DriverDocument
																).filter((a, b) => {
																	if (
																		(a === 'card_id' &&
																			driver.DriverDocument['card_id'] !== '')
																		||
																		(a === 'card_id_back' &&
																			driver.DriverDocument['card_id_back'] !== '')
																		||
																		(a === 'card_pro_front' &&
																			driver.DriverDocument['card_pro_front'] !== '')
																		||
																		(a === 'card_pro_back' &&
																			driver.DriverDocument['card_pro_back'] !== '')
																		||
																		(a === 'card_driver_license' &&
																			driver.DriverDocument['card_driver_license'] !== '')
																		||
																		(a === 'picture' &&
																			driver.DriverDocument['picture'] !== '')
																		||
																		(a === 'dwelling' &&
																			driver.DriverDocument['dwelling'] !== '')
																		||
																		(a === 'medical_checkup' &&
																			driver.DriverDocument['medical_checkup'] !== '')
																		||
																		(a === 'insurance' &&
																			driver.DriverDocument['insurance'] !== '')
																		||
																		(a === 'licence_atout' &&
																			driver.DriverDocument['licence_atout'] !== '')
																		||
																		(a === 'kbis' &&
																			driver.DriverDocument['kbis'] !== '')
																		||
																		(a === 'rib_company' &&
																			driver.DriverDocument['rib_company'] !== '')
																		||
																		(a === 'vehicle_card' &&
																			driver.DriverDocument['vehicle_card'] !== '')
																		||
																		(a === 'vehicle_picture' &&
																			driver.DriverDocument['vehicle_picture'] !== '')
																		||
																		(a === 'vehicle_plate_number' &&
																			driver.DriverDocument['vehicle_plate_number'] !== '')
																		||
																		(a === 'vehicle_insurance' &&
																			driver.DriverDocument['vehicle_insurance'] !== '')

																	) {
																		return true;
																	}

																	return false;
																}).length}/16 docs) `
															)}
														</div>
														<div className="d-table-cell">
															<NavLink
																className="voir-button"
																to={`/drivers/${driver.id}/summary`}
															>
																Profil
															</NavLink>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>

								<Pagination
									count={this.state.count}
									page={this.state.qs.page}
									limit={this.state.qs.limit}
									onPageChange={(page) => {
										this.onPageChange(page);
									}}
								/>
							</div>
						) : (
							<div className="TableComponentsEmpty">
								<img src="/images/Icon_search.png" alt="icon search" />
								<span>Nous sommes désolés,</span>
								<span>Aucun résultat n'a été trouvé !</span>
							</div>
						)}
					</div>
				</div>
			</LoadingOverlay>
		);
	}
}

export default DriversScreen;
