import React, { Component } from 'react';
import env from '../env'
class S3Picture extends Component {

    constructor(props) {
        super(props)
        this.state = {endpoint: env.s3BaseUrl}
        
    }

    render() {
        return <img src={this.state.endpoint + this.props.type + "/" + this.props.file} alt={this.props.type} />
    }

}

export default S3Picture;