import React, { Component } from 'react';
import assign from 'object-assign';
import ReactDOM from 'react-dom';
import moment from 'moment';
import Moment from 'react-moment';

import DatePicker from 'react-datepicker';

import { toast } from 'react-toastify';

import 'react-datepicker/dist/react-datepicker.css';
class TableComponent extends Component {
	constructor(props) {
		super(props);

		this.typingTimer = null;

		this.state = {
			filter_menu_open: false,
			called: false,
			contentLoading: true,
			date: {
				from: new Date(2019, 0, 1),
				to: moment().endOf('month').toDate()
			},

			searchValue: '',

			count: 0,
			data: [],

			qs: {
				filter: {},
				limit: 20,
				sort: 'id DESC',
				page: 1
			}
		};
	}

	extendState(state) {
		assign(this.state, state);
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside, true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside, true);
	}

	handleClickOutside = (event) => {
		if (this.nodeDateFilter && !this.nodeDateFilter.contains(event.target)) {
			if (this.state.filter_menu_open == true) {
				this.hideShowDateFilter();
			}
		}
	};

	reloadData(cb, instant) {
		this.state.contentLoading = true;
		clearTimeout(this.typingTimer);
		this.typingTimer = setTimeout(() => {
			cb();
			this.state.contentLoading = false;
		}, instant ? 0 : 500);
	}

	onPageChange(page) {
		const max = Math.ceil(this.state.count / this.state.qs.limit);

		if (page < 1) {
			page = 1;
		}

		if (page > max) {
			page = max;
		}

		this.state.qs.page = page;

		this.setState({
			qs: this.state.qs
		});

		this.reloadData();
	}

	onDateFromChange(date) {
		if (date > this.state.date.to) {
			return toast.error('La date de départ ne peut pas être supérieur à la date de fin.', {
				position: toast.POSITION.TOP_RIGHT
			});
		}

		this.state.date.from = date;

		this.setState({
			qs: this.state.qs
		});
	}

	onDateToChange(date) {
		if (date < this.state.date.from) {
			return toast.error('La date de fin ne peut pas être antérieur à la date de début.', {
				position: toast.POSITION.TOP_RIGHT
			});
		}

		this.state.date.to = date;

		this.setState({
			qs: this.state.qs
		});
	}

	onDateChangeSubmit() {
		this.onFilterChange(
			'created_at',
			`${moment(this.state.date.from).format('YYYY-MM-DD')} ${moment(this.state.date.to).format('YYYY-MM-DD')}`,
			'between'
		);
		this.hideShowDateFilter();
	}

	onFilterChange(key, value, type, erase) {
		if (value === undefined || value === '' || value === null) {
			delete this.state.qs.filter[key];
		} else {
			this.state.qs.filter[key] = `${type ? type : 'eq'} ${value}`;
		}

		if (erase) {
			this.state.qs.filter = { [key]: `${type ? type : 'eq'} ${value}` };
		}

		this.setState({
			qs: this.state.qs
		});

		this.reloadData();
	}

	onSortChange(key) {
		if (this.state.qs.sort == `${key} DESC`) {
			this.state.qs.sort = `${key} ASC`;
		} else {
			this.state.qs.sort = `${key} DESC`;
		}

		this.setState({
			qs: this.state.qs
		});

		this.reloadData();
	}

	onLimitChange(limit) {
		this.state.qs.limit = limit;
		this.state.qs.page = 1;

		this.setState({
			qs: this.state.qs
		});

		this.reloadData();
	}

	onSearchValueChange(event) {
		this.setState({
			searchValue: event.target.value
		});

		this.searchValueChanged(event.target.value);
	}

	searchValueChanged(value) {
		this.onFilterChange('id', value);
	}

	hideShowDateFilter() {
		this.setState({ filter_menu_open: !this.state.filter_menu_open }, () => {
			setTimeout(() => {
				this.setState({
					filter_menu_open_overflow: this.state.filter_menu_open ? 'visible' : 'hidden'
				});
			}, this.state.filter_menu_open ? 700 : 0);
		});
	}

	generateDateFilter() {
		return (
			<div className="col-lg-5 pr-0 s-date-filter-wrap" ref={(n) => (this.nodeDateFilter = n)}>
				<div className="s-date-filter">
					<span className="adjust-filter">Filtres</span>
					<span
						className="clickable s-filter-for-date"
						onClick={() => {
							this.hideShowDateFilter();
						}}
					>
						Du{' '}
						<span className="s-start-date">
							<Moment format={'DD/MM/YYYY'}>{this.state.date.from}</Moment>
						</span>{' '}
						Au{' '}
						<span className="s-end-date">
							<Moment format={'DD/MM/YYYY'}>{this.state.date.to}</Moment>
						</span>
					</span>
					<div
						className="date-filter-tooltip cross-tooltip-content"
						style={{
							height: this.state.filter_menu_open ? '145px' : '0px',
							border: this.state.filter_menu_open ? '1px solid #2BBAE5' : 0,
							overflow: this.state.filter_menu_open_overflow
						}}
					>
						<h4>Plage de dates</h4>
						<div className="tooltip-shadow s-date-filter-input">
							<span>
								<span>Du</span>
								<DatePicker
									dateFormat="dd/MM/yyyy"
									className="filter-date-field start-datepicker"
									selected={this.state.date.from}
									onChange={this.onDateFromChange.bind(this)}
									locale="fr"
								/>
							</span>
							<span>
								<span>Jusqu’au</span>
								<DatePicker
									dateFormat="dd/MM/yyyy"
									className="filter-date-field start-datepicker"
									selected={this.state.date.to}
									onChange={this.onDateToChange.bind(this)}
									locale="fr"
								/>
							</span>
							<a
								className="theme-button1"
								style={{ color: 'white' }}
								onClick={this.onDateChangeSubmit.bind(this)}
							>
								Valider
							</a>
						</div>
					</div>
					<div className="clear" />
				</div>
			</div>
		);
	}

	generateTableHeader({ column = null, name = null }) {
		if (column) {
			return (
				<div className="d-table-cell clickable">
					<span
						onClick={() => this.onSortChange(column)}
						className={`s ${this.state.qs.sort === `${column} ASC` ||
							this.state.qs.sort === `${column} DESC`
							? this.state.qs.sort === `${column} ASC` ? 's-down-arrow' : 's-up-arrow'
							: null}`}
					>
						{name}
					</span>
				</div>
			);
		} else {
			return (
				<div className="d-table-cell">
					<span className="s">{name}</span>
				</div>
			);
		}
	}

	generateFilter(filters) {
		return (
			<div className="col-lg-7 pl-0">
				<div className="s-filter-pagination">
					<ul>
						<li>
							<span>Afficher par :</span>
						</li>
						{
							filters.map((filter) => {
								return (
									<li className={`clickable ${this.state.qs.limit === filter ? 's-page-selected' : ''}`}>
										<font onClick={() => this.onLimitChange(filter)}>{filter}</font>
									</li>
								);
							})
						}
					</ul>
				</div>
			</div>
		);
	}
}

export default TableComponent;
