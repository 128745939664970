import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Pagination from '../Pagination';
import TableComponent from '../TableComponent';
import Moment from 'react-moment';
import LoadingOverlay from 'react-loading-overlay';
import { CSVLink } from "react-csv";
import moment from 'moment';
import WithdrawEditPopup from './../popups/withdraw_edit'

class WithdrawsScreen extends TableComponent {

	componentDidMount() {
		super.componentDidMount();
		this.reloadData(true);

		this.extendState({
			dataToDownload: [],
			summary: {
				count_true: 0,
				count_false: 0,
				amount_true: 0,
				amount_false: 0
			}
		});

	}


	reloadData(instant) {

		EZEEApi.getWidthdrawsSummary().then((summary) => {
			this.setState({ summary: summary });
		});

		EZEEApi.getWithdraws({ filter: { 'status': 'eq false' } }).then((withdraws) => {

			this.setState({
				dataToDownload: withdraws.rows.map((withdraw) => {
					return {
						ref: withdraw.id,
						mois: moment(withdraw.date).format('MMMM - YYYY'),

						nom: withdraw.Driver.User.firstname + ' ' + withdraw.Driver.User.lastname,
						montant: (withdraw.total_driver_fees_amount / 100) + " €",
						'nom complet banquaire': withdraw.fullanme,
						IBAN: withdraw.IBAN,
						BIC: withdraw.BIC,
					}
				})
			})

		})

		super.reloadData(() => {
			EZEEApi.getWithdraws(this.state.qs).then((withdraws) => {
				this.setState({
					count: withdraws.count,
					data: withdraws.rows,
					called: true
				});
			});
		}, instant);

	}

	searchValueChanged(value) {
		this.onFilterChange('id', value);
	}

	render() {
		if (!this.state.called) {
			return <div />;
		}

		return (
			<LoadingOverlay active={this.state.contentLoading} spinner text="Chargement des données...">
				<div className="content-area-wrapper pt-5 pb-5">
					<div className="body-bg body-bg1" />
					<div className="body-bg body-bg2" />
					<div className="body-bg body-bg3" />

					<div className="container">

						<div>
							<div className="row mb-4 align-items-center">
								<div className="col-md-9">
									<h2>Statistiques globales</h2>
								</div>
							</div>

							<div className="row pt-3 pb-5 align-center">
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{this.state.summary.count_true}</h1>
										<p>Virements effectués</p>
									</div>
								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box m-auto white-bg-section shadow-shape2">
										<h1>{this.state.summary.count_false}</h1>
										<p>Virements en attente</p>
									</div>
								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{(this.state.summary.amount_true / 100).toFixed(2)} &euro;</h1>
										<p>Montant total reversé aux chauffeur</p>
									</div>
								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{(this.state.summary.amount_false / 100).toFixed(2)} &euro;</h1>
										<p>Montant total en attente </p>
									</div>
								</div>
							</div>
						</div>

						<div className="row align-items-center">
							<div className="col-lg-5">
								<h2>Virements ({this.state.count})</h2>
							</div>


							<div class="col-lg-3">

								<CSVLink
									class="exp-btn text-center"
									data={this.state.dataToDownload}
									filename={'EZEE-export-withdraws.csv'}
								>
									Exporter la liste des virements non traités
								</CSVLink>

							</div>

							<div className="col-lg-4 search-form">
								<input
									type="text"
									className="s-textbox"
									placeholder="Rechercher un virement"
									onChange={this.onSearchValueChange.bind(this)}
									value={this.state.searchValue}
								/>
								<input type="submit" value="SEND" className="s-search-btn" />
							</div>

						</div>



						<div className="row ml-0 mr-0 s-filter-sec mt-4 mb-4 align-items-center">
							{this.generateFilter([20, 50, 100])}
							{this.generateDateFilter()}
						</div>

						{this.state.count !== 0 ? (
							<div>
								<div className="row s-table-content-wrapper">
									<div className="col-12">
										<div className="d-table full-width s-table-content">
											<div className="d-table-row table-header">
												{this.generateTableHeader({ name: 'MOIS' })}
												{this.generateTableHeader({ name: 'REF', column: 'id' })}
												{this.generateTableHeader({ name: 'NOM' })}
												{this.generateTableHeader({ name: 'IBAN', column: 'IBAN' })}
												{this.generateTableHeader({ name: 'MTT', column: 'total_driver_fees_amount' })}
												{this.generateTableHeader({ name: 'DATE', column: 'created_at' })}
												{this.generateTableHeader({
													name: <font>STATUT</font>,
													column: 'status'
												})}
												{this.generateTableHeader({ name: <span>&nbsp;</span> })}
											</div>

											{this.state.data.map((withdraw, i) => {
												return (
													<div className="d-table-row table-data-row" key={withdraw.id}>
														<div className="d-table-cell">
															{moment(withdraw.from_date).format("DD MMMM")} - {moment(withdraw.to_date).format("DD MMMM YYYY")}
														</div>
														<div className="d-table-cell">{withdraw.id}</div>
														<div className="d-table-cell s-user-info">
															<div className="d-block mb-1">
																<span className="t-client-icon">
																	{' '}
																	<S3Picture
																		type="user-profil"
																		file={withdraw.Driver.User.picture}
																	/>
																</span>{' '}
																{withdraw.Driver.User.firstname}{' '}
																{withdraw.Driver.User.lastname}
															</div>

														</div>

														<div className="d-table-cell">
															{withdraw.IBAN}
														</div>

														<div className="d-table-cell">
															{withdraw.total_driver_fees_amount / 100} &euro;
														</div>


														<div className="d-table-cell">
															<Moment format="DD/MM/YYYY">
																{withdraw.created_at}
															</Moment>
														</div>

														<div className="d-table-cell">
															{`${withdraw.status === false
																? 'En attente'
																: 'Accepté'} `}{' '}
														</div>

														<div class="d-table-cell">
															{/* <NavLink
																className="voir-button"
																to={`/withdraws/${withdraw.id}`}
															>
																Voir
															</NavLink> */}
															<WithdrawEditPopup
																className="voir-button"
																withdraw={withdraw}
																onClose={(data) => {
																	this.reloadData(true);
																}}
															>
																Voir
															</WithdrawEditPopup>
														</div>

													</div>
												);
											})}
										</div>
									</div>
								</div>

								<Pagination
									count={this.state.count}
									page={this.state.qs.page}
									limit={this.state.qs.limit}
									onPageChange={(page) => {
										this.onPageChange(page);
									}}
								/>
							</div>
						) : (
								<div className="TableComponentsEmpty">
									<img src="/images/Icon_search.png" alt="icon search" />
									<span>Nous sommes désolés,</span>
									<span>Aucun résultat n'a été trouvé !</span>
								</div>
							)}
					</div>
				</div>
			</LoadingOverlay>
		);
	}
}

export default WithdrawsScreen;
