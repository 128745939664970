import { Component } from 'react';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import FormValidator from './FormValidator';


class ComponentWithForm extends Component {

    // constructor(props) {
    //     super(props)
    // }

    onChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.state.formControls[name].value = value
        this.setState(this.state);

        // const validator = new FormValidator(this.state.formControls)
        // const isValid = validator.validate()

        // this.setState({
        //     formValid: isValid
        // })
    }

    handleSubmit = (event) => {

        event.preventDefault()
        const validator = new FormValidator(this.state.formControls)
        
        if (validator.validate()) {
            this.formSubmit()
        }

    }

    formSubmit = () => {
        //interface
    }

    getFormData = () => {

        var obj = {}

        for (var field in this.state.formControls) {
            obj[field] = this.state.formControls[field].value
        }

        return obj
    }

}

export default ComponentWithForm;