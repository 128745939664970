import React, { Component } from 'react';

class Pagination extends Component {

    onPageChange(page) {
        this.props.onPageChange(page)
    }

    render() {

        if (this.props.count == 0) {
            return (<div className="row pt-5"></div>)
        }

        return (
            <div className="row pt-5">
                <div className="col-12 s-pagination">
                    <ul>

                        <li className="s-prev-link">
                            <span className="clickable" onClick={() => this.onPageChange(this.props.page - 1)}>Prev</span>
                        </li>

                        {Array.apply(0, Array(Math.ceil(this.props.count / this.props.limit))).map((x, i) => {

                            if ((i + 1) >= (this.props.page - 1) && (i + 1) <= (this.props.page + 5)) {

                                return (

                                    <li key={i}>
                                        <span
                                            className={`clickable ${(i + 1) === this.props.page ? 'active' : null}`}
                                            onClick={() => this.onPageChange(i + 1)}
                                        >{i + 1}</span>
                                    </li>
                                )

                            }

                        })}

                        <li className="s-next-link">
                            <span className="clickable" onClick={() => this.onPageChange(this.props.page + 1)}>Next</span>
                        </li>

                    </ul>
                </div>
            </div>
        );

    }

}

export default Pagination;