import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import UserSummaryScreen from './user_summary';
import { toast } from 'react-toastify';

import swal from 'sweetalert';

import ReactS3Uploader from 'react-s3-uploader';

import S3FilePopup from '../popups/s3file';

import DatePicker from 'react-datepicker';
import moment from 'moment';



class UserScreen extends Component {
	uploaders = {};

	timeout = null;

	constructor(props) {
		super(props);

		this.state = {
			driver: props.driver,
			driverDocument: {
				...props.driver.DriverDocument,
				card_id_expiration: props.driver.DriverDocument.card_id_expiratio ? new Date(props.driver.DriverDocument.card_id_expiration) : null,
				card_pro_front_expiration: props.driver.DriverDocument.card_pro_front_expiration ? new Date(props.driver.DriverDocument.card_pro_front_expiration) : null,
				card_driver_license_expiration: props.driver.DriverDocument.card_driver_license_expiration ? new Date(props.driver.DriverDocument.card_driver_license_expiration) : null,
				insurance_expiration: props.driver.DriverDocument.insurance_expiration ? new Date(props.driver.DriverDocument.insurance_expiration) : null,
				vehicle_insurance_expiration: props.driver.DriverDocument.vehicle_insurance_expiration ? new Date(props.driver.DriverDocument.vehicle_insurance_expiration) : null,
			}
		};
	}

	changeDriverState(type, value) {
		this.setState((prevState) => ({
			driver: {
				...prevState.driver,
				[type]: value
			}
		}));
	}

	changeDriverDocumentState(type, value) {
		this.setState((prevState) => ({
			driverDocument: {
				...prevState.driverDocument,
				[type]: value
			}
		}));
	}

	getSignedUrlForPost(file, callback, type) {
		EZEEApi.getSignedUrlForPost({
			objectName: file.name,
			contentType: file.type,
			path: `documents/${type}/`
		}).then((data) => {
			callback(data);
		});
	}

	onUploadFinish(data, type) {
		// this.changeDriverDocumentState(type, data.filename)

		EZEEApi.postDriverDocument(this.state.driver.id, {
			type: type,
			document: data.filename
		}).then(() => {
			toast.success('Votre document a bien été reçu', {
				position: toast.POSITION.TOP_RIGHT
			});

			this.setState((prevState) => ({
				driverDocument: {
					...prevState.driverDocument,
					[type]: data.filename,
					[type + '_error']: '',
					[type + '_expiration']: null
				}
			}));
		});
	}

	onUploadStart(file, next, type) {
		if (file.size <= 10000000) {
			next(file);
		} else {
			toast.error('Votre fichier dépasse les 10 MO.', {
				position: toast.POSITION.TOP_RIGHT
			});
			this.uploaders[type].clear();
		}
	}

	declineDocument(type) {
		swal({
			title: 'Décliner le document',
			text: 'Pour quelle raison souhaitez vous décliner ce document ?',
			dangerMode: true,
			content: 'input',
			buttons: ['Annuler', 'Décliner le document']
		}).then((reason) => {
			if (reason != null) {
				if (reason !== '') {
					EZEEApi.deleteDriverDocument(this.props.driver.id, {
						type: type,
						reason: reason
					}).then((documents) => {
						this.changeDriverDocumentState(type, '');
						toast.success('Document refusé avec succès.', {
							position: toast.POSITION.TOP_RIGHT
						});
					});
				} else {
					toast.error("Vous n'avez pas donné de raison.", {
						position: toast.POSITION.TOP_RIGHT
					});
				}
			}
		});
	}

	updateDriver() {


		EZEEApi.postDriver(this.state.driver.id, {
			email_pro: this.state.driver.email_pro,

			structure_type: this.state.driver.structure_type,

			structure_social_reason: this.state.driver.structure_social_reason,
			structure_address_line: this.state.driver.structure_address_line,
			structure_address_postal_code: this.state.driver.structure_address_postal_code,
			structure_address_city: this.state.driver.structure_address_city,
			structure_address_country: this.state.driver.structure_address_country,
			structure_registration_number: this.state.driver.structure_registration_number,
			structure_tva_number: this.state.driver.structure_tva_number,

			vehicle_type_id: this.state.driver.vehicle_type_id

		})
			.then((data) => {
				toast.success('Les données ont bien été mis à jour !', {
					position: toast.POSITION.TOP_RIGHT
				});
			})
			.catch((data) => {
				toast.error('Une erreur est survenu, merci de réessayer et de vérifier vos informations.', {
					position: toast.POSITION.TOP_RIGHT
				});
			});
	}

	updateDirverStatus() {
		EZEEApi.updateDriverStatus(this.state.driver.id).then((driver) => {
			this.changeDriverState('status', driver.status);

			if (driver.status === true) {
				toast.success("Le chauffeur fait désormais parti d'EZEE !", {
					position: toast.POSITION.TOP_RIGHT
				});
			} else {
				toast.success('Le chauffeur quitte désormais EZEE !', {
					position: toast.POSITION.TOP_RIGHT
				});
			}
		});
	}

	updateDriverDocumentData(type, data) {
		let m = data;

		if (data instanceof Date) {
			let m = moment(data).utcOffset(0);
			m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			m.toISOString();
			m.format();
		}

		this.setState((prevState) => ({
			driverDocument: {
				...prevState.driverDocument,
				[type]: data
			}
		}));

		EZEEApi.putDriverDocument(this.state.driver.id, {
			type: type,
			data: m
		});
	}

	render() {
		if (this.state.driver === null || this.state.driverDocument === null) {
			return <div />;
		}

		return (
			<div className="col-md-8">
				<div className="shadow-shape2 user-content-section white-bg-section pb-4">
					<div className="s-padding-sec">
						<h3>Adhésion</h3>

						{this.state.driver.status === true && (
							<p style={{ color: 'green' }}>
								Ce chauffeur fait parti d'EZEE, son adhésion a déjà été accepté, vous pouvez arrêter son
								activité en bas de page.
							</p>
						)}
						{this.state.driver.status === false && (
							<p style={{ color: 'red' }}>
								Ce chauffeur n'est pas encore en activité chez vous, vous devez accepter son adhésion
								tout en bas de page après avoir valider tous les documents.
							</p>
						)}

						<div className="row align-items-center">
							<div className="col-md-3 col-sm-12 pb-3">
								<p>Véhicule</p>
							</div>
							<div className="col-md-9 col-sm-12 pb-3">
								<br />
								<ul className="popup-list-choices green-check-boxes p-0 m-0">
									<li>
										<input
											type="radio"
											name="user-choice"
											id="vehicle_type_id1"
											checked={this.state.driver.vehicle_type_id === 1}
											onClick={() => this.changeDriverState('vehicle_type_id', 1)}
										/>
										<label for="vehicle_type_id1">Moto</label>
									</li>
									<li>
										<input
											type="radio"
											name="user-choice"
											id="vehicle_type_id2"
											checked={this.state.driver.vehicle_type_id === 2}
											onClick={() => this.changeDriverState('vehicle_type_id', 2)}
										/>
										<label for="vehicle_type_id2">Voiture</label>
									</li>
								</ul>
							</div>
						</div>

						<div className="row align-items-center">

							<div className="col-md-3 col-sm-12 pb-3">
								<p>Structure</p>
							</div>
							<div className="col-md-9 col-sm-12 pb-3">
								<div className="s-select-box">
									<select
										name="atructure"
										className={`p-textbox p-selectbox ${this.state.driver.structure_type === '' &&
											'error-text'}`}
										value={this.state.driver.structure_type}
										onChange={(e) => {
											this.changeDriverState('structure_type', e.target.value);
										}}
									>
										<option value="">Type de structure</option>
										<option value="AUTO-ENTREPRISE">AUTO-ENTREPRISE</option>
										<option value="SARL">SARL</option>
										<option value="SASU">SASU</option>
										<option value="EURL">EURL</option>
										<option value="SAS">SAS</option>
									</select>
								</div>
							</div>
						</div>

						<div className="row align-items-center">
							<div className="col-md-3 col-sm-12 pb-3">
								<p>Raison sociale</p>
							</div>
							<div className="col-md-9 col-sm-12 pb-3">
								<input
									type="text"
									className={`p-textbox ${this.state.driver.structure_social_reason === '' &&
										'error-text'}`}
									placeholder="Mon entreprise"
									value={this.state.driver.structure_social_reason}
									onChange={(e) => {
										this.changeDriverState('structure_social_reason', e.target.value);
									}}
								/>
							</div>
						</div>

						<div className="row align-items-center">
							<div className="col-md-3 col-sm-12 pb-3">
								<p>Numéro SIRET</p>
							</div>
							<div className="col-md-9 col-sm-12 pb-3">
								<input
									type="text"
									className={`p-textbox ${this.state.driver.structure_registration_number === '' &&
										'error-text'}`}
									placeholder="Numéro SIRET"
									value={this.state.driver.structure_registration_number}
									onChange={(e) => {
										this.changeDriverState('structure_registration_number', e.target.value);
									}}
								/>

							</div>
						</div>

						<div className="row align-items-center">
							<div className="col-md-3 col-sm-12 pb-3">
								<p>Numéro TVA</p>
							</div>
							<div className="col-md-9 col-sm-12 pb-3">
								<input
									type="text"
									className={`p-textbox ${this.state.driver.structure_tva_number === '' &&
										'error-text'}`}
									placeholder="Numéro TVA"
									value={this.state.driver.structure_tva_number}
									onChange={(e) => {
										this.changeDriverState('structure_tva_number', e.target.value);
									}}
								/>
							</div>
						</div>

						<div className="row align-items-start">
							<div className="col-md-3 col-sm-12 pb-3 pt-3">
								<p>Adresse</p>
							</div>
							<div className="col-md-9 col-sm-12 pb-3">
								<input
									type="text"
									className={`p-textbox ${this.state.driver.structure_address_line === '' &&
										'error-text'}`}
									placeholder="Adresse"
									value={this.state.driver.structure_address_line}
									onChange={(e) => {
										this.changeDriverState('structure_address_line', e.target.value);
									}}
								/>
								<div className="row pt-3">
									<div className="col-md-6 pb-3">
										<input
											type="text"
											className={`p-textbox ${this.state.driver.structure_address_postal_code ===
												'' && 'error-text'}`}
											placeholder="Code postale"
											value={this.state.driver.structure_address_postal_code}
											onChange={(e) => {
												this.changeDriverState('structure_address_postal_code', e.target.value);
											}}
										/>
									</div>
									<div className="col-md-6 pb-3">
										<input
											type="text"
											className={`p-textbox ${this.state.driver.structure_address_city === '' &&
												'error-text'}`}
											placeholder="Ville"
											value={this.state.driver.structure_address_city}
											onChange={(e) => {
												this.changeDriverState('structure_address_city', e.target.value);
											}}
										/>
									</div>
								</div>
								<input
									type="text"
									className={`p-textbox ${this.state.driver.structure_address_country === '' &&
										'error-text'}`}
									placeholder="France"
									value={this.state.driver.structure_address_country}
									onChange={(e) => {
										this.changeDriverState('structure_address_country', e.target.value);
									}}
								/>





							</div>
						</div>

						<div className="align-center s-middle-buttons pt-5 pb-4">
							<button
								onClick={() => {
									this.updateDriver();
								}}
								className="theme-button1"
							>
								Mettre à jour l'adhésion
							</button>
							<br />
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Pièces d’identité</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.card_id != '' ? (
										this.state.driverDocument.card_id.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.card_id = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'card_id');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'card_id');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'card_id');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.card_id !== '' ? (
										<span>
											<S3FilePopup type="card_id" file={this.state.driverDocument.card_id}>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('card_id');
												}}
											>
												Refuser le document
											</button>

											<div className="col-md-8 pb-3 pt-3">
												Expiration :
												<br />
												<DatePicker
													dateFormat="dd/MM/yyyy"
													className="p-textbox datepicker"
													selected={this.state.driverDocument.card_id_expiration}
													placeholder="01/02/1980"
													onChange={(data) => {
														this.updateDriverDocumentData('card_id_expiration', data);
													}}
												/>
											</div>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Pièces d’identité Verso</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.card_id_back != '' ? (
										this.state.driverDocument.card_id_back.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.card_id_back = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'card_id_back');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'card_id_back');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'card_id_back');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.card_id_back !== '' ? (
										<span>
											<S3FilePopup type="card_id_back" file={this.state.driverDocument.card_id_back}>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('card_id_back');
												}}
											>
												Refuser le document
											</button>

										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec gray-box-bg content-block pt-4 pb-4">
						<h5>Carte professionnelle</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">RECTO :</span>{' '}
									{this.state.driverDocument.card_pro_front != '' ? (
										this.state.driverDocument.card_pro_front.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.card_pro_front = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'card_pro_front');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'card_pro_front');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'card_pro_front');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.card_pro_front !== '' ? (
										<span>
											<S3FilePopup
												type="card_pro_front"
												file={this.state.driverDocument.card_pro_front}
											>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('card_pro_front');
												}}
											>
												Refuser le document
											</button>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
						<hr />
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">VERSO :</span>{' '}
									{this.state.driverDocument.card_pro_back != '' ? (
										this.state.driverDocument.card_pro_back.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.card_pro_back = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'card_pro_back');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'card_pro_back');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'card_pro_back');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.card_pro_back !== '' ? (
										<span>
											<S3FilePopup
												type="card_pro_back"
												file={this.state.driverDocument.card_pro_back}
											>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('card_pro_back');
												}}
											>
												Refuser le document
											</button>

											<div className="col-md-8 pb-3 pt-3">
												Expiration :
												<br />
												<DatePicker
													dateFormat="dd/MM/yyyy"
													className="p-textbox datepicker"
													selected={this.state.driverDocument.card_pro_front_expiration}
													placeholder="01/02/1980"
													onChange={(data) => {
														this.updateDriverDocumentData(
															'card_pro_front_expiration',
															data
														);
													}}
												/>
											</div>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Permis de conduire</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.card_driver_license != '' ? (
										this.state.driverDocument.card_driver_license.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.card_driver_license = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'card_driver_license');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'card_driver_license');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'card_driver_license');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.card_driver_license !== '' ? (
										<span>
											<S3FilePopup
												type="card_driver_license"
												file={this.state.driverDocument.card_driver_license}
											>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('card_driver_license');
												}}
											>
												Refuser le document
											</button>

											<div className="col-md-8 pb-3 pt-3">
												Expiration :
												<br />
												<DatePicker
													dateFormat="dd/MM/yyyy"
													className="p-textbox datepicker"
													selected={this.state.driverDocument.card_driver_license_expiration}
													placeholder="01/02/1980"
													onChange={(data) => {
														this.updateDriverDocumentData(
															'card_driver_license_expiration',
															data
														);
													}}
												/>
											</div>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Photo</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.picture != '' ? (
										this.state.driverDocument.picture.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.picture = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'picture');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'picture');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'picture');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.picture !== '' ? (
										<span>
											<S3FilePopup type="picture" file={this.state.driverDocument.picture}>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('picture');
												}}
											>
												Refuser le document
											</button>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Justificatif de domicile</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.dwelling != '' ? (
										this.state.driverDocument.dwelling.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.dwelling = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'dwelling');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'dwelling');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'dwelling');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.dwelling !== '' ? (
										<span>
											<S3FilePopup type="dwelling" file={this.state.driverDocument.dwelling}>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('dwelling');
												}}
											>
												Refuser le document
											</button>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Visite médicale</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.medical_checkup != '' ? (
										this.state.driverDocument.medical_checkup.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.medical_checkup = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'medical_checkup');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'medical_checkup');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'medical_checkup');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.medical_checkup !== '' ? (
										<span>
											<S3FilePopup
												type="medical_checkup"
												file={this.state.driverDocument.medical_checkup}
											>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('medical_checkup');
												}}
											>
												Refuser le document
											</button>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Assurance Responsabilité civile professionnelle</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.insurance != '' ? (
										this.state.driverDocument.insurance.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.insurance = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'insurance');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'insurance');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'insurance');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.insurance !== '' ? (
										<span>
											<S3FilePopup type="insurance" file={this.state.driverDocument.insurance}>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('insurance');
												}}
											>
												Refuser le document
											</button>

											<div className="col-md-8 pb-3 pt-3">
												Expiration :
												<br />
												<DatePicker
													dateFormat="dd/MM/yyyy"
													className="p-textbox datepicker"
													selected={this.state.driverDocument.insurance_expiration}
													placeholder="01/02/1980"
													onChange={(data) => {
														this.updateDriverDocumentData('insurance_expiration', data);
													}}
												/>
											</div>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Licence Atout France ou Certificat d'inscription au Registre VTC</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.licence_atout != '' ? (
										this.state.driverDocument.licence_atout.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.licence_atout = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'licence_atout');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'licence_atout');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'licence_atout');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.licence_atout !== '' ? (
										<span>
											<S3FilePopup
												type="licence_atout"
												file={this.state.driverDocument.licence_atout}
											>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('licence_atout');
												}}
											>
												Refuser le document
											</button>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Extrait KBIS (ou relevé SIRENE)</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.kbis != '' ? (
										this.state.driverDocument.kbis.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.kbis = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'kbis');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'kbis');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'kbis');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.kbis !== '' ? (
										<span>
											<S3FilePopup type="kbis" file={this.state.driverDocument.kbis}>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('kbis');
												}}
											>
												Refuser le document
											</button>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Relevé d'identité Bancaire (RIB)</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.rib_company != '' ? (
										this.state.driverDocument.rib_company.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.rib_company = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'rib_company');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'rib_company');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'rib_company');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.rib_company !== '' ? (
										<span>
											<S3FilePopup
												type="rib_company"
												file={this.state.driverDocument.rib_company}
											>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('rib_company');
												}}
											>
												Refuser le document
											</button>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Carte grise du vehicule</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.vehicle_card != '' ? (
										this.state.driverDocument.vehicle_card.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.vehicle_card = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'vehicle_card');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'vehicle_card');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'vehicle_card');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.vehicle_card !== '' ? (
										<span>
											<S3FilePopup
												type="vehicle_card"
												file={this.state.driverDocument.vehicle_card}
											>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('vehicle_card');
												}}
											>
												Refuser le document
											</button>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Photo extérieur du véhicule (face avant, plaque visible)</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.vehicle_picture != '' ? (
										this.state.driverDocument.vehicle_picture.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.vehicle_picture = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'vehicle_picture');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'vehicle_picture');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'vehicle_picture');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.vehicle_picture !== '' ? (
										<span>
											<S3FilePopup
												type="vehicle_picture"
												file={this.state.driverDocument.vehicle_picture}
											>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('vehicle_picture');
												}}
											>
												Refuser le document
											</button>
										</span>
									) : (
											'Maximum 10 Mo'
										)}

									<div className="col-md-8 pb-3 pt-3">
										Plaque d'immatriculation :
										<br />
										<input
											type="text"
											style={{ 'margin-bottom': '15px' }}
											className="p-textbox"
											value={this.state.driverDocument.vehicle_plate_number}
											onChange={(e) => {
												let data = e.target.value;

												this.setState((prevState) => ({
													driverDocument: {
														...prevState.driverDocument,
														vehicle_plate_number: data
													}
												}));

												clearTimeout(this.timeout);
												this.timeout = setTimeout(() => {
													this.updateDriverDocumentData('vehicle_plate_number', data);
												}, 3000);
											}}
										// onChange={(event) => {

										// }}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Carte verte (correspond à l'attestation d'assurance)</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p>
									<span className="black">DOC :</span>{' '}
									{this.state.driverDocument.vehicle_insurance != '' ? (
										this.state.driverDocument.vehicle_insurance.substring(36)
									) : (
											'Aucun document envoyé.'
										)}{' '}
								</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => {
											this.uploaders.vehicle_insurance = ref;
										}}
										className="theme-button1"
										preprocess={(file, next) => {
											this.onUploadStart(file, next, 'vehicle_insurance');
										}}
										onFinish={(data) => {
											this.onUploadFinish(data, 'vehicle_insurance');
										}}
										getSignedUrl={(file, callback) => {
											this.getSignedUrlForPost(file, callback, 'vehicle_insurance');
										}}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.vehicle_insurance !== '' ? (
										<span>
											<S3FilePopup
												type="vehicle_insurance"
												file={this.state.driverDocument.vehicle_insurance}
											>
												{' '}
												Aperçu du document envoyé{' '}
											</S3FilePopup>
											<br />
											<br />
											<button
												className="theme-button1 theme-button-red"
												onClick={() => {
													this.declineDocument('vehicle_insurance');
												}}
											>
												Refuser le document
											</button>

											<div className="col-md-8 pb-3 pt-3">
												Expiration :
												<br />
												<DatePicker
													dateFormat="dd/MM/yyyy"
													className="p-textbox datepicker"
													selected={this.state.driverDocument.vehicle_insurance_expiration}
													placeholder="01/02/1980"
													onChange={(data) => {
														this.updateDriverDocumentData(
															'vehicle_insurance_expiration',
															data
														);
													}}
												/>
											</div>
										</span>
									) : (
											'Maximum 10 Mo'
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="align-center s-middle-buttons pt-5 pb-4">
						{this.state.driver.status === true && (
							<button
								className="theme-button1 theme-button-red"
								onClick={() => {
									this.updateDirverStatus();
								}}
							>
								Annuler l’adhésion
							</button>
						)}
						{this.state.driver.status === false && (
							<button
								className="theme-button1"
								onClick={() => {
									this.updateDirverStatus();
								}}
							>
								Accepter l’adhésion
							</button>
						)}

						{/* <a href="#" className="theme-button1 theme-button-red">Refuser l’adhésion</a> */}
					</div>

					{/* <div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Justificatif de domicile</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p><span className="black">DOC :</span> {this.state.driverDocument.dwelling != '' ? this.state.driverDocument.dwelling.substring(36) : 'Aucun document envoyé.'} </p>
								<div className="s-sm-links pt-3">

									<ReactS3Uploader
										ref={(ref) => { this.uploaders.dwelling = ref }}
										className="theme-button1"
										preprocess={(file, next) => { this.onUploadStart(file, next, 'dwelling') }}
										onFinish={(data) => { this.onUploadFinish(data, 'dwelling') }}
										getSignedUrl={(file, callback) => { this.getSignedUrlForPost(file, callback, 'dwelling') }}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>

									{this.state.driverDocument.dwelling !== '' ?
										<span>
											<S3FilePopup type='dwelling' file={this.state.driverDocument.dwelling} > Aperçu du document envoyé </S3FilePopup>
											<br /><br />
											<button className="theme-button1 theme-button-red" onClick={() => { this.declineDocument('dwelling') }}>Refuser le document</button>
										</span>
										: 'Maximum 10 Mo'}
								</div>
							</div>
						</div>
					</div> */}

					{/* <div className="s-padding-sec gray-box-bg content-block pt-4 pb-4">
						<h5>Carte professionnelle</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p><span className="black">RECTO :</span> {this.state.driverDocument.card_pro_front != '' ? this.state.driverDocument.card_pro_front.substring(36) : 'Aucun document envoyé.'}</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => { this.uploaders.card_pro_front = ref }}
										className="theme-button1"
										preprocess={(file, next) => { this.onUploadStart(file, next, 'card_pro_front') }}
										onFinish={(data) => { this.onUploadFinish(data, 'card_pro_front') }}
										getSignedUrl={(file, callback) => { this.getSignedUrlForPost(file, callback, 'card_pro_front') }}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>
									{this.state.driverDocument.card_pro_front !== '' ?
										<span>
											<S3FilePopup type='card_pro_front' file={this.state.driverDocument.card_pro_front} > Aperçu du document envoyé </S3FilePopup>
											<br /><br />
											<button className="theme-button1 theme-button-red" onClick={() => { this.declineDocument('card_pro_front') }}>Refuser le document</button>
										</span>
										: 'Maximum 10 Mo'}
								</div>
							</div>
						</div>
						<hr></hr>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p><span className="black">VERSO :</span> {this.state.driverDocument.card_pro_back != '' ? this.state.driverDocument.card_pro_back.substring(36) : 'Aucun document envoyé.'}</p>
								<div className="s-sm-links pt-3">

									<ReactS3Uploader
										ref={(ref) => { this.uploaders.card_pro_back = ref }}
										className="theme-button1"
										preprocess={(file, next) => { this.onUploadStart(file, next, 'card_pro_back') }}
										onFinish={(data) => { this.onUploadFinish(data, 'card_pro_back') }}
										getSignedUrl={(file, callback) => { this.getSignedUrlForPost(file, callback, 'card_pro_back') }}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>



									{this.state.driverDocument.card_pro_back !== '' ?


										<span>
											<S3FilePopup type='card_pro_back' file={this.state.driverDocument.card_pro_back} > Aperçu du document envoyé </S3FilePopup>
											<br /><br />
											<button className="theme-button1 theme-button-red" onClick={() => { this.declineDocument('card_pro_back') }}>Refuser le document</button>
										</span>

										: 'Maximum 10 Mo'}

									<div className="col-md-8 pb-3 pt-3">
										Expiration :
										 <input
											type="text"
											className="p-textbox"
											value="aze"
										/> 
										<br></br>

										<DatePicker
											dateFormat="dd/MM/yyyy"
											className="p-textbox datepicker"
											// selected={this.state.birthdate}
											placeholder="01/02/1980"
										// onChange={this.handleDateChange.bind(this)}
										/>

									</div>

								</div>
							</div>
						</div>
					</div> */}

					{/* <div className="s-padding-sec content-block pt-4 pb-4">
						<h5>Relevé d’informations d’assurances</h5>
						<div className="row">
							<div className="col-sm-12 pt-2 pb-2">
								<p><span className="black">DOC :</span> {this.state.driverDocument.insurance != '' ? this.state.driverDocument.insurance.substring(36) : 'Aucun document envoyé.'}</p>
								<div className="s-sm-links pt-3">
									<ReactS3Uploader
										ref={(ref) => { this.uploaders.insurance = ref }}
										className="theme-button1"
										preprocess={(file, next) => { this.onUploadStart(file, next, 'insurance') }}
										onFinish={(data) => { this.onUploadFinish(data, 'insurance') }}
										getSignedUrl={(file, callback) => { this.getSignedUrlForPost(file, callback, 'insurance') }}
										accept="image/*, application/pdf"
										uploadRequestHeaders={{
											'x-amz-acl': 'private',
											'x-amz-meta-owner': this.state.driver.user_id.toString()
										}}
										contentDisposition="auto"
									/>
									{this.state.driverDocument.insurance !== '' ?
										<span>
											<S3FilePopup type='insurance' file={this.state.driverDocument.insurance} > Aperçu du document envoyé </S3FilePopup>
											<br /><br />
											<button className="theme-button1 theme-button-red" onClick={() => { this.declineDocument('insurance') }}>Refuser le document</button>
										</span>
										: 'Maximum 10 Mo'}

								</div>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		);
	}
}

export default UserScreen;
