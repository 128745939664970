import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import EZEEApi from '../../../services/EZEEApi';
import ReactS3Uploader from 'react-s3-uploader';
import { toast } from 'react-toastify';
import Popup from "reactjs-popup";
import S3FilePopup from '../../popups/s3file'
import Moment from 'react-moment'
import DatePicker from 'react-datepicker';
import moment from 'moment';

class MeScreen extends Component {

    uploaders = {}

    constructor(props) {
        super(props)

        this.state = {
            trips: [],
            date: [],

            begin_created_at: moment().startOf('month').toDate(),
            end_created_at: new Date()

        }

    }

    componentDidMount() {

        EZEEApi.getUserTripsAsDriver('me', `&filter=created_at between ${moment(this.state.begin_created_at).startOf('day').format('YYYY-MM-DD')} ${moment(this.state.end_created_at).add(1, 'days').endOf('day').format('YYYY-MM-DD')} `).then((trips) => {

            this.setState({
                trips: trips
            })

        })

    }

    onDateFromChange(date) {
        if (date > this.state.end_created_at) {
            return toast.error('La date de départ ne peut pas être supérieur à la date de fin.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        this.setState({
            begin_created_at: date
        });
    }

    onDateToChange(date) {
        if (date < this.state.begin_created_at) {
            return toast.error('La date de fin ne peut pas être antérieur à la date de début.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        this.setState({
            end_created_at: date
        });
    }

    change() {
        EZEEApi.getUserTripsAsDriver('me', `&filter=created_at between ${moment(this.state.begin_created_at).startOf('day').format('YYYY-MM-DD')} ${moment(this.state.end_created_at).add(1, 'days').endOf('day').format('YYYY-MM-DD')} `).then((trips) => {
            this.setState({
                trips: trips
            })
        })
    }

    render() {
        return (
            <div class="content-area-wrapper pt-5 pb-5">
                <div class="body-bg body-bg1 body-bg-pos"></div>
                <div class="body-bg body-bg2"></div>


                <div class="container">
                    <div class="row mb-5">
                        <div class="col-md-4 mb-5">
                            <div class="shadow-shape2 user-profile-sidebar pb-3 white-bg-section" style={{ 'min-height': '450px' }}>
                                <div class="s-back-link s-heading1"><h3>Historique</h3></div>
                                <div class="s-filter-link">
                                    {/* <a class="filter-btn" href="#">Filtrer mes courses</a> */}
                                    <div class="sb-date-filter mt-5 mb-4">
                                        <p><label for="amount">Par date</label></p>

                                        Du : {" "}
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            className="p-textbox datepicker"
                                            selected={this.state.begin_created_at}
                                            onChange={this.onDateFromChange.bind(this)}
                                            locale="fr"
                                        />
                                        <hr />
                                        Au : {" "}
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            className="p-textbox datepicker"
                                            selected={this.state.end_created_at}
                                            onChange={this.onDateToChange.bind(this)}
                                            locale="fr"
                                        />
                                    </div>

                                    <button class="theme-button1 align-center full-width-btn" onClick={() => { this.change() }}>Appliquer</button>
                                </div>
                            </div>
                        </div>



                        <div class="col-md-8">
                            <div class="white-bg-section shadow-shape2 mb-2">
                                <div class="row">
                                    <div class="col-sm-12">

                                        {

                                            this.state.trips.map((trip) => {

                                                if (trip.Transaction) {

                                                    return (

                                                        <div class="path-box-wrapper">
                                                            {/* <div class="path-image full-width-img border-radius-top"><img class="border-radius-top" src="images/path-img.png" alt="" /></div> */}
                                                            <div class="s-padding-sec2 row">
                                                                <div class="col-md-8">
                                                                    <p> Le <Moment format="LLLL">{trip.created_at}</Moment>  </p>
                                                                    <p class="gray-color"> Trajet en {trip.vehicle_type_id === 1 ? "moto" : "voiture"} ({trip.plate_number}) de {trip.address_from} vers {trip.address_to} <br /> <span style={{ color: "#B22222" }}>{(trip.Transaction.refunded ? "Remboursé" : "")} </span> </p>
                                                                </div>
                                                                <div class="col-md-4 align-right">

                                                                    <p>{(trip.Transaction.refunded ? "0" : (trip.Transaction.amount_paid - trip.Transaction.ezee_fees_amount_total) / 100)} &euro;</p>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                } else {
                                                    return <></>
                                                }


                                            })

                                        }

                                        {
                                            this.state.trips.length === 0 && (
                                                <div className="TableComponentsEmpty" style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                                                    <img src="/images/Icon_search.png" alt="icon search" />
                                                    <span>Nous sommes désolés,</span>
                                                    <span>Aucun résultat n'a été trouvé !</span>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >)
    }
}

export default MeScreen;