import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import BookingSetDriverPopup from '../popups/booking_set_driver';
import moment from 'moment'
import env from '../../env'
import swal from 'sweetalert';


class BookingScreen extends Component {

    constructor(props) {
        super(props)

        this.state = {
            booking: null
        }

    }

    componentDidMount() {
        this.reloadBooking()
    }

    reloadBooking() {
        EZEEApi.getDeliveryBooking(this.props.match.params.bookingId).then((booking) => {
            this.setState({ booking: booking })
        })
    }

    render() {

        if (this.state.booking == null) {
            return (<div></div>)
        }

        return (
            <div className="content-area-wrapper pt-5 pb-5">

                <div className="body-bg body-bg1"></div>
                <div className="body-bg body-bg2"></div>

                <div className="container">
                    <div className="row">
                        <div className="visit-trajet">
                            <NavLink to={`/deliveries/bookings`} ><img src="/images/arrow-left-f.svg" alt="" />Retour aux réservations</NavLink>
                        </div>
                    </div>

                    <div className="white-bg-section shadow-shape2 mb-5">
                        <div className="row detail-table-header-border">
                            <div className="col-12">
                                <div className="detail-table-row-header detail-table-header">
                                    <h4>Détail de la réservation</h4>
                                </div>
                            </div>
                        </div>

                        <div class="row detail-table-content-wrapper">
                            <div class="col-12">
                                <div class="detail-table full-width">
                                    <div class="detail-table-row empty-row row m-0 align-items-center">
                                    </div>
                                </div>
                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Ref</h6></div>
                                        <div class="detail-table-cell">{this.state.booking.id}</div>

                                    </div>
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Statut</h6></div>
                                        <div className="detail-table-cell">
                                            {(this.state.booking.status === 2 && 'Payé sans chauffeur') || (this.state.booking.status === 3 && 'En attente') || (this.state.booking.status === 4 && 'Terminée') || (this.state.booking.status === 9 && 'Annuler') || (this.state.booking.status === 99 && 'Rembourser')}
                                        </div>

                                        {
                                            this.state.booking.status === 2 && (
                                                <div class="detail-table-cell right btn-media">
                                                    <button class="view-profile-btn " onClick={() => {

                                                        if (this.state.booking.is_one_way) {

                                                            swal({
                                                                title: "Confirmation",
                                                                text: "Êtes vous sur de vouloir annuler cette course ?",
                                                                icon: "warning",
                                                                buttons: true,
                                                                dangerMode: true
                                                            }).then((confirmed) => {
                                                                if (confirmed) {
                                                                    EZEEApi.deleteBooking(this.state.booking.id).then(() => {
                                                                        this.reloadBooking()
                                                                    })
                                                                }
                                                            })

                                                        } else {

                                                            swal({
                                                                title: "Confirmation",
                                                                text: `Êtes vous sur de vouloir annuler cette course ? Celle-ci annulera aussi la course ${this.state.booking.booking_outbound_id ? "Aller" : "Retour"} que le client a commandé !`,
                                                                icon: "warning",
                                                                buttons: true,
                                                                dangerMode: true
                                                            }).then((confirmed) => {
                                                                if (confirmed) {
                                                                    EZEEApi.deleteBooking(this.state.booking.id).then(() => {
                                                                        EZEEApi.deleteBooking(this.state.booking.booking_outbound_id || this.state.booking.booking_return_id).then(() => {
                                                                            this.reloadBooking()
                                                                        })
                                                                    })
                                                                }
                                                            })

                                                        }

                                                    }}>Annuler la course</button>
                                                </div>
                                            )
                                        }


                                        <div className="clear"></div>
                                    </div>
                                </div>

                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Date de création</h6></div>
                                        <div class="detail-table-cell"><Moment format="DD/MM/YYYY">{this.state.booking.created_at}</Moment></div>
                                    </div>
                                </div>

                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Chauffeur</h6></div>

                                        {this.state.booking.Driver ?

                                            <>
                                                <div class="detail-table-cell">
                                                    <span class="t-client-icon">
                                                        <S3Picture type="user-profil" file={(this.state.booking.Driver).picture} />
                                                    </span>
                                                    {(this.state.booking.Driver).lastname} {(this.state.booking.Driver).firstname}
                                                </div>
                                                <div class="detail-table-cell right btn-media">
                                                    <NavLink to={`/drivers/${this.state.booking.Driver.Driver.id}/summary`} class="view-profile-btn " style={{ marginRight: '10px' }}>Voir le profil chauffeur</NavLink>

                                                </div>
                                            </>

                                            :
                                            <>
                                                <div class="detail-table-cell" style={{ color: 'red' }}>
                                                    {/* <span class="t-client-icon">
                                                        <S3Picture type="user-profil" file={(this.state.booking.CreditedUser || { picture: 'default.jpg' }).picture} />
                                                    </span> */}
                                                    {/* {(this.state.booking.CreditedUser || { lastname: "Booking" }).lastname} {(this.state.booking.CreditedUser || { firstname: "Booking" }).firstname}
                                                 */} Aucun chauffeur n'a été choisi
                                                </div>
                                                <div class="detail-table-cell right btn-media">

                                                    <BookingSetDriverPopup
                                                        style={{ marginRight: '10px' }}
                                                        className="detail-button popup-info_open"
                                                        booking={this.state.booking}
                                                        onClose={() => this.reloadBooking()}
                                                    >Définir un chauffeur</BookingSetDriverPopup>

                                                    {/* <NavLink to={`/drivers/${this.state.booking.driver_id}/summary`} class="view-profile-btn " ></NavLink> */}

                                                </div>
                                            </>
                                        }


                                        <div class="clear"></div>
                                    </div>
                                </div>

                                {/*  */}

                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Client</h6></div>
                                        <div class="detail-table-cell">
                                            <span class="t-client-icon"><S3Picture type="user-profil" file={this.state.booking.Client.picture} /> </span>

                                            {
                                                this.state.booking.Client.is_professional ?
                                                    ` Pro - ${this.state.booking.Client.professional_name} ` :
                                                    ` ${this.state.booking.Client.firstname} ${this.state.booking.Client.lastname} `
                                            }

                                            {/* {this.state.booking.DebitedUser.lastname} {this.state.booking.DebitedUser.firstname} */}
                                        </div>
                                        <div class="detail-table-cell right btn-media">
                                            <NavLink to={`/users/${this.state.booking.Client.id}/profil`} class="view-profile-btn ">Voir le profil</NavLink>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                </div>

                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Prix de la course</h6></div>
                                        <div class="detail-table-cell">{this.state.booking.amount_to_pay / 100} €</div>
                                    </div>
                                </div>

                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Type</h6></div>
                                        <div class="detail-table-cell">
                                            {
                                                this.state.booking.is_one_way ? "Aller " : "Aller / Retour "
                                            }
                                            {
                                                this.state.booking.vehicle_type_id === 1 ? "en Moto " : "en Voiture "
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Date</h6></div>
                                        <div class="detail-table-cell">
                                            <div class="detail-user-info"><span class="client-route black">Aller</span>
                                                <span class="address-media">{moment(this.state.booking.trip_date).format('Do MMMM  YYYY, HH:mm:ss')} </span>
                                            </div>
                                            {
                                                this.state.booking.return_date &&
                                                <div class="detail-user-info"><span class=" client-route black">Retour</span>
                                                    <span class="address-media"> {moment(this.state.booking.return_date).format('Do MMMM  YYYY, HH:mm:ss')}</span>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Adresse</h6></div>
                                        <div class="detail-table-cell">
                                            <div class="detail-user-info"><span class="client-route black">Départ</span>
                                                <span class="address-media">{this.state.booking.address_from}</span>
                                            </div>
                                            <div class="detail-user-info"><span class=" client-route black">Arrivée</span>
                                                <span class="address-media">{this.state.booking.address_to}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Top case ? </h6></div>
                                        <div class="detail-table-cell">{this.state.booking.baggages ? "Oui" : "Non"}</div>
                                    </div>
                                </div>

                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Commentaire </h6></div>
                                        <div class="detail-table-cell">{this.state.booking.comment}</div>
                                    </div>
                                </div>


                                {/* <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>CA généré</h6></div>
                                        <div class="detail-table-cell">{this.state.booking.amount_to_pay / 100} €</div>
                                        <div class="detail-table-cell detail-title-cell auto-width"><h6>Bénéfice</h6></div>
                                        <div class="detail-table-cell">{this.state.booking.ezee_fees_amount_total / 100} €</div>
                                        <div class="detail-table-cell detail-title-cell auto-width"><h6>Frais bancaires</h6></div>
                                        <div class="detail-table-cell">{this.state.booking.stripe_fees_amount_total / 100} €</div>
                                    </div>
                                </div> */}



                                {
                                    this.state.booking.Transaction &&

                                    <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                        <div class="col-12 p-0">
                                            <div class="detail-table-cell detail-title-cell"><h6>Transaction</h6></div>
                                            <div class="detail-table-cell"> La transaction a été effectué, vous pouvez la rembrouser  </div>
                                            <div class="detail-table-cell right btn-media">
                                                <NavLink to={`/transactions/${this.state.booking.Transaction.id}`} class="view-profile-btn " style={{ marginRight: '10px' }}>Voir la transaction</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default BookingScreen;