import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';

import Pagination from '../Pagination';
import TableComponent from '../TableComponent';

import Moment from 'react-moment';

import ReactTooltip from 'react-tooltip';

import LoadingOverlay from 'react-loading-overlay';
class UsersScreen extends TableComponent {
	componentDidMount() {
		super.componentDidMount();
		this.reloadData(true);
	}

	reloadData(instant) {
		super.reloadData(() => {
			EZEEApi.getUsers(this.state.qs).then((users) => {
				this.setState({
					count: users.count,
					data: users.rows,
					called: true
				});
			});
		}, instant);
	}

	searchValueChanged(value) {
		this.onFilterChange('firstname', `%${value}%`, 'iLike');
		this.onFilterChange('lastname', `%${value}%`, 'iLike');
		this.onFilterChange('professional_name', `%${value}%`, 'iLike');
	}

	render() {
		if (!this.state.called) {
			return <div />;
		}

		return (
			<LoadingOverlay active={this.state.contentLoading} spinner text="Chargement des données...">
				<div className="content-area-wrapper pt-5 pb-5">
					<div className="body-bg body-bg1" />
					<div className="body-bg body-bg2" />
					<div className="body-bg body-bg3" />

					<div className="container">
						<div className="row">
							<div className="col-lg-8">
								<h2>Utilisateurs ({this.state.count})</h2>
							</div>
							<div className="col-lg-4 search-form">
								<form action="#" method="post">
									<input
										type="text"
										className="s-textbox"
										placeholder="Rechercher un utilisateur"
										onChange={this.onSearchValueChange.bind(this)}
										value={this.state.searchValue}
									/>
									<input type="submit" value="SEND" className="s-search-btn" />
								</form>
							</div>
						</div>

						<div className="row ml-0 mr-0 s-filter-sec mt-4 mb-4 align-items-center">
							{this.generateFilter([20, 50, 100])}
							{this.generateDateFilter()}
						</div>

						{this.state.count !== 0 ? (
							<div>
								<div className="row s-table-content-wrapper">
									<div className="col-12">
										<div className="d-table full-width s-table-content">
											<div className="d-table-row table-header">
												<div className="d-table-cell">
													<input type="checkbox" className="s-checkbox" id="user-id" />
													<label htmlFor="user-id">&nbsp;</label>
												</div>

												{this.generateTableHeader({ name: 'REF', column: 'id' })}
												{this.generateTableHeader({ name: 'NOM', column: 'firstname' })}
												{this.generateTableHeader({ name: 'CRÉATION', column: 'created_at' })}
												{this.generateTableHeader({ name: 'NOTE', column: 'passenger_rate' })}
												{this.generateTableHeader({ name: 'COURSES', column: 'trips_amount' })}
												{this.generateTableHeader({
													name: 'CA GÉNÉRÉ',
													column: 'debited_amount'
												})}
												{this.generateTableHeader({ name: <font>STATUT</font> })}
												{this.generateTableHeader({ name: <span>&nbsp;</span> })}
											</div>

											{this.state.data.map((user) => {
												return (
													<div className="d-table-row table-data-row">
														<div className="d-table-cell">
															<input
																type="checkbox"
																className="s-checkbox"
																id="user-id1"
															/>
															<label htmlFor="user-id1">&nbsp;</label>
														</div>
														<div className="d-table-cell">{user.id} </div>
														<div className="d-table-cell s-user-info">
															<span className="t-client-icon">
																<NavLink to={`/users/${user.id}/profil`}>
																	{' '}
																	<S3Picture
																		type="user-profil"
																		file={user.picture}
																	/>{' '}
																</NavLink>
															</span>
															<NavLink to={`/users/${user.id}/profil`}>

																{user.is_professional ? ` ${user.professional_name} (Pro)` : ` ${user.firstname} ${user.lastname}`}

															</NavLink>
														</div>
														<div className="d-table-cell">
															<Moment format="DD/MM/YYYY">{user.created_at}</Moment>{' '}
														</div>
														<div className="d-table-cell">
															{Math.round(user.passenger_rate * 100) / 100} / 5
														</div>
														<div className="d-table-cell">{user.trips_amount}</div>
														<div className="d-table-cell">
															{user.debited_amount / 100} €
														</div>
														<div className="d-table-cell">Actif</div>
														<div className="d-table-cell">
															<NavLink
																className="voir-button"
																to={`/users/${user.id}/profil`}
															>
																Profil
															</NavLink>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>

								<Pagination
									count={this.state.count}
									page={this.state.qs.page}
									limit={this.state.qs.limit}
									onPageChange={(page) => {
										this.onPageChange(page);
									}}
								/>
							</div>
						) : (
							<div className="TableComponentsEmpty">
								<img src="/images/Icon_search.png" alt="icon search" />
								<span>Nous sommes désolés,</span>
								<span>Aucun résultat n'a été trouvé !</span>
							</div>
						)}
					</div>
				</div>
			</LoadingOverlay>
		);
	}
}

export default UsersScreen;
