import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';

class UserVehicleScreen extends Component {

    constructor(props) {
        super(props)

        this.state = {
            trips: []
        }

    }

    componentDidMount() {

        if (this.props.as === 'passenger') {
            EZEEApi.getUserTripsAsPassenger(this.props.user.id).then((trips) => {
                this.setState({ trips: trips })
            })
        } else if (this.props.as === 'driver') {
            EZEEApi.getUserTripsAsDriver(this.props.user.id).then((trips) => {
                this.setState({ trips: trips })
            })
        }

    }

    render() {
        return (

            <div class="col-md-8">
                <div class="shadow-shape2 white-bg-section pb-4">
                    <div class="row align-items-center s-padding-sec">
                        <div class="col-sm-12">
                            <h3>Historique des courses</h3>
                        </div>
                    </div>

                    <div class="s-table-content-wrapper s-virements-table-wrap">
                        <div class="col-12 p-0">
                            <div class="d-table full-width s-table-content">
                                <div class="d-table-row table-header table-header-middle">
                                    <div class="d-table-cell"><span class="s-down-arrow">DATE</span></div>
                                    <div class="d-table-cell"><span class="s-down-arrow">HEURE</span></div>
                                    <div class="d-table-cell"><span class="s-down-arrow">ADDRESSES</span></div>
                                    <div class="d-table-cell"><span class="s-down-arrow">PRIX</span></div>
                                    {/* <div class="d-table-cell"><span class="s-down-arrow">NOTE</span></div> */}
                                </div>

                                {
                                    this.state.trips.map((trip) => {

                                        return (

                                            <div class="d-table-row table-data-row">
                                                <div class="d-table-cell"><Moment format="DD/MM/YYYY">{trip.created_at}</Moment></div>
                                                <div class="d-table-cell"><Moment format="HH[h]mm">{trip.created_at}</Moment></div>
                                                <div class="d-table-cell">
                                                    <p><span class="color-gray">Départ :</span>{trip.address_from}</p>
                                                    <p><span class="color-gray">Arrivée :</span>{trip.address_to}</p>
                                                </div>
                                                <div class="d-table-cell">{trip.Transaction.amount_paid / 100} €</div>
                                                {/* <div class="d-table-cell">5/5</div> */}
                                            </div>

                                        )

                                    })
                                }

                            </div>
                        </div>
                    </div>


                </div>
            </div>);
    }
}

export default UserVehicleScreen;