import React, { Component } from 'react';
import Popup from "reactjs-popup";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';

class TransactionRefundPopup extends Component {

    constructor(props) {
        super(props)

        this.sclose = null

        this.state = {
            left_reason: '',
            choices: 1
        }
    }

    componentDidMount() {
        console.log(this.props)
    }

    onLeftReasonChange(event) {
        this.setState({
            left_reason: event.target.value
        })
    }

    refund() {

        EZEEApi.putTransaction(this.props.transaction.id, this.state.left_reason).then(() => {
            this.props.onClose()
            this.sclose()
        })

    }


    render() {

        return (
            <Popup trigger={<button className={this.props.className}> {this.props.children} </button>} modal>

                {(close) => {
                    this.sclose = close
                    return (

                        <div className="popup-box align-center">
                            <div className="close-popup popup-info_close"><img src="/images/close-icon.svg" alt="Close" onClick={close} /></div>
                            <div className="popup-box-inner">
                                <div className="popup-title">
                                    <div className="p-title-icon"><img src="/images/title-icon.svg" alt="" /></div>
                                    <h3>Vous souhaitez rembourser cette transactions ? </h3>
                                </div>
                                <div className="popup-main">
                                    <div className="row sky-bg-section align-left">
                                        <div className="col-12">
                                            <ul className="popup-list-choices p-0 m-0">
                                                <li><input type="radio" name="user-choice" id="user-choice4" onClick={() => this.setState({ choices: 4 })} checked={this.state.choices === 4 ? true : false} /><label forhtml="user-choice4">Indiquez la raison :</label>
                                                    <textarea className="s-textbox s-choice-textarea" name="your-choice" value={this.state.left_reason} onChange={this.onLeftReasonChange.bind(this)}></textarea>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <a className="theme-button1 theme-button-green" onClick={() => this.refund()}>Rembourser la commande</a>
                                </div>
                            </div>
                        </div>

                    )
                }}

            </Popup>
        );

    }
}

export default TransactionRefundPopup;