import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import UserSummaryScreen from './user_summary';
import { toast } from 'react-toastify';
class UserScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			comments: [],
			message: ''
		};
	}

	generateNewPassword() {
		EZEEApi.postUserPasswordReset(this.props.user.email).then(() => {
			toast.success('La requête du nouveau mot de passe a été envoyé par e-mail !', {
				position: toast.POSITION.TOP_RIGHT
			});
		});
	}

	render() {
		return (
			<div className="col-md-8">
				<div className="shadow-shape2 user-content-section white-bg-section pb-4">
					<div className="s-padding-sec">
						<h3>Informations sur le profil</h3>
						<form className="pt-5" action="#" method="post">
							<div className="row align-items-center">
								<div className="col-sm-4">
									<p>
										Photo <br />de profil
									</p>
								</div>
								<div className="col-sm-8">
									<div className="user-profile-pic2">
										<S3Picture type="user-profil" file={this.props.user.picture} />
									</div>
								</div>
							</div>


							{this.props.user.is_professional && (

								<div className="row align-items-center">
									<div className="col-md-4 pb-3">
										<p>Nom pro</p>
									</div>
									<div className="col-md-8 pb-3">
										<input
											type="text"
											className="p-textbox"
											value={this.props.user.professional_name}
											disabled="true"
										/>
									</div>
								</div>

							)}


							<div className="row align-items-center">
								<div className="col-md-4 pb-3">
									<p>Prénom</p>
								</div>
								<div className="col-md-8 pb-3">
									<input
										type="text"
										className="p-textbox"
										value={this.props.user.firstname}
										disabled="true"
									/>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-md-4 pb-3">
									<p>Nom</p>
								</div>
								<div className="col-md-8 pb-3">
									<input
										type="text"
										className="p-textbox"
										value={this.props.user.lastname}
										disabled
									/>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-md-4 pb-3">
									<p>Téléphone</p>
								</div>
								<div className="col-md-8 pb-3">
									<input
										type="text"
										className="p-textbox"
										value={this.props.user.phone_number}
										disabled
									/>
								</div>
							</div>
							<div className="row align-items-center">
								<div className="col-md-4 pb-3">
									<p>E-mail</p>
								</div>
								<div className="col-md-8 pb-3">
									<input type="text" className="p-textbox" value={this.props.user.email} disabled />
								</div>
							</div>

							<div className="row align-items-center mt-3">
								<div className="col-md-4 pb-3">
									<p>Mot de passe</p>
								</div>
								<div className="col-md-8 pb-3">
									<input
										type="button"
										className="p-submit-btn full-width-btn theme-button1"
										value="Générer un nouveau mot de passe"
										onClick={this.generateNewPassword.bind(this)}
									/>
								</div>
							</div>
						</form>
					</div>

				</div>
			</div>
		);
	}
}

export default UserScreen;
