import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, NavLink } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';

import DriverSummaryScreen from './driver_summary';
import DriverWithdrawsScreen from './driver_withdraws';

import UserProfilScreen from './user_profil';
import UserTripsScreen from './user_trips';
import UserSponsorshipScreen from './user_sponsorship';
import UserTripsRatesScreen from './user_triprates';

import UserBanPopup from '../popups/user_ban';
import UserUnbanPopup from '../popups/user_unban';

class UserScreen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			driver: null,
			user: null
		};
	}

	reloadDriver() {
		EZEEApi.getDriver(this.props.match.params.driverId).then((driver) => {
			this.setState({ driver: driver });
			EZEEApi.getUser(driver.user_id).then((user) => {
				this.setState({ user: user });
			});
		});
		
	}

	componentDidMount() {
		this.reloadDriver();
	}

	render() {
		if (this.state.driver == null || this.state.user == null) {
			return <div />;
		} else {
			return (
				<div>

					<div className="content-area-wrapper pt-5 pb-5">
						<div className="body-bg body-bg1 body-bg-pos" />
						<div className="body-bg body-bg2" />

						<div className="container">
							<div className="row mb-5">
								<div className="col-md-4 mb-5">
									<div className="shadow-shape2 user-profile-sidebar white-bg-section">
										<div className="s-back-link">
											<NavLink to={`/drivers`}>Retour aux chauffeurs</NavLink>
										</div>
										<div className="user-profile-info table-quick-links">
											<div className="user-profile-pic d-inline-block">
												<S3Picture type="user-profil" file={this.state.driver.User.picture} />
											</div>
											<div className="user-name-info d-inline-block">
												<h5>
													{this.state.driver.User.firstname} {this.state.driver.User.lastname}
												</h5>
												
											</div>
										</div>
										<div className="user-profile-links">
											<ul>

												<li
													className={`${this.props.location.pathname.includes('summary')
														? 'active-item'
														: null}`}
												>
													<NavLink to={`${this.props.match.url}/summary`}>Adhésion</NavLink>
												</li>

												<li
													className={`${this.props.location.pathname.includes('profil')
														? 'active-item'
														: null}`}
												>
													<NavLink to={`${this.props.match.url}/profil`}>Informations sur le profil</NavLink>
												</li>

												<li 
													className={`${this.props.location.pathname.includes('trips')
													? 'active-item'
													: null}`}
												>
													<NavLink
														to={`${this.props.match.url}/trips`}
													>
														Courses
													</NavLink>
												</li>

												<li
													className={`${this.props.location.pathname.includes('rates')
														? 'active-item'
														: null}`}
												>
													<NavLink to={`${this.props.match.url}/rates`}>
														Notes et avis des chauffeurs
													</NavLink>
												</li>
												
												<li
													className={`${this.props.location.pathname.includes('withdraws')
														? 'active-item'
														: null}`}
												>
													<NavLink to={`${this.props.match.url}/withdraws`}>Virements</NavLink>
												</li>

												
											</ul>
										</div>
										
									</div>
								</div>

								

								<Route
									path={`${this.props.match.url}/summary`}
									render={(props) => <DriverSummaryScreen driver={this.state.driver} {...props} />}
								/>

								<Route
									path={`${this.props.match.url}/profil`}
									render={(props) => <UserProfilScreen user={this.state.user} {...props} />} 
								/>

								<Route
									path={`${this.props.match.url}/trips`}
									render={(props) => <UserTripsScreen user={this.state.user} as={'driver'} {...props} />}
								/>

								<Route
									path={`${this.props.match.url}/rates`}
									render={(props) => <UserTripsRatesScreen user={this.state.user} driver={this.state.driver} as={'driver'} {...props} />}
								/>

								<Route
									path={`${this.props.match.url}/withdraws`}
									render={(props) => <DriverWithdrawsScreen driver={this.state.driver} {...props} />}
								/>

							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default UserScreen;
