import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import TransactionRefundPopup from '../popups/transaction_refund';
import env from '../../env'

class TransactionScreen extends Component {

    constructor(props) {
        super(props)

        this.state = {
            transaction: null
        }

    }

    componentDidMount() {
        this.reloadTransaction()
    }

    reloadTransaction() {
        EZEEApi.getTransaction(this.props.match.params.transactionId).then((transaction) => {
            this.setState({ transaction: transaction })
        })
    }

    render() {

        if (this.state.transaction == null) {
            return (<div></div>)
        }

        return (
            <div className="content-area-wrapper pt-5 pb-5">

                <div className="body-bg body-bg1"></div>
                <div className="body-bg body-bg2"></div>

                <div className="container">
                    <div className="row">
                        <div className="visit-trajet">
                            <NavLink to={`/transactions`} ><img src="/images/arrow-left-f.svg" alt="" />Retour aux commandes</NavLink>
                        </div>
                    </div>

                    <div className="white-bg-section shadow-shape2 mb-5">
                        <div className="row detail-table-header-border">
                            <div className="col-12">
                                <div className="detail-table-row-header detail-table-header">
                                    <h4>Détail de la commande</h4>
                                </div>
                            </div>
                        </div>

                        <div class="row detail-table-content-wrapper">
                            <div class="col-12">
                                <div class="detail-table full-width">
                                    <div class="detail-table-row empty-row row m-0 align-items-center">
                                    </div>
                                </div>
                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Ref</h6></div>
                                        <div class="detail-table-cell">{this.state.transaction.id}</div>
                                        <div className="detail-table-cell right">
                                            <a className="detail-button popup-info_open" target="_blank" rel="noopener noreferrer" href={`${env.s3BaseUrl + 'invoices'}/${this.state.transaction.reference}.pdf`}>Voir la facture</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Chauffeur</h6></div>
                                        <div class="detail-table-cell">
                                            <span class="t-client-icon">
                                                <S3Picture type="user-profil" file={(this.state.transaction.CreditedUser || { picture: 'default.jpg' }).picture} />
                                            </span>
                                            {(this.state.transaction.CreditedUser || { lastname: "Booking" }).lastname} {(this.state.transaction.CreditedUser || { firstname: "Booking" }).firstname}
                                        </div>
                                        <div class="detail-table-cell right btn-media">
                                            <NavLink to={`/drivers/${this.state.transaction.driver_id}/summary`} class="view-profile-btn " style={{ marginRight: '10px' }}>Voir le profil chauffeur</NavLink>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                </div>
                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Client</h6></div>
                                        <div class="detail-table-cell">
                                            <span class="t-client-icon"><S3Picture type="user-profil" file={this.state.transaction.DebitedUser.picture} /> </span>

                                            {
                                                this.state.transaction.DebitedUser.is_professional ?
                                                    ` Pro - ${this.state.transaction.DebitedUser.professional_name} pour ${(this.state.transaction.Trip || this.state.transaction.Booking).UserProfessionalCollaborator.firstname} ${(this.state.transaction.Trip || this.state.transaction.Booking).UserProfessionalCollaborator.lastname}` :
                                                    ` ${this.state.transaction.DebitedUser.firstname} ${this.state.transaction.DebitedUser.lastname} ${(this.state.transaction.Trip || this.state.transaction.Booking).user_company_id && `(${(this.state.transaction.Trip || this.state.transaction.Booking).UserCompany.name}) `}`
                                            }

                                            {/* {this.state.transaction.DebitedUser.lastname} {this.state.transaction.DebitedUser.firstname} */}
                                        </div>
                                        <div class="detail-table-cell right btn-media">
                                            <NavLink to={`/users/${this.state.transaction.DebitedUser.id}/profil`} class="view-profile-btn ">Voir le profil</NavLink>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                </div>
                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Adresse</h6></div>
                                        <div class="detail-table-cell">
                                            <div class="detail-user-info"><span class="client-route black">Départ</span>
                                                <span class="address-media">{(this.state.transaction.Trip || this.state.transaction.Booking).address_from}</span>
                                            </div>
                                            <div class="detail-user-info"><span class=" client-route black">Arrivée</span>
                                                <span class="address-media">{(this.state.transaction.Trip || this.state.transaction.Booking).address_to}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.transaction.Trip && (
                                    <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                        <div class="col-12 p-0">
                                            <div class="detail-table-cell detail-title-cell"><h6>Plaque immatriculation</h6></div>
                                            <div class="detail-table-cell">{this.state.transaction.Trip.plate_number}</div>
                                        </div>
                                    </div>
                                )}
                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Prix Payé</h6></div>
                                        <div class="detail-table-cell">{this.state.transaction.amount_paid / 100} €</div>
                                        <div class="detail-table-cell detail-title-cell auto-width"><h6>Code promo</h6></div>
                                        <div class="detail-table-cell">{(this.state.transaction.amount_voucher || 0) / 100} €</div>
                                        <div class="detail-table-cell detail-title-cell auto-width"><h6>Prix Normal</h6></div>
                                        <div class="detail-table-cell">{(this.state.transaction.amount_total || 0) / 100} €</div>
                                    </div>
                                </div>
                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Date</h6></div>
                                        <div class="detail-table-cell"><Moment format="DD/MM/YYYY">{this.state.transaction.created_at}</Moment></div>
                                    </div>
                                </div>
                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>CA généré</h6></div>
                                        <div class="detail-table-cell">{this.state.transaction.amount_paid / 100} €</div>
                                        <div class="detail-table-cell detail-title-cell auto-width"><h6>Bénéfice</h6></div>
                                        <div class="detail-table-cell">{this.state.transaction.ezee_fees_amount_total / 100} €</div>
                                        <div class="detail-table-cell detail-title-cell auto-width"><h6>Frais bancaires</h6></div>
                                        <div class="detail-table-cell">{this.state.transaction.stripe_fees_amount_total / 100} €</div>
                                    </div>
                                </div>

                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Statut</h6></div>
                                        <div className="detail-table-cell">{`${this.state.transaction.refunded === true ? 'Remboursé' : 'Accepté'} `}</div>
                                        {this.state.transaction.refunded === false ?
                                            <div className="detail-table-cell right">
                                                <TransactionRefundPopup
                                                    className="detail-button popup-info_open"
                                                    transaction={this.state.transaction}
                                                    onClose={() => this.reloadTransaction()}
                                                >Rembourser</TransactionRefundPopup>
                                            </div>
                                            :
                                            <>
                                                <div className="detail-table-cell">
                                                    <p>Remboursement le <Moment format="DD/MM/YYYY">{this.state.transaction.updated_at}</Moment></p>
                                                    <p>Remboursement de {this.state.transaction.TransactionRefund.amount_refunded / 100} €</p>
                                                    <p>Raison du remboursement: </p>
                                                    <p>{this.state.transaction.TransactionRefund.reason_refunded}</p>
                                                </div>
                                                <div className="detail-table-cell right">
                                                    <a className="detail-button popup-info_open" target="_blank" rel="noopener noreferrer" href={`${env.s3BaseUrl + 'creditnotes'}/${this.state.transaction.TransactionRefund.reference}.pdf`}>Voir la facture d'avoir</a>
                                                </div>
                                            </>
                                        }
                                        <div className="clear"></div>
                                    </div>
                                </div>

                                <div class="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div class="col-12 p-0">
                                        <div class="detail-table-cell detail-title-cell"><h6>Payé au chauffeur</h6></div>
                                        <div class="detail-table-cell"> {this.state.transaction.driver_withdraw_id ? "La course a été payé au chauffeur." : "La course n'a pas été payé au chauffeur (Aucun virement pour le moment)."} </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* <div className="row detail-table-content-wrapper">
                        <div className="col-12">
                            <div className="detail-table full-width">
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Ref de la commande</h6></div>
                                        <div className="detail-table-cell">{this.state.transaction.id}</div>
                                        <div className="detail-table-cell pt-2 right"><a  ><span className="report-link">Voir la facture (NW)</span></a></div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Date</h6></div>
                                        <div className="detail-table-cell">
                                            <Moment format="DD/MM/YYYY">{this.state.transaction.created_at}</Moment>
                                        </div>
                                    </div>    
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Heure</h6></div>
                                        <div className="detail-table-cell">
                                            <Moment format="HH[h]mm">{this.state.transaction.created_at}</Moment>
                                        </div>
                                    </div>    
                                </div>                                                 
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Adresse</h6></div>
                                        <div className="detail-table-cell">
                                            <div className="detail-user-info"><span className="client-route black">Départ</span>
                                                <span className="address-media">{(this.state.transaction.Trip || this.state.transaction.Booking).address_from}</span>
                                            </div>
                                            <div className="detail-user-info"><span className=" client-route black">Arrivée</span>
                                                <span className="address-media">{(this.state.transaction.Trip || this.state.transaction.Booking).address_to}</span>
                                            </div>
                                        </div>
                                        </div>  
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Montant total</h6></div>
                                        <div className="detail-table-cell">{this.state.transaction.debited_amount / 100} €</div>
                                        <div className="detail-table-cell detail-title-cell auto-width"><h6>Com<br /> HT</h6></div>
                                        <div className="detail-table-cell">{this.state.transaction.fees_pickmiup_vat_excluded / 100} €</div>
                                        <div className="detail-table-cell detail-title-cell auto-width"><h6>Com<br /> TTC</h6></div>
                                        <div className="detail-table-cell">{this.state.transaction.fees_pickmiup_vat_included / 100} €</div>
                                        <div className="detail-table-cell detail-title-cell auto-width"><h6>Frais<br /> Banc</h6></div>
                                        <div className="detail-table-cell">{this.state.transaction.fees_mango_vat_included / 100} €</div>
                                    </div>
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Statut</h6></div>
                                        <div className="detail-table-cell">{ `${ this.state.transaction.refunded === true ? 'Remboursé' : 'Accepté' } ` }</div>   
                                        { this.state.transaction.refunded === false ?     
                                            <div className="detail-table-cell right">
                                                <TransactionRefundPopup 
                                                    className="detail-button popup-info_open" 
                                                    transaction={this.state.transaction}
                                                    onClose={() => this.reloadTransaction()}
                                                >Rembourser</TransactionRefundPopup>
                                            </div>
                                            :
                                            <div className="detail-table-cell detail-transaction-status-cell pt-3 pb-3">
                                                <p>Remboursement par {

                                                    this.state.transaction.refund_type == 1 ? 
                                                        'le chauffeur'
                                                    : 
                                                        this.state.transaction.refund_type == 2 ? 
                                                            'le passager'
                                                        :
                                                            "l'administrateur"
                                                    
                                                } le <Moment format="DD/MM/YYYY">{this.state.transaction.updated_at}</Moment></p>
                                                <p>Remboursement de {this.state.transaction.refunded_amount / 100} €</p>
                                                <p>Raison du remboursement: </p>
                                                <p>{this.state.transaction.Passenger.left_reason}</p>
                                            </div>  
                                        } 
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    
                </div>*/}

                </div>
            </div>
        );
    }
}

export default TransactionScreen;