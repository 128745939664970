import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import Pagination from '../Pagination';
import TableComponent from '../TableComponent';

import LoadingOverlay from 'react-loading-overlay';
class UserSponsorshipScreen extends TableComponent {
	componentDidMount() {
		this.reloadData();
	}

	reloadData(instant) {
		super.reloadData(() => {
			EZEEApi.getUserSponsorship(this.props.user.id, this.state.qs).then((sponsorship) => {
				console.log(sponsorship)
				this.setState({
					count: sponsorship.count,
					data: sponsorship.rows
				});
			});
		}, instant);
	}

	render() {
		return (
			<div className="col-md-8">
				<div className="shadow-shape2 white-bg-section pb-4">

					<div class="row align-items-center s-padding-sec">
						<div class="col-sm-12">
							<h3>Parrainage ({this.state.count})</h3>
							Utilisateurs ayant appliquer son code de parainage afin d'effectuer une course avec Ezee.
						</div>
					</div>

					<div className="s-table-content-wrapper s-virements-table-wrap">
						<div className="col-12 p-0">
							<div className="d-table full-width s-table-content">

								<div class="d-table-row table-header table-header-middle">
									<div class="d-table-cell"><span class="s-down-arrow">NOM DU CLIENT</span></div>
									<div class="d-table-cell"><span class="s-down-arrow">DATE</span></div>
									<div class="d-table-cell"><span class="s-down-arrow">CODE</span></div>
									<div class="d-table-cell"><span class="s-down-arrow">ECONOMIES</span></div>
								</div>

								{this.state.data.map((sponsorship) => {
									return (

										<div class="d-table-row table-data-row">
											<div class="d-table-cell">{sponsorship.CreatedFor.firstname} {sponsorship.CreatedFor.lastname}</div>
											<div class="d-table-cell"><Moment format="DD/MM/YYYY">{sponsorship.created_at}</Moment></div>
											<div class="d-table-cell">{sponsorship.code}</div>
											<div class="d-table-cell">-{sponsorship.amount / 100} €</div>
										</div>

									);
								})}
							</div>
						</div>
					</div>

					<Pagination
						count={this.state.count}
						page={this.state.qs.page}
						limit={this.state.qs.limit}
						onPageChange={(page) => {
							this.onPageChange(page);
						}}
					/>
				</div>
			</div>
		);
	}
}

export default UserSponsorshipScreen;
