import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import EZEEApi from '../../../services/EZEEApi';
import ReactS3Uploader from 'react-s3-uploader';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import S3FilePopup from '../../popups/s3file';
import S3Picture from '../../S3Picture';
import moment from 'moment';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
class MeScreen extends Component {
	uploaders = {};

	constructor(props) {
		super(props);

		this.state = {
			user_id: 0,
			driver_id: 0,

			fullname: '',
			IBAN: '',
			BIC: '',

			picture: '',
			firstname: '',
			lastname: '',
			phone_number: '',
			birthdate: new Date('1990'),

			vehicle_type_id: 1,

			email_pro: '',

			structure_type: '',

			structure_social_reason: '',
			structure_address_line: '',
			structure_address_postal_code: '',
			structure_address_city: '',
			structure_address_country: '',

			card_id_front: '',
			card_id_back: '',

			dwelling: '',

			card_pro_front: '',
			card_pro_back: '',

			insurance: '',

			card_id_front_error: '',
			card_id_back_error: '',

			dwelling_error: '',

			card_pro_front_error: '',
			card_pro_back_error: '',

			insurance_error: ''
		};
	}

	componentDidMount() {
		this.reloadData();
	}

	reloadData() {
		EZEEApi.getMe('me').then((user) => {
			this.setState({
				user_id: user.id,
				picture: user.picture,
				firstname: user.firstname,
				lastname: user.lastname,
				phone_number: user.phone_number,
				birthdate: new Date(user.birthdate) || new Date('1990'),
				email: user.email,
				is_driver: user.is_driver
			});

			EZEEApi.getDriverBankAccount('me').then((ba) => {
				this.setState({
					fullname: ba.fullname,
					IBAN: ba.IBAN,
					BIC: ba.BIC
				});
			});

			EZEEApi.getDriver('me').then((driver) => {
				this.setState({
					driver_id: driver.id,

					email_pro: driver.email_pro !== '' ? driver.email_pro : user.email,

					structure_type: driver.structure_type,

					structure_social_reason: driver.structure_social_reason,
					structure_address_line: driver.structure_address_line,
					structure_address_postal_code: driver.structure_address_postal_code,
					structure_address_city: driver.structure_address_city,
					structure_address_country: driver.structure_address_country,
					structure_registration_number: driver.structure_registration_number,
					structure_tva_number: driver.structure_tva_number,


					card_id: driver.DriverDocument.card_id || '',
					card_id_back: driver.DriverDocument.card_id_back || '',

					card_pro_front: driver.DriverDocument.card_pro_front || '',
					card_pro_back: driver.DriverDocument.card_pro_back || '',

					card_driver_license: driver.DriverDocument.card_driver_license || '',

					picture: driver.DriverDocument.picture || '',

					dwelling: driver.DriverDocument.dwelling || '',

					insurance: driver.DriverDocument.insurance || '',

					medical_checkup: driver.DriverDocument.medical_checkup || '',

					licence_atout: driver.DriverDocument.licence_atout || '',

					kbis: driver.DriverDocument.kbis || '',

					rib_company: driver.DriverDocument.rib_company || '',

					vehicle_card: driver.DriverDocument.vehicle_card || '',
					vehicle_picture: driver.DriverDocument.vehicle_picture || '',
					vehicle_insurance: driver.DriverDocument.vehicle_insurance || '',

					// Error handler

					card_id_error: driver.DriverDocument.card_id_error || '',

					card_id_back_error: driver.DriverDocument.card_id_back_error || '',

					card_pro_front_error: driver.DriverDocument.card_pro_front_error || '',
					card_pro_back_error: driver.DriverDocument.card_pro_back_error || '',

					card_driver_license_error: driver.DriverDocument.card_driver_license_error || '',

					picture_error: driver.DriverDocument.picture_error || '',

					dwelling_error: driver.DriverDocument.dwelling_error || '',

					insurance_error: driver.DriverDocument.insurance_error || '',

					medical_checkup_error: driver.DriverDocument.medical_checkup_error || '',

					licence_atout_error: driver.DriverDocument.licence_atout_error || '',

					kbis_error: driver.DriverDocument.kbis_error || '',

					rib_company_error: driver.DriverDocument.rib_company_error || '',

					vehicle_card_error: driver.DriverDocument.vehicle_card_error || '',
					vehicle_picture_error: driver.DriverDocument.vehicle_picture_error || '',
					vehicle_insurance_error: driver.DriverDocument.vehicle_insurance_error || ''
				});
			});
		});
	}

	getSignedUrlForPost(file, callback, type) {
		EZEEApi.getSignedUrlForPost({
			objectName: file.name,
			contentType: file.type,
			path: `documents/${type}/`
		}).then((data) => {
			callback(data);
		});
	}

	onUploadFinish(data, type) {
		EZEEApi.postDriverDocument('me', {
			type: type,
			document: data.filename
		}).then(() => {
			toast.success('Votre document a bien été reçu', {
				position: toast.POSITION.TOP_RIGHT
			});

			this.setState({
				[type]: data.filename,
				[type + '_error']: '',
				[type + '_expiration']: null
			});
		});
	}

	onUploadStart(file, next, type) {
		if (file.size <= 10000000) {
			next(file);
		} else {
			toast.error('Votre fichier dépasse les 10 MO.', {
				position: toast.POSITION.TOP_RIGHT
			});
			this.uploaders[type].clear();
		}
	}

	renderIconMark() {
		return (
			<img
				alt="icon-markup"
				style={{
					float: 'left',
					width: '16px',
					height: '16px'
				}}
				src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Feedbin-Icon-check.svg/1280px-Feedbin-Icon-check.svg.png"
				width="16"
			/>
		);
	}

	updateEverything() {
		this.updateMe().then(() => {
			this.createOrUpdateDriver().then(() => {
				this.updateBankaccount().then(() => {
					this.reloadData();
				});
			});
		});
	}

	updateMe() {
		return new Promise((resolve, reject) => {
			EZEEApi.updateMyAccount({
				firstname: this.state.firstname,
				lastname: this.state.lastname,
				phone_number: this.state.phone_number,
				birthdate: this.state.birthdate.toISOString()
			})
				.then((data) => {
					resolve();

					// toast.success('Modification bien reçu !', {
					//     position: toast.POSITION.TOP_RIGHT
					// })
				})
				.catch((data) => {
					reject();

					toast.error(
						'Une erreur est survenue lors de la modification de vos informations personnelles, merci de vérifier les informations renseignées.',
						{
							position: toast.POSITION.TOP_RIGHT
						}
					);
				});
		});
	}

	updateBankaccount() {
		return new Promise((resolve, reject) => {
			EZEEApi.postDriverBankaccount('me', {
				fullname: this.state.fullname,
				IBAN: this.state.IBAN,
				BIC: this.state.BIC
			})
				.then(() => {
					resolve();

					toast.success('Le compte bancaire a bien été enregistré.', {
						position: toast.POSITION.TOP_RIGHT
					});
				})
				.catch((data) => {
					reject();

					toast.error(
						'Une erreur est survenue lors de la modification du compte bancaire, merci de vérifier les informations renseignées.',
						{
							position: toast.POSITION.TOP_RIGHT
						}
					);
				});
		});
	}

	createOrUpdateDriver() {
		return new Promise((resolve, reject) => {
			EZEEApi.postDriver('me', {
				email_pro: this.state.email_pro,

				vehicle_type_id: this.state.vehicle_type_id,

				structure_type: this.state.structure_type,

				structure_social_reason: this.state.structure_social_reason,
				structure_address_line: this.state.structure_address_line,
				structure_address_postal_code: this.state.structure_address_postal_code,
				structure_address_city: this.state.structure_address_city,
				structure_address_country: this.state.structure_address_country,
				structure_registration_number: this.state.structure_registration_number,
				structure_tva_number: this.state.structure_tva_number

			})
				.then((data) => {
					resolve();

					toast.success('Votre compte chauffeur a bien été enregistré.', {
						position: toast.POSITION.TOP_RIGHT
					});
				})
				.catch((data) => {
					reject();

					toast.error(
						'Une erreur est survenue lors de la modification de vos informations de votre compte chauffeur.',
						{
							position: toast.POSITION.TOP_RIGHT
						}
					);
				});
		});
	}

	formMeIsValid() {
		return (
			/^(\+33|0)[1-9](\d\d){4}$/.test(this.state.phone_number) &&
			this.state.firstname !== '' &&
			this.state.firstname.length > 1 &&
			this.state.lastname !== '' &&
			this.state.lastname.length > 1 &&
			this.state.phone_number !== '' &&
			this.state.birthdate !== '' &&
			this.state.email !== ''
		);
	}

	formDriverIsValid() {
		return (
			this.state.email_pro !== '' &&
			this.state.structure_type !== '' &&
			this.state.structure_social_reason !== '' &&
			this.state.structure_address_line !== '' &&
			this.state.structure_address_postal_code !== '' &&
			this.state.structure_address_city !== '' &&
			this.state.structure_address_country !== ''
		);
	}

	formBackaccountIsValid() {
		return this.state.fullname !== '' && this.state.IBAN !== '' && this.state.BIC !== '';
	}

	handleDateChange(date) {
		this.setState({
			birthdate: date
		});
	}

	render() {
		return (
			<div className="content-area-wrapper pt-5 pb-5">
				<style>
					{'.react-datepicker-wrapper {display: block;}.react-datepicker__input-container {display: block;}'}
				</style>

				<div className="body-bg body-bg1 body-bg-pos" />
				<div className="body-bg body-bg2" />

				<div className="container">
					<div className="row mb-5">
						<div className="col-12">
							<div className="shadow-shape2 user-content-section white-bg-section pb-4 mb-5rem">
								<div className="s-padding-sec3 heading-bottom-border">
									<h3>Vos informations</h3>
								</div>

								<div className="s-padding-sec3">
									<div className="pt-3">
										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>Prénom</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<input
													className={`p-textbox ${this.state.firstname === '' &&
														'error-text'}`}
													type="text"
													placeholder="John"
													value={this.state.firstname}
													onChange={(e) => {
														this.setState({ firstname: e.target.value });
													}}
												/>
											</div>
										</div>
										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>Nom</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<input
													className={`p-textbox ${this.state.lastname === '' &&
														'error-text'}`}
													type="text"
													placeholder="Doe"
													value={this.state.lastname}
													onChange={(e) => {
														this.setState({ lastname: e.target.value });
													}}
												/>
											</div>
										</div>
										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>Téléphone</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<input
													type="text"
													className={`p-textbox ${this.state.phone_number === '' &&
														/^(\+33|0)[1-9](\d\d){4}$/.test(this.state.phone_number) ===
														false &&
														'error-text'}`}
													placeholder="06 12 34 56 78"
													value={this.state.phone_number}
													onChange={(e) => {
														this.setState({ phone_number: e.target.value });
													}}
												/>
											</div>
										</div>
										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>Date de naissance</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<DatePicker
													className="p-textbox datepicker"
													selected={this.state.birthdate}
													placeholder="01/02/1980"
													onChange={this.handleDateChange.bind(this)}
													dateFormat="dd/MM/yyyy"
												/>
											</div>
										</div>
										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>E-mail</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<input
													type="text"
													className="p-textbox"
													placeholder="email@provider.fr"
													value={this.state.email}
													disabled
												/>
											</div>
										</div>

										<hr />

										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>Véhicule</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<br />
												<ul className="popup-list-choices green-check-boxes p-0 m-0">
													<li>
														<input
															type="radio"
															name="user-choice"
															id="vehicle_type_id1"
															checked={this.state.vehicle_type_id === 1}
															onClick={() => this.setState({ vehicle_type_id: 1 })}
														/>
														<label for="vehicle_type_id1">Moto</label>
													</li>
													<li>
														<input
															type="radio"
															name="user-choice"
															id="vehicle_type_id2"
															checked={this.state.vehicle_type_id === 2}
															onClick={() => this.setState({ vehicle_type_id: 2 })}
														/>
														<label for="vehicle_type_id2">Voiture</label>
													</li>
												</ul>
											</div>
										</div>

										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>
													E-mail Pro<br />(compta)
												</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<br />
												<input
													type="text"
													className={`p-textbox ${this.state.email_pro === '' &&
														'error-text'}`}
													placeholder="email@provider.fr"
													value={this.state.email_pro}
													onChange={(e) => {
														this.setState({ email_pro: e.target.value });
													}}
												/>
											</div>
										</div>

										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>Structure</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<div className="s-select-box">
													<select
														name="atructure"
														className={`p-textbox p-selectbox ${this.state
															.structure_type === '' && 'error-text'}`}
														value={this.state.structure_type}
														onChange={(e) => {
															this.setState({ structure_type: e.target.value });
														}}
													>
														<option value="">Type de structure</option>
														<option value="AUTO-ENTREPRISE">AUTO-ENTREPRISE</option>
														<option value="SARL">SARL</option>
														<option value="SASU">SASU</option>
														<option value="EURL">EURL</option>
														<option value="SAS">SAS</option>
													</select>
												</div>
											</div>
										</div>
										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>Raison sociale</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<input
													type="text"
													className={`p-textbox ${this.state.structure_social_reason === '' &&
														'error-text'}`}
													placeholder="Mon entreprise"
													value={this.state.structure_social_reason}
													onChange={(e) => {
														this.setState({ structure_social_reason: e.target.value });
													}}
												/>
											</div>
										</div>
										<div className="row align-items-start">
											<div className="col-md-3 col-sm-12 pb-3 pt-3">
												<p>Adresse</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<input
													type="text"
													className={`p-textbox ${this.state.structure_address_line === '' &&
														'error-text'}`}
													placeholder="Adresse"
													value={this.state.structure_address_line}
													onChange={(e) => {
														this.setState({ structure_address_line: e.target.value });
													}}
												/>
												<div className="row pt-3">
													<div className="col-md-6 pb-3">
														<input
															type="text"
															className={`p-textbox ${this.state
																.structure_address_postal_code === '' && 'error-text'}`}
															placeholder="Code postale"
															value={this.state.structure_address_postal_code}
															onChange={(e) => {
																this.setState({
																	structure_address_postal_code: e.target.value
																});
															}}
														/>
													</div>
													<div className="col-md-6 pb-3">
														<input
															type="text"
															className={`p-textbox ${this.state
																.structure_address_city === '' && 'error-text'}`}
															placeholder="Ville"
															value={this.state.structure_address_city}
															onChange={(e) => {
																this.setState({
																	structure_address_city: e.target.value
																});
															}}
														/>
													</div>
												</div>
												<input
													type="text"
													className={`p-textbox ${this.state.structure_address_country ===
														'' && 'error-text'}`}
													placeholder="France"
													value={this.state.structure_address_country}
													onChange={(e) => {
														this.setState({ structure_address_country: e.target.value });
													}}
												/>
											</div>
										</div>
										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>
													Numéro<br />d’immatriculation RCS
												</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<input
													type="text"
													className={`p-textbox ${this.state.structure_registration_number ===
														'' && 'error-text'}`}
													placeholder="Numéro SIRET ex : 9876299719288"
													value={this.state.structure_registration_number}
													onChange={(e) => {
														this.setState({
															structure_registration_number: e.target.value
														});
													}}
												/>
											</div>



										</div>

										<div className="row align-items-center">
											<div className="col-md-3 col-sm-12 pb-3">
												<p>
													Numéro<br />TVA
												</p>
											</div>
											<div className="col-md-9 col-sm-12 pb-3">
												<input
													type="text"
													className={`p-textbox ${this.state.structure_tva_number ===
														'' && 'error-text'}`}
													placeholder="Numéro TVA"
													value={this.state.structure_tva_number}
													onChange={(e) => {
														this.setState({
															structure_tva_number: e.target.value
														});
													}}
												/>
											</div>



										</div>



										<hr />

										<div class="row align-items-start">
											<div class="col-md-3 col-sm-12 pb-3 pt-3">
												<p>Titulaire du compte bancaire</p>
											</div>
											<div class="col-md-9 col-sm-12">
												<div class="row">
													<div class="col-md-12 pb-3">
														<input
															type="text"
															className={`p-textbox ${this.state.fullname === '' &&
																'error-text'}`}
															placeholder="Nom complet"
															value={this.state.fullname}
															onChange={(e) => {
																this.setState({ fullname: e.target.value });
															}}
														/>
													</div>
												</div>
											</div>
										</div>
										<div class="row align-items-center">
											<div class="col-md-3 col-sm-12 pb-3">
												<p>IBAN</p>
											</div>
											<div class="col-md-9 col-sm-12 pb-3">
												<input
													type="text"
													className={`p-textbox ${this.state.IBAN === '' && 'error-text'}`}
													placeholder="ex : FR14 2004 1010 0505 0001 3M02 606"
													value={this.state.IBAN}
													onChange={(e) => {
														this.setState({ IBAN: e.target.value });
													}}
												/>
											</div>
										</div>
										<div class="row align-items-center">
											<div class="col-md-3 col-sm-12 pb-3">
												<p>BIC</p>
											</div>
											<div class="col-md-9 col-sm-12 pb-3">
												<input
													type="text"
													className={`p-textbox ${this.state.BIC === '' && 'error-text'}`}
													placeholder="ex : SOGEFRPP"
													value={this.state.BIC}
													onChange={(e) => {
														this.setState({ BIC: e.target.value });
													}}
												/>
											</div>
										</div>

										<div class="row align-items-center mt-5">
											<div class="col-md-12 col-sm-12 pb-3 align-right">
												{/* <button disabled={!this.formBackaccountIsValid()} className="theme-button1" onClick={() => { this.updateBankaccount() }}>Sauvegarder les modifications</button> */}
												<button
													disabled={
														!this.formMeIsValid() ||
														!this.formBackaccountIsValid() ||
														!this.formDriverIsValid()
													}
													className="theme-button1"
													onClick={() => {
														this.updateEverything();
													}}
												>
													Sauvegarder les modifications
												</button>
											</div>
										</div>

										{/* <div className="pt-5 pb-5 s-padding-sec3 align-right">
                                        <button disabled={
                                            !this.formDriverIsValid() ||
                                            !this.formBackaccountIsValid()

                                        } className="theme-button1" onClick={() => { this.updateEverything() }}>Sauvegarder les modifications</button>
                                    </div> */}
									</div>
								</div>

								{this.state.driver_id !== 0 && (
									<div>
										<div className="s-padding-sec3 heading-bottom-border">
											<h3>Vos documents justificatifs </h3>
											<br />

											{(this.state.card_id === '' ||
												this.state.card_pro_front === '' ||
												this.state.card_pro_back === '' ||
												this.state.card_driver_license === '' ||
												this.state.picture === '' ||
												this.state.dwelling === '' ||
												this.state.insurance === '' ||
												this.state.licence_atout === '' ||
												this.state.kbis === '' ||
												this.state.medical_checkup === '' ||
												this.state.rib_company === '' ||
												this.state.vehicle_card === '' ||
												this.state.vehicle_picture === '' ||
												this.state.vehicle_insurance === '') && (
													<p style={{ color: 'red' }}>
														Certains documents sont manquants, merci de les vérifier ci-dessous.
												</p>
												)}

											{(this.state.card_id_error !== '' ||
												this.state.card_pro_front_error !== '' ||
												this.state.card_pro_back_error !== '' ||
												this.state.card_driver_license_error !== '' ||
												this.state.picture_error !== '' ||
												this.state.dwelling_error !== '' ||
												this.state.insurance_error !== '' ||
												this.state.licence_atout_error !== '' ||
												this.state.medical_checkup_error === '' ||
												this.state.kbis_error !== '' ||
												this.state.rib_company_error !== '' ||
												this.state.vehicle_card_error !== '' ||
												this.state.vehicle_picture_error !== '' ||
												this.state.vehicle_insurance_error !== '') && (
													<p style={{ color: 'red' }}>
														Certains documents sont invalides, merci de les vérifier ci-dessous.
												</p>
												)}
										</div>

										<div
											className={`s-padding-sec3 gray-box-bg content-block pt-5 pb-5 ${this.state
												.card_id === '' && 'error-text'} `}
										>
											{this.state.card_id !== '' && this.renderIconMark()}

											<h5>Pièces d’identité</h5>

											{this.state.card_id_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.card_id_error}{' '}
												</span>
											)}

											<p>
												Carte d’identité recto en cours de validité (carte d'identité,
												passeport, titre du séjour)
											</p>
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.card_id = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'card_id');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'card_id');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'card_id');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>
												<div className="d-inline-block">
													<span>
														{this.state.card_id !== '' ? (
															<S3FilePopup type="card_id" file={this.state.card_id}>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div className={`s-padding-sec3 gray-box-bg content-block pt-5 pb-5`}>
											{this.state.card_id_back !== '' && this.renderIconMark()}

											<h5>Pièces d’identité Verso</h5>

											{this.state.card_id_back_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.card_id_back_error}{' '}
												</span>
											)}

											<p>
												Carte d’identité verso en cours de validité (carte d'identité,
												passeport, titre du séjour)
											</p>
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.card_id_back = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'card_id_back');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'card_id_back');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'card_id_back');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>
												<div className="d-inline-block">
													<span>
														{this.state.card_id_back !== '' ? (
															<S3FilePopup
																type="card_id_back"
																file={this.state.card_id_back}
															>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 gray-box-bg content-block pt-5 pb-5 ${(this.state
												.card_pro_front === '' ||
												this.state.card_pro_back === '') &&
												'error-text'} `}
										>
											{this.state.card_pro_front !== '' && this.renderIconMark()}

											<h5>Carte professionnelle</h5>

											{this.state.card_pro_front_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.card_pro_front_error}{' '}
												</span>
											)}
											{this.state.card_pro_back_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.card_pro_back_error}{' '}
												</span>
											)}

											<p>Carte professionnelle recto en cours de validité </p>
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.card_pro_front = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'card_pro_front');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'card_pro_front');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'card_pro_front');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>
												<div className="d-inline-block">
													<span>
														{this.state.card_pro_front !== '' ? (
															<S3FilePopup
																type="card_pro_front"
																file={this.state.card_pro_front}
															>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>

											<hr />

											{this.state.card_pro_back !== '' && this.renderIconMark()}

											<p>Carte professionnelle verso en cours de validité </p>
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.card_pro_back = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'card_pro_back');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'card_pro_back');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'card_pro_back');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>
												<div className="d-inline-block">
													<span>
														{this.state.card_pro_back !== '' ? (
															<S3FilePopup
																type="card_pro_back"
																file={this.state.card_pro_back}
															>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 gray-box-bg content-block pt-5 pb-5 ${this.state
												.card_driver_license === '' && 'error-text'} `}
										>
											{this.state.card_driver_license !== '' && this.renderIconMark()}

											<h5>Permis de conduire</h5>

											{this.state.card_driver_license_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.card_driver_license_error}{' '}
												</span>
											)}

											<p>Permis de conduire recto en cours de validité </p>
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.card_driver_license = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'card_driver_license');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'card_driver_license');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(
																file,
																callback,
																'card_driver_license'
															);
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>
												<div className="d-inline-block">
													<span>
														{this.state.card_driver_license !== '' ? (
															<S3FilePopup
																type="card_driver_license"
																file={this.state.card_driver_license}
															>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 gray-box-bg content-block pt-5 pb-5 ${this.state
												.picture === '' && 'error-text'} `}
										>
											{this.state.picture !== '' && this.renderIconMark()}

											<h5>Votre photo</h5>

											{this.state.picture_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.picture_error}{' '}
												</span>
											)}

											<p>Votre visage (de face, visage centré et luminosité suffisante) </p>
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.picture = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'picture');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'picture');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'picture');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>
												<div className="d-inline-block">
													<span>
														{this.state.picture !== '' ? (
															<S3FilePopup type="picture" file={this.state.picture}>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 white-box-bg content-block pt-5 pb-5 ${this.state
												.dwelling === '' && 'error-text'} `}
										>
											{this.state.medical_checkup !== '' && this.renderIconMark()}

											<h5>Visite médicale</h5>

											{this.state.medical_checkup_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.medical_checkup_error}{' '}
												</span>
											)}
											<p />
											{/* <p>Document daté de moins de 3 mois à votre adresse personnelle : quittance de loyer, facture d’énergie, de téléphonie fixe ou Internet, avis d’imposition ou taxe d’habitation. </p> */}
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.medical_checkup = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'medical_checkup');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'medical_checkup');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'medical_checkup');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>
												<div className="d-inline-block">
													<span>
														{this.state.medical_checkup !== '' ? (
															<S3FilePopup
																type="medical_checkup"
																file={this.state.medical_checkup}
															>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 white-box-bg content-block pt-5 pb-5 ${this.state
												.dwelling === '' && 'error-text'} `}
										>
											{this.state.dwelling !== '' && this.renderIconMark()}

											<h5>Justificatif de domicile</h5>

											{this.state.dwelling_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.dwelling_error}{' '}
												</span>
											)}

											<p>
												Document daté de moins de 3 mois à votre adresse personnelle : quittance
												de loyer, facture d’énergie, de téléphonie fixe ou Internet, avis
												d’imposition ou taxe d’habitation.{' '}
											</p>
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.dwelling = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'dwelling');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'dwelling');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'dwelling');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>
												<div className="d-inline-block">
													<span>
														{this.state.dwelling !== '' ? (
															<S3FilePopup type="dwelling" file={this.state.dwelling}>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 white-box-bg content-block heading-bottom-border pt-5 pb-5 ${this
												.state.insurance === '' && 'error-text'} `}
										>
											{this.state.insurance !== '' && this.renderIconMark()}

											<h5>Assurance Responsabilité civile professionnelle</h5>
											{this.state.insurance_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.insurance_error}{' '}
												</span>
											)}
											<p>
												Adressé à chaque échéance annuelle de contrat, le relevé d’information
												peut également être remis sur simple demande de l’assuré. Il suffit de
												contacter votre compagnie d’assurance par téléphone ou d’en faire la
												demande par écrit pour recevoir le document sous un délai de 15 jours,
												selon l’article A. 121-1 du Code des assurances.
											</p>
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.insurance = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'insurance');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'insurance');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'insurance');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>

												<div className="d-inline-block">
													<span>
														{this.state.insurance !== '' ? (
															<S3FilePopup type="insurance" file={this.state.insurance}>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 white-box-bg content-block heading-bottom-border pt-5 pb-5 ${this
												.state.licence_atout === '' && 'error-text'} `}
										>
											{this.state.licence_atout !== '' && this.renderIconMark()}

											<h5>Licence Atout France ou Certificat d'inscription au Registre VTC</h5>
											{this.state.licence_atout_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.licence_atout_error}{' '}
												</span>
											)}
											{/* <p>Adressé à chaque échéance annuelle de contrat, le relevé d’information peut également être remis sur simple demande de l’assuré. Il suffit de contacter votre compagnie d’assurance par téléphone ou d’en faire la demande par écrit pour recevoir le document sous un délai de 15 jours, selon l’article A. 121-1 du Code des assurances.</p> */}
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.licence_atout = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'licence_atout');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'licence_atout');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'licence_atout');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>

												<div className="d-inline-block">
													<span>
														{this.state.licence_atout !== '' ? (
															<S3FilePopup
																type="licence_atout"
																file={this.state.licence_atout}
															>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 white-box-bg content-block heading-bottom-border pt-5 pb-5 ${this
												.state.kbis === '' && 'error-text'} `}
										>
											{this.state.kbis !== '' && this.renderIconMark()}

											<h5>Extrait KBIS (ou relevé SIRENE)</h5>
											{this.state.kbis_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.kbis_error}{' '}
												</span>
											)}
											{/* <p>Adressé à chaque échéance annuelle de contrat, le relevé d’information peut également être remis sur simple demande de l’assuré. Il suffit de contacter votre compagnie d’assurance par téléphone ou d’en faire la demande par écrit pour recevoir le document sous un délai de 15 jours, selon l’article A. 121-1 du Code des assurances.</p> */}
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.kbis = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'kbis');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'kbis');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'kbis');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>

												<div className="d-inline-block">
													<span>
														{this.state.kbis !== '' ? (
															<S3FilePopup type="kbis" file={this.state.kbis}>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 white-box-bg content-block heading-bottom-border pt-5 pb-5 ${this
												.state.rib_company === '' && 'error-text'} `}
										>
											{this.state.rib_company !== '' && this.renderIconMark()}

											<h5>Relevé d'identité Bancaire (RIB)</h5>
											{this.state.rib_company_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.rib_company_error}{' '}
												</span>
											)}
											{/* <p>Adressé à chaque échéance annuelle de contrat, le relevé d’information peut également être remis sur simple demande de l’assuré. Il suffit de contacter votre compagnie d’assurance par téléphone ou d’en faire la demande par écrit pour recevoir le document sous un délai de 15 jours, selon l’article A. 121-1 du Code des assurances.</p> */}
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.rib_company = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'rib_company');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'rib_company');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'rib_company');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>

												<div className="d-inline-block">
													<span>
														{this.state.rib_company !== '' ? (
															<S3FilePopup
																type="rib_company"
																file={this.state.rib_company}
															>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div className="s-padding-sec3 heading-bottom-border">
											<h3>Informations relatives à votre véhicule</h3>
										</div>

										{/* <div className="s-padding-sec3">
                                        <div className="pt-3">

                                            <div class="row align-items-center">
                                                <div class="col-md-3 col-sm-12 pb-3"><p>Plaque d'immatriculation</p></div>
                                                <div class="col-md-9 col-sm-12 pb-3"><input type="text"
                                                    className={`p-textbox ${this.state.vehicle_plate_number === '' && 'error-text'}`}
                                                    placeholder="ex : 123 ABC 75" value={this.state.vehicle_plate_number} onChange={(e) => { this.setState({ vehicle_plate_number: e.target.value }) }} /></div>
                                            </div>
                                        </div>
                                    </div> */}

										<div
											className={`s-padding-sec3 white-box-bg content-block heading-bottom-border pb-5 ${this
												.state.vehicle_card === '' && 'error-text'} `}
										>
											{this.state.vehicle_card !== '' && this.renderIconMark()}

											<h5>Carte grise du vehicule que vous allez utiliser</h5>
											{this.state.vehicle_card_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.vehicle_card_error}{' '}
												</span>
											)}
											{/* <p>Adressé à chaque échéance annuelle de contrat, le relevé d’information peut également être remis sur simple demande de l’assuré. Il suffit de contacter votre compagnie d’assurance par téléphone ou d’en faire la demande par écrit pour recevoir le document sous un délai de 15 jours, selon l’article A. 121-1 du Code des assurances.</p> */}
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.vehicle_card = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'vehicle_card');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'vehicle_card');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'vehicle_card');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>

												<div className="d-inline-block">
													<span>
														{this.state.vehicle_card !== '' ? (
															<S3FilePopup
																type="vehicle_card"
																file={this.state.vehicle_card}
															>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 white-box-bg content-block heading-bottom-border pt-5 pb-5 ${this
												.state.vehicle_picture === '' && 'error-text'} `}
										>
											{this.state.vehicle_picture !== '' && this.renderIconMark()}

											<h5>Photo extérieur du véhicule (face avant, plaque visible)</h5>
											{this.state.vehicle_picture_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.vehicle_picture_error}{' '}
												</span>
											)}
											{/* <p>Adressé à chaque échéance annuelle de contrat, le relevé d’information peut également être remis sur simple demande de l’assuré. Il suffit de contacter votre compagnie d’assurance par téléphone ou d’en faire la demande par écrit pour recevoir le document sous un délai de 15 jours, selon l’article A. 121-1 du Code des assurances.</p> */}
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.vehicle_picture = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'vehicle_picture');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'vehicle_picture');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(file, callback, 'vehicle_picture');
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>

												<div className="d-inline-block">
													<span>
														{this.state.vehicle_picture !== '' ? (
															<S3FilePopup
																type="vehicle_picture"
																file={this.state.vehicle_picture}
															>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>

										<div
											className={`s-padding-sec3 white-box-bg content-block heading-bottom-border pt-5 pb-5 ${this
												.state.vehicle_insurance === '' && 'error-text'} `}
										>
											{this.state.vehicle_insurance !== '' && this.renderIconMark()}

											<h5>Carte verte (correspond à l'attestation d'assurance)</h5>
											{this.state.vehicle_insurance_error !== '' && (
												<span>
													<span style={{ color: 'red' }}>
														{' '}
														Information par l'adminstrateur:{' '}
													</span>{' '}
													{this.state.vehicle_insurance_error}{' '}
												</span>
											)}
											{/* <p>Adressé à chaque échéance annuelle de contrat, le relevé d’information peut également être remis sur simple demande de l’assuré. Il suffit de contacter votre compagnie d’assurance par téléphone ou d’en faire la demande par écrit pour recevoir le document sous un délai de 15 jours, selon l’article A. 121-1 du Code des assurances.</p> */}
											<div className="c-block-meta pt-3 pb-2">
												<div className="d-inline-block">
													<ReactS3Uploader
														ref={(ref) => {
															this.uploaders.vehicle_insurance = ref;
														}}
														className="theme-button1 theme-button1-upload"
														preprocess={(file, next) => {
															this.onUploadStart(file, next, 'vehicle_insurance');
														}}
														onFinish={(data) => {
															this.onUploadFinish(data, 'vehicle_insurance');
														}}
														getSignedUrl={(file, callback) => {
															this.getSignedUrlForPost(
																file,
																callback,
																'vehicle_insurance'
															);
														}}
														accept="image/*, application/pdf"
														uploadRequestHeaders={{
															'x-amz-acl': 'private',
															'x-amz-meta-owner': this.state.user_id.toString()
														}}
														contentDisposition="auto"
													/>
												</div>

												<div className="d-inline-block">
													<span>
														{this.state.vehicle_insurance !== '' ? (
															<S3FilePopup
																type="vehicle_insurance"
																file={this.state.vehicle_insurance}
															>
																{' '}
																Voir le document déjà envoyé{' '}
															</S3FilePopup>
														) : (
																'Maximum 10 Mo'
															)}
													</span>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MeScreen;
