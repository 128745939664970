import React, { Component } from 'react';
import Popup from "reactjs-popup";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';

class S3FilePopup extends Component {

    constructor(props) {
        super(props)

        this.state = {
            source: ''
        }
    }

    onOpen() {
        EZEEApi.getSignedUrlForGet({
            path: `documents/${this.props.type}/${this.props.file}`
        }).then((data) => {
            this.setState({
                source: data.url
            })
        })
    }

    render() {

        return (
            <Popup modal={true} lockScroll={true} onOpen={() => { this.onOpen() }} trigger={<span href="#" className="s-search-icon link-a-simulation" style={{ 'margin-left': '5px' }}>{this.props.children}</span>} modal>

                {(close) => {

                    return (
                        <div className="popup-box">
                            <div className="close-popup popup-info1_close"><img src="/images/close-icon.svg" alt="Close" onClick={() => close()} /></div>
                            <div className="popup-box-inner">
                                <div className="popup-main align-center">
                                    <img src={this.state.source} alt="some" style={{
                                        display: 'block',
                                        'margin-left': 'auto',
                                        'margin-right': 'auto',
                                        width: '50%',
                                        'max-height': '80vh'
                                    }} />
                                    <a class="link-a-simulation" href={this.state.source} target="_blank" download> Télécharger </a>
                                </div>
                            </div>
                        </div>
                    )

                }}

            </Popup>
        );

    }
}

export default S3FilePopup;