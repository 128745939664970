import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import EZEEApi from '../../../services/EZEEApi';
import ReactS3Uploader from 'react-s3-uploader';
import { toast } from 'react-toastify';
import Popup from "reactjs-popup";
import S3FilePopup from '../../popups/s3file'
import Moment from 'react-moment'
import DatePicker from 'react-datepicker';
import moment from 'moment';

class IncomeScreen extends Component {

    uploaders = {}

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            type: 'week',
            withdraws: []
        }

        this.time = new Date().getTime()

    }

    componentDidMount() {
        this.reload('week')
    }

    reload(type) {

        this.setState({
            type: type
        })

        EZEEApi.getDriverTransactions('me', type).then((data) => {

            this.setState({
                data: data
            })

        })

        EZEEApi.getDriverWithdraws('me', '').then((data) => {
            this.setState({
                withdraws: data.rows
            })
        })
    }

    generateWithdraw(id) {

        if (this.time < new Date().getTime()) {

            this.time = new Date().getTime() + 10000

            EZEEApi.generateWithdraw(id).then((res) => {
                return toast.success('Votre relevé vous a été envoyé par e-mail.', {
                    position: toast.POSITION.TOP_RIGHT
                });
            })

        }

    }

    render() {
        return (
            <div className="content-area-wrapper pt-5 pb-5">
                <div className="body-bg body-bg1 body-bg-pos"></div>
                <div className="body-bg body-bg2"></div>


                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-4 mb-5">

                            <div className="shadow-shape2 user-profile-sidebar pb-3 white-bg-section" style={{ 'min-height': '350px' }}>
                                <div className="s-back-link s-heading1">
                                    <h3>Historique</h3>
                                </div>

                                <div className="user-profile-links">
                                    <ul>
                                        <li className={` ${this.state.type === 'week' ? 'active-item' : ''}`} onClick={() => { this.reload('week') }} ><a>Semaine</a></li>
                                        <li className={` ${this.state.type === 'month' ? 'active-item' : ''}`} onClick={() => { this.reload('month') }}><a>Mois</a></li>
                                        <li className={` ${this.state.type === 'year' ? 'active-item' : ''}`} onClick={() => { this.reload('year') }}><a>Année</a></li>
                                    </ul>
                                </div>
                            </div>
                            <br></br>
                            <br></br>
                            <br></br>

                            <div className="shadow-shape2 user-profile-sidebar pb-3 white-bg-section" style={{ 'min-height': '350px' }}>
                                <div className="s-back-link s-heading1">
                                    <h3>Mes relevés</h3>
                                    <p>Sélectionnez un de vos relevés afin qu'il vous soit envoyé par e-mail.</p>
                                </div>

                                <div className="user-profile-links">
                                    <ul>
                                        {
                                            this.state.withdraws.map((withdraw) => {
                                                return (
                                                    <li onClick={() => { this.generateWithdraw(withdraw.id) }}><a>{moment(withdraw.date).format('MMMM - YYYY')} ({(withdraw.total_driver_fees_amount / 100) + " €"})</a></li>
                                                )
                                            })

                                        }

                                        {/* <li onClick={() => { this.reload('month') }}><a>Novembre 2019 (452 €)</a></li> */}

                                        {/* <li onClick={() => { this.reload('week') }} ><a>Octobre 2019 (356 €)</a></li> */}
                                    </ul>
                                </div>
                            </div>

                        </div>



                        <div className="col-md-8">
                            <div className="white-bg-section shadow-shape2 mb-2">
                                <div className="row">
                                    <div className="col-sm-12">

                                        {

                                            this.state.data.map((single) => {
                                                return (

                                                    <div class="white-bg-section no-border-radius mb-2">
                                                        <div class="row s-padding-sec2">
                                                            <div class="col-sm-12">
                                                                <p>{this.state.type === 'year' ? 'Année' : this.state.type === 'week' ? 'Semaine' : 'Mois'} du {moment(single.date_from).format('DD MMMM')} au {moment(single.date_to).format('DD MMMM YYYY')} </p>
                                                                <div class="box-bottom-text">
                                                                    <div class="box-bottom-right align-right">
                                                                        <p>{single.amount_trip}</p>
                                                                        <p><strong>{single.amount_charged / 100} &euro;</strong></p>
                                                                    </div>
                                                                    <p>Courses réalisées</p>
                                                                    <p>Total chiffre d’affaires</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })


                                        }

                                        {
                                            this.state.data.length === 0 && (
                                                <div className="TableComponentsEmpty" style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                                                    <img src="/images/Icon_search.png" alt="icon search" />
                                                    <span>Nous sommes désolés,</span>
                                                    <span>Aucun résultat n'a été trouvé !</span>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>)
    }
}

export default IncomeScreen;