import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Pagination from '../Pagination';
import TableComponent from '../TableComponent';
import Moment from 'react-moment';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment'

class TransactionsScreen extends TableComponent {
	constructor(props) {
		super(props);

		this.extendState({
			summary: {
				ca: 0,
				count: 0,
				count_refunded: 0,
				fees: 0
			}
		});

	}

	componentDidMount() {
		super.componentDidMount();
		this.reloadData(true);
		// EZEEApi.getTransactionsSummary().then((summary) => {
		// 	this.setState({ summary: summary });
		// });
	}

	reloadData(instant) {
		super.reloadData(() => {
			EZEEApi.getDeliveriesBookings(this.state.qs).then((bookings) => {
				console.log(bookings)
				this.setState({
					count: bookings.count,
					data: bookings.rows,
					called: true
				});
			});
		}, instant);
	}

	render() {
		if (!this.state.called) {
			return <div />;
		}

		return (
			<LoadingOverlay active={this.state.contentLoading} spinner text="Chargement des données...">
				<div className="content-area-wrapper pt-5 pb-5">
					<div className="body-bg body-bg1" />
					<div className="body-bg body-bg2" />
					<div className="body-bg body-bg3" />

					<div className="container">
						{/* <div>
							<div className="row mb-4 align-items-center">
								<div className="col-md-9">
									<h2>Statistiques globales</h2>
								</div>
							</div>

							<div className="row pt-3 pb-5 align-center">
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{this.state.summary.count}</h1>
										<p>Nombre total de <br />commandes</p>
									</div>
								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box m-auto white-bg-section shadow-shape2">
										<h1>{this.state.summary.count_refunded}</h1>
										<p>
											Nombre total de <br /> commandes remboursées
										</p>
									</div>
								</div>
								<div className="col-lg-6 col-sm-6 mb-3">

								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{(this.state.summary.ca / 100).toFixed(2)} &euro;</h1>
										<p>Chiffre d’affaires réalisé</p>
									</div>
								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{(this.state.summary.amount_ezee_fees / 100).toFixed(2)} &euro;</h1>
										<p>Com. EZEE</p>
									</div>
								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{(this.state.summary.amount_stripe_fees / 100).toFixed(2)} &euro;</h1>
										<p>Frais bancaires</p>
									</div>
								</div>
							</div>
						</div> */}


						<div className="row">
							<div className="col-lg-8">
								<h2>Réservations ({this.state.count})</h2>
							</div>
						</div>

						<div className="row ml-0 mr-0 s-filter-sec mt-4 mb-4 align-items-center">
							{this.generateFilter([20, 50, 100])}
							{this.generateDateFilter()}
						</div>

						{this.state.count !== 0 ? (
							<div>
								<div className="row s-table-content-wrapper">
									<div className="col-12">
										<div className="d-table full-width s-table-content s-table-middle-section">
											<div className="d-table-row table-header">
												{this.generateTableHeader({ name: 'REF', column: 'id' })}
												{this.generateTableHeader({
													name: (
														<font>
															NOMS<br />
															<small>Client / Chauffeur</small>
														</font>
													)
												})}
												{this.generateTableHeader({ name: 'DATE', column: 'created_at' })}
												{this.generateTableHeader({
													name: (
														<font>
															Prix
														</font>
													),
													column: 'amount_to_pay'
												})}
												{this.generateTableHeader({
													name: <font>ALLEE</font>,
													column: 'trip_date'
												})}
												{this.generateTableHeader({
													name: <font>RETOUR</font>,
													column: 'return_date'
												})}
												{/* {this.generateTableHeader({ name: 'COM. EZEE' })} */}
												{/* {this.generateTableHeader({ name: 'FRAIS BANCAIRES' })} */}
												{this.generateTableHeader({
													name: <font>STATUT</font>,
													column: 'status'
												})}
												{this.generateTableHeader({ name: <span>&nbsp;</span> })}
											</div>

											{this.state.data.map((booking, i) => {
												return (
													<div className="d-table-row table-data-row" key={booking.id} >
														<div className="d-table-cell" style={{
															// background: '#F08080' 
															// background: '#FFA500'
															background: (booking.status === 2 && '#F08080') || (booking.status === 3 && '#90EE90')
														}}>{booking.id}</div>
														<div className="d-table-cell s-user-info">
															<div className="d-block mb-1">
																<span className="t-client-icon">
																	{' '}
																	<S3Picture
																		type="user-profil"
																		file={booking.Client.picture}
																	/>
																</span>

																{
																	booking.Client.is_professional ?
																		` Pro - ${booking.Client.professional_name}` :
																		` ${booking.Client.firstname} ${booking.Client.lastname} ${booking.user_company_id}`
																}

															</div>
															<div className="d-block">
																<span className="t-client-icon">
																	{' '}
																	<S3Picture
																		type="user-profil"
																		file={(booking.Driver || { picture: 'default.jpg' }).picture}
																	/>{' '}
																</span>{' '}
																{(booking.Driver || { firstname: "Sans " }).firstname}{' '}
																{(booking.Driver || { lastname: "chauffeur" }).lastname}
															</div>
														</div>
														{/* <div className="d-table-cell">
															<Moment format="DD/MM/YYYY">
																{booking.created_at}
															</Moment>
														</div> */}

														<div className="d-table-cell s-user-info">
															<div className="d-block mb-1">


																{booking.address_from}

															</div>
															<div className="d-block">

																{booking.address_to}


															</div>
														</div>

														<div className="d-table-cell">
															{(booking.amount_to_pay / 100).toFixed(2)} &euro;
														</div>
														<div className="d-table-cell">
															{moment(booking.trip_date).format('Do MMMM  YYYY [à] HH:mm')}
														</div>
														<div className="d-table-cell">
															{booking.return_date ? moment(booking.return_date).format('Do MMMM  YYYY [à] HH:mm') : "Pas de retour"}
														</div>
														<div className="d-table-cell">

															{(booking.status === 2 && 'Payé sans chauffeur') || (booking.status === 3 && 'En attente de la course') || (booking.status === 4 && 'Terminée') || (booking.status === 9 && 'Annuler') || (booking.status === 99 && 'Rembourser')}

															{/* {`${booking.refunded === true
																? 'Remboursé'
																: 'Accepté ' + (booking.driver_withdraw_id ? '(Payé)' : '')} `}{' '} */}
															<br />
														</div>

														<div className="d-table-cell">

															{/* {booking.trip_id && ( */}

															<NavLink
																className="voir-button"
																to={`/deliveries/bookings/${booking.id}`}
															> Voir
																</NavLink>

															{/* )} */}



														</div>

													</div>
												);
											})}
										</div>
									</div>
								</div>

								<Pagination
									count={this.state.count}
									page={this.state.qs.page}
									limit={this.state.qs.limit}
									onPageChange={(page) => {
										this.onPageChange(page);
									}}
								/>
							</div>
						) : (
								<div className="TableComponentsEmpty">
									<img src="/images/Icon_search.png" alt="icon search" />
									<span>Nous sommes désolés,</span>
									<span>Aucun résultat n'a été trouvé !</span>
								</div>
							)}
					</div>
				</div>
			</LoadingOverlay>
		);
	}
}

export default TransactionsScreen;
