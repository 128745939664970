

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import LoginScreen from './components/screens/login';

import HeaderScreen from './components/screens/header';

import TripsScreen from './components/screens/trips';
// import TripScreen from './components/screens/trip';

import BookingsScreen from './components/screens/bookings';
import BookingScreen from './components/screens/booking';

import DeliveryBookingsScreen from './components/screens/delivery_bookings';
import DeliveryBookingScreen from './components/screens/delivery_booking';

import TransactionsScreen from './components/screens/transactions';
import TransactionScreen from './components/screens/transaction';

import UsersScreen from './components/screens/users';
import UserScreen from './components/screens/user';

import DriversScreen from './components/screens/drivers';
import DriverScreen from './components/screens/driver'

import DriverMeInformationsScreen from './components/screens/driver/informations'
import DriverMeHistoryScreen from './components/screens/driver/history'
import DriverMeIncomeScreen from './components/screens/driver/income'
import DriverMeBankaccountScreen from './components/screens/driver/bankaccount'


import VouchersScreen from './components/screens/vouchers'
import VoucherCreateScreen from './components/screens/voucher.create.js'
import VoucherScreen from './components/screens/voucher'

import WithdrawsScreen from './components/screens/withdraws'


// import ReportsScreen from './components/screens/reports';
// import ReportScreen from './components/screens/report';

// import ActivitiesScreen from './components/screens/activities';

// import IdentitiesScreen from './components/screens/identities';

import { AnimatedSwitch } from 'react-router-transition';

import EZEEApi from './services/EZEEApi';

import moment from 'moment'
import 'moment/locale/fr'

import env from './env'

function getCookie(cname) {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

function deleteCookie(name) {
	document.cookie = name + `=0; domain=${env.domainBaseUrl}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
}

class App extends Component {
	constructor(props) {
		super(props);

		setDefaultLocale('fr');
		registerLocale('fr', fr);

		moment.lang('fr')

		this.state = {
			called: false,
			me: null
		};

		if (getCookie('ezee_api_token').length > 1) {
			EZEEApi.setToken(getCookie('ezee_api_token'))
			deleteCookie('ezee_api_token')

			setTimeout(() => {
				document.location.reload()
			}, 2000)

		}

		EZEEApi.getMe()
			.then((me) => {
				EZEEApi.setMe(me);
				this.setState({ called: true, me: me });
			})
			.catch(() => {
				if (EZEEApi.isLogged()) {
					EZEEApi.logout()
					document.location.reload(true)
				} else {
					if (window.location.pathname !== '/login') {
						window.location.href = window.location.origin + "/login"
					}
				}
				this.setState({ called: true, me: null });
			});

	}

	mapStyles(styles) {
		return {
			opacity: styles.opacity
		};
	}

	render() {
		if (!this.state.called) {
			return <div />;
		}

		return (
			<Router>
				<div>
					<ToastContainer />

					<Route path={/^(?!.*login).*$/} currentuser={this.state.me} component={HeaderScreen} />

					<AnimatedSwitch
						atEnter={{ opacity: 0 }}
						atLeave={{ opacity: 0 }}
						atActive={{ opacity: 1 }}
						className="route-wrapper"
					>
						{/*  */}
						{/* <Route path={`/transactions/:transactionId`} component={TransactionScreen} />
						<Route path={`/trips/:tripId`} component={TripScreen} />
						<Route path={`/reports/:reportId`} component={ReportScreen} /> */}

						<Route path="/login" component={LoginScreen} />

						<Route path={/(driver-me-informations)$/} component={DriverMeInformationsScreen} />
						<Route path={/(driver-me-history)$/} component={DriverMeHistoryScreen} />
						<Route path={/(driver-me-income)$/} component={DriverMeIncomeScreen} />
						<Route path={/(driver-me-bank-account)$/} component={DriverMeBankaccountScreen} />


						<Route path={/(users)$/} component={UsersScreen} />
						<Route path={`/users/:userId`} component={UserScreen} />

						<Route path={/(drivers)$/} component={DriversScreen} />
						<Route path={`/drivers/:driverId`} component={DriverScreen} />

						<Route path={/(trips)$/} component={TripsScreen} />

						<Route path={'/bookings'} exact component={BookingsScreen} key={Date.now()} />
						<Route path={`/bookings/:bookingId`} component={BookingScreen} key={Date.now()} />

						<Route path={'/deliveries/bookings'} exact component={DeliveryBookingsScreen} />
						<Route path={`/deliveries/bookings/:bookingId`} component={DeliveryBookingScreen} />

						<Route path={/(transactions)$/} component={TransactionsScreen} />
						<Route path={`/transactions/:transactionId`} component={TransactionScreen} />

						<Route path={/(vouchers)$/} component={VouchersScreen} />
						<Route path={'/vouchers/create'} exact component={VoucherCreateScreen} />
						<Route path={`/vouchers/:voucherId`} component={VoucherScreen} />

						<Route path={/(withdraws)$/} component={WithdrawsScreen} />


						{/* <Route path={/(trips)$/} component={TripsScreen} />
						<Route path={/(identities)$/} component={IdentitiesScreen} />

						<Route path={/(activities)$/} component={ActivitiesScreen} />

						<Route path={/(reports)$/} component={ReportsScreen} /> */}
					</AnimatedSwitch>

					<Route
						exact
						path="/"
						render={() => {

							if (EZEEApi.isLogged() && this.state.me) {

								if (this.state.me.role > 1) {
									return <Redirect to="/users" />
								} else {
									return <Redirect to="/driver-me-informations" />
								}

							} else {
								return <Redirect to="/login" />
							}
						}}
					/>
				</div>
			</Router>
		);
	}
}

export default connect()(App);
