import React from 'react';
import { toast } from 'react-toastify';

import TextInputComponent from '../TextInputComponent';
import ComponentWithForm from '../ComponentWithForm';
import EZEEApi from '../../services/EZEEApi';

import Swal from 'sweetalert2'

import 'sweetalert2/dist/sweetalert2.min.css'

class LoginScreen extends ComponentWithForm {
	constructor(props) {
		super(props);

		this.state = {
			formValid: false,
			formControls: {
				email: {
					type: 'text',
					value: '',
					label: 'Identifiant',
					placeholder: 'Email',
					valid: true,
					validationRules: {
						isRequired: true,
						isEmail: true
					}
				},
				password: {
					type: 'password',
					value: '',
					label: 'Mot de passe',
					placeholder: 'Mot de passe',
					valid: true,
					validationRules: {
						isRequired: true
					}
				}
			}
		};

		if (EZEEApi.isLogged()) {
			this.props.history.push('/users');
		}
	}

	formSubmit = () => {
		EZEEApi.auth(this.getFormData())
			.then((sres) => {
				EZEEApi.token = sres.token;

				EZEEApi.getMe().then((res) => {
					EZEEApi.setToken(sres.token);
					EZEEApi.setMe(res);

					if (res.role === 1) {
						this.props.history.push('/driver-me-informations');
					} else {
						this.props.history.push('/users');
					}
				});
			})
			.catch(() => {
				toast.error('Vos identifiants sont invalides.', {
					position: toast.POSITION.TOP_RIGHT
				});
			});
	};

	render() {
		return (
			<div className="content-area-wrapper">
				<div className="body-bg conn-bg1" />
				<div className="body-bg conn-bg2" />
				<div className="body-bg body-bg1" />

				<div className="container">
					<div className="row full-scr-sec justify-content-center align-items-center">
						<div className="col-12 pt-5 pb-5">
							<div className="logo m-auto">
								<a>
									<img src="images/logo.svg" alt="logo" />
								</a>
							</div>
							<div className="connexion-wrapper">
								<div className="white-bg-section shadow-shape2 p-4 mt-5 mb-4">
									<form onSubmit={this.handleSubmit}>
										<TextInputComponent
											{...this.state.formControls.email}
											name="email"
											onChange={this.onChange}
										/>
										<TextInputComponent
											{...this.state.formControls.password}
											name="password"
											onChange={this.onChange}
										/>

										<div className="form-group">
											<input
												type="submit"
												className="theme-button1 full-width-btn"
												value="Se connecter"
											/>
										</div>
									</form>
								</div>
								<div className="col-12">
									<p><font style={{ cursor: 'pointer' }} href="#" onClick={() => {
										// swal("Une erreur s'est produite !", "", "error")
										Swal.fire({
											title: 'Cela arrive même aux meilleurs',
											html: 'Entrez votre adresse mail pour rénitialiser votre mot de passe et laissez-vous guider',
											input: 'text',
											inputAttributes: {
												autocapitalize: 'off'
											},
											showCancelButton: true,
											confirmButtonText: 'Envoyer',
											showLoaderOnConfirm: true,
											cancelButtonText: "Annuler",
											preConfirm: (email) => {

												EZEEApi.requestNewpassword(email).then((res) => { })
											},
											allowOutsideClick: () => !Swal.isLoading()
										}).then((result) => {
											if (result.isConfirmed) {
												Swal.fire('Success!', 'Un e-mail vous a été envoyé.', 'success')
											}
										})
									}}
									>Mot de passe oublié ?</font></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		document.body.classList.add('s-connexion-bg');
	}

	componentWillUnmount() {
		document.body.classList.remove('s-connexion-bg');
	}
}

export default LoginScreen;
