// export default {
// 	baseUrl: 'http://localhost:8889',
// 	s3BaseUrl: 'https://ezee-app-public-dev.s3.amazonaws.com/',
// 	domainBaseUrl: '.etherial.fr'
// };

// export default {
// 	baseUrl: 'http://localhost:8889',
// 	// baseUrl: 'https://ezee.etherial.fr',
// 	s3BaseUrl: 'https://ezee-app-public-dev.s3.amazonaws.com/',
// 	domainBaseUrl: '.etherial.fr'
// };

export default {
	baseUrl: 'https://api.ezeeapp.fr',
	s3BaseUrl: 'https://static-ezee-app.fra1.digitaloceanspaces.com/',
	domainBaseUrl: '.ezeeapp.fr'
}
