import React, { Component } from 'react';
import { BrowserRouter as Link, NavLink } from 'react-router-dom';
// import EZEEApi from '../../services/EZEEApi';
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';

import { toast } from 'react-toastify';

class HeaderScreen extends Component {
	currentPath = this.props.location.pathname;

	constructor(props) {
		super(props);

		this.unlisten = this.props.history.listen((location, action) => {
			this.currentPath = location.pathname;
		});

		this.state = {
			showMenu: false,
			menuopen: false
		};
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside, true);

		this.setState({
			user: EZEEApi.me
		});
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside, true);
	}

	handleClickOutside = (event) => {
		this.setState({
			menuopen: false,
			showMenu: false
		});
	};

	logout() {
		EZEEApi.logout().then(() => {
			this.props.history.push('/login');
		});
	}

	render() {
		return (
			<div className="header-wrapper">
				{/* <Link><Link to="/login" >Login</Link></Link> */}
				<div className="top-header">
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-md-3">
								<div className="logo">
									<img src="/images/logo.svg" alt="logo" />
								</div>
							</div>
							<div className="col-md-9 header-right-sec align-right">
								<div className="header-profile-wrap d-inline-block">
									<a
										className="clickable profile-menu-dropdown"
										onClick={() => this.setState({ menuopen: !this.state.menuopen })}
									>
										<S3Picture
											className="shadow-shape1"
											type="user-profil"
											file={EZEEApi.me.picture}
										/>{' '}
										<span>
											{EZEEApi.me.firstname} {EZEEApi.me.lastname}
										</span>
									</a>
									<div
										className="profile-quick-links h-sub-menu shadow-shape1"
										style={{ height: this.state.menuopen ? '65px' : '0px' }}
									>
										<ul>
											{/* <li>
												{' '}
												<NavLink to="/activities"> Activité</NavLink>
											</li> */}
											<li className="sign-out">
												<a className="clickable" onClick={() => this.logout()}>
													Déconnexion
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="clear" />
							</div>
						</div>
					</div>
				</div>

				<div className="navigations">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<nav className="navbar navbar-expand-lg navbar-light">
									<button
										className="navbar-toggler ml-auto"
										type="button"
										data-toggle="collapse"
										data-target="#collapsibleNavbar"
									>
										<span
											className="navbar-toggler-icon"
											onClick={() => {
												let element = document.querySelector('#collapsibleNavbar');

												if (element.classList.contains('show')) {
													element.classList.remove('show');
												} else {
													element.classList.add('show');
												}
											}}
										/>
									</button>

									{this.state.user &&
										this.state.user.role === 1 && (
											<>
												<div
													className="collapse navbar-collapse justify-content-center"
													id="collapsibleNavbar"
												>
													<ul className="navbar-nav">
														<li
															className={`menu-icon1 ${this.currentPath.includes(
																'/driver-me-informations'
															)
																? 'active-menu'
																: ''}`}
														>
															<NavLink to="/driver-me-informations">Mon compte</NavLink>
														</li>

														<li
															className={`menu-icon8 ${this.currentPath.includes(
																'/driver-me-history'
															)
																? 'active-menu'
																: ''}`}
														>
															<NavLink to="/driver-me-history">
																Historique des courses
															</NavLink>
														</li>

														<li
															className={`menu-icon4 ${this.currentPath.includes(
																'/driver-me-income'
															)
																? 'active-menu'
																: ''}`}
														>
															<NavLink to="/driver-me-income">
																Mon chiffre d'affaires
															</NavLink>
														</li>

														<li
															className={`menu-icon7 ${this.currentPath.includes(
																'/driver-me-bank-account'
															)
																? 'active-menu'
																: ''}`}
														>
															<NavLink to="/driver-me-bank-account">
																Coordonnées bancaires
															</NavLink>
														</li>
													</ul>

												</div>
												{/* <div> <br/>  <hr/> </div> */}
												{/* <div
												className="collapse navbar-collapse justify-content-center"
												id="collapsibleNavbar"
											>
												{this.state.user.is_driver === true ? (
													<ul className="navbar-nav">
														<li
															className={`menu-icon1 ${this.currentPath.includes(
																'/driver-me-informations'
															)
																? 'active-menu'
																: ''}`}
														>
															<NavLink to="/driver-me-informations">Mon compte chauffeur</NavLink>
														</li>

														<li
															className={`menu-icon8 ${this.currentPath.includes(
																'/driver-me-history'
															)
																? 'active-menu'
																: ''}`}
														>
															<NavLink to="/driver-me-history">
																Historique des courses
															</NavLink>
														</li>

														<li
															className={`menu-icon4 ${this.currentPath.includes(
																'/driver-me-income'
															)
																? 'active-menu'
																: ''}`}
														>
															<NavLink to="/driver-me-income">
																Mon chiffre d'affaires
															</NavLink>
														</li>

														<li
															className={`menu-icon7 ${this.currentPath.includes(
																'/driver-me-bank-account'
															)
																? 'active-menu'
																: ''}`}
														>
															<NavLink to="/driver-me-bank-account">
																Coordonnées bancaires
															</NavLink>
														</li>
													</ul>
												) : (
													<ul className="navbar-nav">
														<li
															className={`menu-icon1 ${this.currentPath.includes(
																'/driver-me-informations'
															)
																? 'active-menu'
																: ''}`}
														>
															<NavLink to="/driver-me-informations">Mon compte</NavLink>
														</li>
													</ul>
												)}
											</div> */}

												{/* <div> <br/>  <hr/> </div> */}
											</>
										)}

									{this.state.user &&
										(this.state.user.role === 2 || this.state.user.role === 3) && (
											<div
												className="collapse navbar-collapse justify-content-center"
												id="collapsibleNavbar"
											>
												<ul className="navbar-nav">
													<li
														className={`menu-icon2 ${this.currentPath.includes('/users')
															? 'active-menu'
															: ''}`}
													>
														<NavLink to="/users">Clients</NavLink>
													</li>

													<li
														className={`menu-icon3 ${this.currentPath.includes('/drivers')
															? 'active-menu'
															: ''}`}
													>
														<NavLink to="/drivers">Chauffeurs</NavLink>
													</li>




													<li
														className={`menu-icon5 ${this.currentPath.includes('/transactions')
															? 'active-menu'
															: ''}`}
													>
														<NavLink to="/transactions">Commandes</NavLink>
													</li>

													<li
														className={`menu-icon4 ${this.currentPath.includes('/trips')
															? 'active-menu'
															: ''}`}
													>
														<NavLink to="/trips">Trajets</NavLink>
													</li>

													<li
														className={`menu-icon5 ${this.currentPath.includes('/bookings')
															? 'active-menu'
															: ''}`}
													>
														<NavLink to="/bookings">Réservations</NavLink>
													</li>

													{/* <li
														className={`menu-icon5 ${this.currentPath.includes('/deliveries/bookings')
															? 'active-menu'
															: ''}`}
													>
														<NavLink to="/deliveries/bookings">Réservations Livraisons</NavLink>
													</li> */}

													<li
														className={`menu-icon6 ${this.currentPath.includes('/vouchers')
															? 'active-menu'
															: ''}`}
													>
														<NavLink to="/vouchers">Codes promo</NavLink>
													</li>

													<li
														className={`menu-icon7 ${this.currentPath.includes('/withdraws') &&
															this.currentPath[1] === 'w'
															? 'active-menu'
															: ''}`}
													>
														{console.log(this.currentPath)}
														<NavLink to="/withdraws">Virements</NavLink>
													</li>
												</ul>
											</div>
										)}
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HeaderScreen;
