import env from '../env.js'

class EZEEApi {
	static myInstance = null;

	static getInstance() {
		if (EZEEApi.myInstance == null) {
			EZEEApi.myInstance = new EZEEApi();
			EZEEApi.myInstance.token = localStorage.getItem('token');
		}

		return this.myInstance;
	}

	userID = '';
	baseUrl = env.baseUrl;
	me = {};

	objToQueryString = (obj) => {
		const keyValuePairs = [];
		for (const key in obj) {
			keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
		}
		return keyValuePairs.join('&');
	}

	isLogged = () => {
		return this.token != null;
	};

	setMe = (me) => {
		this.me = me;
	};

	setToken = (token) => {
		localStorage.setItem('token', token);
		this.token = token;
	};

	auth = ({ email, password }) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/auth', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					email: email,
					password: password
				})
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	logout = () => {
		return new Promise((resolve) => {
			localStorage.removeItem('token');
			this.token = null;
			this.me = {};
			resolve();
		});
	};

	getMe = () => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/users/me', {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject();
					}
				});
			});
		});
	};

	requestNewpassword = (email) => {
		return new Promise((fullfil, reject) => {
			fetch(`${this.baseUrl}/users/password/reset?email=${email}`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then((response) => {
					fullfil(response.json());
				})
				.catch(function (error) {
					reject(error);
				});

		});
	}

	// getReports = (qs) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/reports` + this.qsGenerator(qs || {}), {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		})
	// 			.then((response) => {
	// 				response.json().then((res) => {
	// 					if (res.status === 200) {
	// 						resolve(res.data);
	// 					} else {
	// 						reject(res.errors);
	// 					}
	// 				});
	// 			})
	// 			.catch((err) => {
	// 				console.log(err);
	// 			});
	// 	});
	// };

	// getReport = (id) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/reports/${id}`, {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		})
	// 			.then((response) => {
	// 				response.json().then((res) => {
	// 					if (res.status === 200) {
	// 						resolve(res.data);
	// 					} else {
	// 						reject(res.errors);
	// 					}
	// 				});
	// 			})
	// 			.catch((err) => {
	// 				console.log(err);
	// 			});
	// 	});
	// };

	// putReport = (id, { status, message }) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/reports/${id}`, {
	// 			method: 'PUT',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			},
	// 			body: JSON.stringify({
	// 				status: status,
	// 				message_by_admin: message
	// 			})
	// 		})
	// 			.then((response) => {
	// 				response.json().then((res) => {
	// 					if (res.status === 200) {
	// 						resolve(res.data);
	// 					} else {
	// 						reject(res.errors);
	// 					}
	// 				});
	// 			})
	// 			.catch((err) => {
	// 				console.log(err);
	// 			});
	// 	});
	// };

	banUser = (id, reason) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/users/${id}/ban`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify({
					reason: reason
				})
			})
				.then((response) => {
					response.json().then((res) => {
						if (res.status === 201) {
							resolve(res.data);
						} else {
							reject(res.errors);
						}
					});
				})
				.catch((err) => {
					console.log(err);
				});
		});
	};

	unbanUser = (id) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/users/${id}/ban`, {
				method: 'DELETE',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			})
				.then((response) => {
					resolve();
				})
				.catch((err) => {
					console.log(err);
				});
		});
	};

	getActivities = (qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/activities` + this.qsGenerator(qs || {}), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			})
				.then((response) => {
					response.json().then((res) => {
						if (res.status === 200) {
							resolve(res.data);
						} else {
							reject(res.errors);
						}
					});
				})
				.catch((err) => {
					console.log(err);
				});
		});
	};

	getUsers = (qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/users' + this.qsGenerator(qs), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getUser = (id) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/users/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getDrivers = (qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/drivers' + this.qsGenerator(qs), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	postUserPasswordReset = (email) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/users/password/reset?email=${email}`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 201) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	// getUserIdentity = (id) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/users/${id}/identity`, {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		}).then((response) => {
	// 			response.json().then((res) => {
	// 				if (res.status === 200) {
	// 					resolve(res.data);
	// 				} else {
	// 					reject(res.errors);
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	// getUserComments = (id) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/users/${id}/comments`, {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		}).then((response) => {
	// 			response.json().then((res) => {
	// 				if (res.status === 200) {
	// 					resolve(res.data);
	// 				} else {
	// 					reject(res.errors);
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	// postUserComments = (id, message) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/users/${id}/comments`, {
	// 			method: 'POST',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			},
	// 			body: JSON.stringify({ message: message })
	// 		}).then((response) => {
	// 			response.json().then((res) => {
	// 				if (String(response.status).startsWith(2)) {
	// 					resolve(res.data);
	// 				} else {
	// 					reject(res.errors);
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	// deleteUserComments = (id, comment_id) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/users/${id}/comments/${comment_id}`, {
	// 			method: 'DELETE',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		}).then((response) => {
	// 			response
	// 				.json()
	// 				.then((res) => {
	// 					if (String(response.status).startsWith(2)) {
	// 						resolve(res.data);
	// 					} else {
	// 						reject(res.errors);
	// 					}
	// 				})
	// 				.catch(() => {
	// 					resolve({});
	// 				});
	// 		});
	// 	});
	// };



	getUserSponsorship = (id, qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/users/${id}/sponsorship${this.qsGenerator(qs)}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getUserTripsAsPassenger = (id) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/users/${id}/trips?as=passenger`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getUserTripsAsDriver = (id, qs) => {

		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/users/${id}/trips?as=driver${qs ? qs : ''}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	// getUserActivities = (id, qs) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/users/${id}/activities` + this.qsGenerator(qs || {}), {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		})
	// 			.then((response) => {
	// 				response.json().then((res) => {
	// 					if (res.status === 200) {
	// 						resolve(res.data);
	// 					} else {
	// 						reject(res.errors);
	// 					}
	// 				});
	// 			})
	// 			.catch((err) => {
	// 				console.log(err);
	// 			});
	// 	});
	// };

	// getUserWalletWithdraws = (id, qs) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/users/${id}/wallet/withdraws` + this.qsGenerator(qs), {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		}).then((response) => {
	// 			response.json().then((res) => {
	// 				if (res.status === 200) {
	// 					resolve(res.data);
	// 				} else {
	// 					reject(res.errors);
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	getUserTripsRates = (id, qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/users/${id}/trips/rates` + this.qsGenerator(qs), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	// getTrips = (qs) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + '/trips' + this.qsGenerator(qs), {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		}).then((response) => {
	// 			response.json().then((res) => {
	// 				if (res.status === 200) {
	// 					resolve(res.data);
	// 				} else {
	// 					reject(res.errors);
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	// getTripsSummary = (qs) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + '/trips/summary', {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		}).then((response) => {
	// 			response.json().then((res) => {
	// 				if (res.status === 200) {
	// 					resolve(res.data);
	// 				} else {
	// 					reject(res.errors);
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	// getTrip = (id) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/trips/${id}`, {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		}).then((response) => {
	// 			response.json().then((res) => {
	// 				if (res.status === 200) {
	// 					resolve(res.data);
	// 				} else {
	// 					reject(res.errors);
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	// deleteTrip = (id) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/trips/${id}`, {
	// 			method: 'DELETE',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		}).then((response) => {
	// 			response.json().then((res) => {
	// 				if (String(response.status).startsWith(2)) {
	// 					resolve(res.data);
	// 				} else {
	// 					reject(res.errors);
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	// getValidationsIdentifications = (qs) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + '/validations/identifications' + this.qsGenerator(qs), {
	// 			method: 'GET',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			}
	// 		}).then((response) => {
	// 			response.json().then((res) => {
	// 				if (res.status === 200) {
	// 					resolve(res.data);
	// 				} else {
	// 					reject(res.errors);
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	// putValidationsIdentifications = (id, data) => {
	// 	return new Promise((resolve, reject) => {
	// 		fetch(this.baseUrl + `/validations/identifications/${id}`, {
	// 			method: 'PUT',
	// 			headers: {
	// 				Accept: 'application/json',
	// 				'Content-Type': 'application/json',
	// 				Authorization: 'Bearer ' + this.token
	// 			},
	// 			body: JSON.stringify(data)
	// 		}).then((response) => {
	// 			response.json().then((res) => {
	// 				if (res.status === 200) {
	// 					resolve(res.data);
	// 				} else {
	// 					reject(res.errors);
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	updateMyAccount = (data) => {
		return new Promise((fullfil, reject) => {
			fetch(this.baseUrl + '/users/me', {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(data)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						fullfil(res.data);
					} else {
						reject(res.errors);
					}
				});
			});

		});
	}

	getVouchers = (qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/vouchers' + this.qsGenerator(qs), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};


	getVoucher = (id) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/vouchers/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	postVoucher = (data) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/vouchers`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(data)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 201) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getTrips = (qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/trips' + this.qsGenerator(qs), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};



	getDeliveriesBookings = (qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/deliveries/bookings' + this.qsGenerator(qs), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getDeliveryBooking = (id) => {
		return new Promise((resolve, reject) => {
			fetch(`${this.baseUrl}/deliveries/bookings/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getBookings = (qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/bookings' + this.qsGenerator(qs), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getBooking = (id) => {
		return new Promise((resolve, reject) => {
			fetch(`${this.baseUrl}/bookings/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	putBooking = (id, body) => {

		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/bookings/${id}`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(body)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	deleteBooking = (id, body) => {

		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/bookings/${id}`, {
				method: 'DELETE',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getTransactions = (qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/transactions' + this.qsGenerator(qs), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getTransactionsSummary = () => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/transactions/summary', {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getWithdraws = (qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/drivers/withdraws' + this.qsGenerator(qs), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getWidthdrawsSummary = () => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + '/drivers/withdraws/summary', {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};


	getTransaction = (id) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/transactions/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	putTransaction = (id, reason_refunded) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/transactions/${id}`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify({ reason_refunded: reason_refunded })
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getDriver = (id) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	postDriver = (id, data) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(data)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	updateDriverStatus = (id, data) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}/status`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(data)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	deleteDriverDocument = (id, data) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}/documents`, {
				method: 'DELETE',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(data)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	generateWithdraw = (id) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/me/withdraws/${id}`, {
				method: 'PATCH',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getDriverWithdraws = (id, qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}/withdraws${this.qsGenerator(qs)}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	putDriverWithdraw = (id, params) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/withdraws/${id}`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(params)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getDriverTransactions = (id, type) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}/transactions?date_type=${type}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getDriverBankAccount = (id) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}/bankaccount`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	postDriverBankaccount = (id, params) => {

		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}/bankaccount`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(params)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 201 || res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	postDriverDocument = (id, params) => {


		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}/documents`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(params)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 201 || res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	putDriverDocument = (id, params) => {

		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}/documents`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(params)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 201 || res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});

	};

	putVoucher = (id, params) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/vouchers/${id}`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				},
				body: JSON.stringify(params)
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	qsGenerator = ({ filter, limit, sort, page }) => {
		var qs = '?';

		if (filter && Object.keys(filter).length !== 0) {
			qs += `filter=`;
			for (const k in filter) {
				qs += `${k} ${filter[k]},`;
			}
			qs.slice(0, -1);
			qs += '&';
		}

		if (limit) {
			qs += 'limit=' + limit + '&';
		}

		if (sort) {
			qs += 'sort=' + sort + '&';
		}

		if (page) {
			qs += 'offset=' + (page * limit - limit) + '&';
		}

		return qs.substring(0, qs.length - 1);
	};

	getSignedUrlForPost = (params) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/s3/sign?${this.objToQueryString(params)}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getDriverTripsRates = (id, qs) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/drivers/${id}/trips/rates` + this.qsGenerator(qs), {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};

	getSignedUrlForGet = (params) => {
		return new Promise((resolve, reject) => {
			fetch(this.baseUrl + `/s3/file?${this.objToQueryString(params)}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + this.token
				}
			}).then((response) => {
				response.json().then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					} else {
						reject(res.errors);
					}
				});
			});
		});
	};



	getUserID() {
		return this.userID;
	}

	setUserID(id) {
		this.userID = id;
	}
}

export default EZEEApi.getInstance();
