import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, NavLink, Switch } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import UserSummaryScreen from './user_summary';
import UserProfilScreen from './user_profil';
import UserTripsScreen from './user_trips';
import UserSponsorshipScreen from './user_sponsorship';
import UserTripsRatesScreen from './user_triprates';

import UserBanPopup from '../popups/user_ban';
import UserUnbanPopup from '../popups/user_unban';

import { AnimatedSwitch } from 'react-router-transition';

class UserScreen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null
		};
	}

	reloadUser() {
		EZEEApi.getUser(this.props.match.params.userId).then((user) => {
			this.setState({ user: user });
		});
	}

	componentDidMount() {
		this.reloadUser();
	}

	render() {
		if (this.state.user == null) {
			return <div />;
		} else {
			return (
				<div>
					{this.state.user.UserBan && (
						<div className="s-message-strip2">
							<div className="container">
								<div className="row">
									<div className="col-12 align-center">
										<p>
											Cet utilisateur a été banni de l’application
											<UserUnbanPopup
												user={this.state.user}
												onClose={() => {
													this.reloadUser();
												}}
											>
												<a className="theme-button1 ml-5  theme-button-white popup-info_open">
													Voir la raison
												</a>
											</UserUnbanPopup>
										</p>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="content-area-wrapper pt-5 pb-5">
						<div className="body-bg body-bg1 body-bg-pos" />
						<div className="body-bg body-bg2" />

						<div className="container">
							<div className="row mb-5">
								<div className="col-md-4 mb-5">
									<div className="shadow-shape2 user-profile-sidebar white-bg-section">
										<div className="s-back-link">
											<NavLink to={`/users`}>Retour aux clients</NavLink>
										</div>
										<div className="user-profile-info table-quick-links">
											<div className="user-profile-pic d-inline-block">
												<S3Picture type="user-profil" file={this.state.user.picture} />
											</div>
											<div className="user-name-info d-inline-block">
												<h5>
													{this.state.user.is_professional ? ` ${this.state.user.professional_name} (Pro)` : ` ${this.state.user.firstname} ${this.state.user.lastname}`}
												</h5>

											</div>
										</div>
										<div className="user-profile-links">
											<ul>
												<li
													className={`${this.props.location.pathname.includes('profil')
														? 'active-item'
														: null}`}
												>
													<NavLink to={`${this.props.match.url}/profil`}>Informations sur le profil</NavLink>
												</li>

												<li
													className={`${this.props.location.pathname.includes('trips')
														? 'active-item'
														: null}`}
												>
													<NavLink
														to={`${this.props.match.url}/trips`}
													>
														Courses
													</NavLink>
												</li>

												<li
													className={`${this.props.location.pathname.includes('rates')
														? 'active-item'
														: null}`}
												>
													<NavLink to={`${this.props.match.url}/rates`}>
														Notes et avis des chauffeurs
													</NavLink>
												</li>

												<li
													className={`${this.props.location.pathname.includes('sponsorship')
														? 'active-item'
														: null}`}
												>
													<NavLink to={`${this.props.match.url}/sponsorship`}>Parrainage</NavLink>
												</li>


											</ul>
										</div>
										{!this.state.user.UserBan && (
											<div className="align-center s-more-link">
												<UserBanPopup
													user={this.state.user}
													onClose={() => {
														this.reloadUser();
													}}
												>
													<a className="clickable">Bannir ce client</a>
												</UserBanPopup>
											</div>
										)}
									</div>
								</div>

								<Switch>
									<Route
										path={`${this.props.match.url}$`}
										render={(props) => <UserProfilScreen user={this.state.user} {...props} />}
									/>

									<Route
										path={`${this.props.match.url}/profil`}
										render={(props) => <UserProfilScreen user={this.state.user} {...props} />}
									/>

									<Route
										path={`${this.props.match.url}/trips`}
										render={(props) => <UserTripsScreen user={this.state.user} as={'passenger'} {...props} />}
									/>

									<Route
										path={`${this.props.match.url}/sponsorship`}
										render={(props) => <UserSponsorshipScreen user={this.state.user} {...props} />}
									/>

									<Route
										path={`${this.props.match.url}/rates`}
										render={(props) => <UserTripsRatesScreen user={this.state.user} as={'passenger'} {...props} />}
									/>
								</Switch>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default UserScreen;
