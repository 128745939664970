import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Pagination from '../Pagination';
import TableComponent from '../TableComponent';
import Moment from 'react-moment';
import LoadingOverlay from 'react-loading-overlay';
class TransactionsScreen extends TableComponent {
	constructor(props) {
		super(props);

		this.extendState({
			summary: {
				ca: 0,
				count: 0,
				count_refunded: 0,
				fees: 0
			}
		});

	}

	componentDidMount() {
		super.componentDidMount();
		this.reloadData(true);
		EZEEApi.getTransactionsSummary().then((summary) => {
			this.setState({ summary: summary });
		});
	}

	reloadData(instant) {
		super.reloadData(() => {
			EZEEApi.getTransactions(this.state.qs).then((transactions) => {
				this.setState({
					count: transactions.count,
					data: transactions.rows,
					called: true
				});
			});
		}, instant);
	}

	render() {
		if (!this.state.called) {
			return <div />;
		}

		return (
			<LoadingOverlay active={this.state.contentLoading} spinner text="Chargement des données...">
				<div className="content-area-wrapper pt-5 pb-5">
					<div className="body-bg body-bg1" />
					<div className="body-bg body-bg2" />
					<div className="body-bg body-bg3" />

					<div className="container">
						<div>
							<div className="row mb-4 align-items-center">
								<div className="col-md-9">
									<h2>Statistiques globales</h2>
								</div>
							</div>

							<div className="row pt-3 pb-5 align-center">
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{this.state.summary.count}</h1>
										<p>Nombre total de <br />commandes</p>
									</div>
								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box m-auto white-bg-section shadow-shape2">
										<h1>{this.state.summary.count_refunded}</h1>
										<p>
											Nombre total de <br /> commandes remboursées
										</p>
									</div>
								</div>
								<div className="col-lg-6 col-sm-6 mb-3">

								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{(this.state.summary.ca / 100).toFixed(2)} &euro;</h1>
										<p>Chiffre d’affaires réalisé</p>
									</div>
								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{(this.state.summary.amount_ezee_fees / 100).toFixed(2)} &euro;</h1>
										<p>Com. EZEE</p>
									</div>
								</div>
								<div className="col-lg-3 col-sm-6 mb-3">
									<div className="s-stat-box white-bg-section shadow-shape2">
										<h1>{(this.state.summary.amount_stripe_fees / 100).toFixed(2)} &euro;</h1>
										<p>Frais bancaires</p>
									</div>
								</div>
							</div>
						</div>


						<div className="row">
							<div className="col-lg-8">
								<h2>Transactions ({this.state.count})</h2>
							</div>
						</div>

						<div className="row ml-0 mr-0 s-filter-sec mt-4 mb-4 align-items-center">
							{this.generateFilter([20, 50, 100])}
							{this.generateDateFilter()}
						</div>

						{this.state.count !== 0 ? (
							<div>
								<div className="row s-table-content-wrapper">
									<div className="col-12">
										<div className="d-table full-width s-table-content s-table-middle-section">
											<div className="d-table-row table-header">
												{this.generateTableHeader({ name: 'REF', column: 'id' })}
												{this.generateTableHeader({
													name: (
														<font>
															NOMS<br />
															<small>Client / Chauffeur</small>
														</font>
													)
												})}
												{this.generateTableHeader({ name: 'DATE', column: 'created_at' })}
												{this.generateTableHeader({
													name: (
														<font>
															Prix
														</font>
													),
													column: 'amount_paid'
												})}
												{this.generateTableHeader({ name: 'COM. EZEE', column: 'ezee_fees_amount_total' })}
												{this.generateTableHeader({ name: 'FRAIS BANCAIRES', column: 'stripe_fees_amount_total' })}
												{this.generateTableHeader({ name: 'TYPE' })}


												{this.generateTableHeader({
													name: <font>STATUT</font>,
													column: 'refunded'
												})}
												{this.generateTableHeader({ name: <span>&nbsp;</span> })}
											</div>

											{this.state.data.map((transaction, i) => {
												return (
													<div className="d-table-row table-data-row" key={transaction.id}>
														<div className="d-table-cell">{transaction.id}</div>
														<div className="d-table-cell s-user-info">
															<div className="d-block mb-1">
																<span className="t-client-icon">
																	{' '}
																	<S3Picture
																		type="user-profil"
																		file={transaction.DebitedUser.picture}
																	/>
																</span>

																{
																	transaction.DebitedUser.is_professional ?
																		` Pro - ${transaction.DebitedUser.professional_name} pour ${(transaction.Trip || transaction.Booking).UserProfessionalCollaborator.firstname} ${(transaction.Trip || transaction.Booking).UserProfessionalCollaborator.lastname}` :
																		` ${transaction.DebitedUser.firstname} ${transaction.DebitedUser.lastname} ${(transaction.Trip || transaction.Booking).user_company_id ? `(${(transaction.Trip || transaction.Booking).UserCompany.name}) ` : ''}`
																}


															</div>
															<div className="d-block">
																<span className="t-client-icon">
																	{' '}
																	<S3Picture
																		type="user-profil"
																		file={(transaction.CreditedUser).picture}
																	/>{' '}
																</span>{' '}
																{(transaction.CreditedUser).firstname}{' '}
																{(transaction.CreditedUser).lastname}
															</div>
														</div>
														<div className="d-table-cell">
															<Moment format="DD/MM/YYYY">
																{transaction.created_at}
															</Moment>
														</div>
														<div className="d-table-cell">
															{(transaction.amount_paid / 100).toFixed(2)} &euro;
														</div>
														<div className="d-table-cell">
															{(transaction.ezee_fees_amount_total / 100).toFixed(2)} &euro;
														</div>
														<div className="d-table-cell">
															{(transaction.stripe_fees_amount_total / 100).toFixed(2)} &euro;
														</div>
														<div className="d-table-cell">
															{(transaction.Booking && 'Booking') || (transaction.Trip && 'Live')}
														</div>
														<div className="d-table-cell">
															{`${transaction.refunded === true
																? 'Remboursé'
																: 'Accepté ' + (transaction.driver_withdraw_id ? '(Payé)' : '')} `}{' '}
															<br />
														</div>

														<div className="d-table-cell">

															{/* {transaction.trip_id && ( */}

															<NavLink
																className="voir-button"
																to={`/transactions/${transaction.id}`}
															> Voir
																</NavLink>

															{/* )} */}



														</div>

													</div>
												);
											})}
										</div>
									</div>
								</div>

								<Pagination
									count={this.state.count}
									page={this.state.qs.page}
									limit={this.state.qs.limit}
									onPageChange={(page) => {
										this.onPageChange(page);
									}}
								/>
							</div>
						) : (
								<div className="TableComponentsEmpty">
									<img src="/images/Icon_search.png" alt="icon search" />
									<span>Nous sommes désolés,</span>
									<span>Aucun résultat n'a été trouvé !</span>
								</div>
							)}
					</div>
				</div>
			</LoadingOverlay>
		);
	}
}

export default TransactionsScreen;
