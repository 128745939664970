import React, { Component } from 'react';
import Popup from "reactjs-popup";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify'


class VoucherEditPopup extends Component {

    constructor(props) {
        super(props)

        this.sclose = null

        this.state = {
            status: props.voucher.status,
            description: props.voucher.description,

            begin_at: new Date(props.voucher.begin_at),
            end_at: new Date(props.voucher.end_at)
        }
    }

    change() {

        EZEEApi.putVoucher(this.props.voucher.id, {
            status: this.state.status,
            description: this.state.description,

            begin_at: this.state.begin_at,
            end_at: this.state.end_at
        }).then(() => {
            this.props.onClose()
            this.sclose()
        })

    }

    formIsValid() {

        return (
            this.state.status !== '' &&
            this.state.description !== '' &&
            this.state.begin_at !== '' &&
            this.state.end_at !== ''
        )
    }

    onDateFromChange(date) {
        if (date > this.state.voucher.date_to) {
            return toast.error('La date de départ ne peut pas être supérieur à la date de fin.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        this.state.voucher.date_from = date;

        this.setState({
            qs: this.state.qs
        });
    }

    onDateToChange(date) {
        if (date < this.state.voucher.date_from) {
            return toast.error('La date de fin ne peut pas être antérieur à la date de début.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        this.state.voucher.date_to = date;

        this.setState({
            qs: this.state.qs
        });
    }

    render() {

        return (
            <Popup modal={true} lockScroll={true} trigger={<button style={{ border: 0 }} className={this.props.className}> {this.props.children} </button>} modal>

                {(close) => {
                    this.sclose = close
                    return (

                        <div id="popup-info" class="popup-box align-left" style={{ margin: 0, 'max-width': '950px' }}>
                            <div class="close-popup popup-info_close"><img src="images/close-icon.svg" alt="Close" onClick={() => { close() }} /></div>
                            <div class="popup-box-inner align-left" style={{ 'padding': 0 }}>
                                <div class="heading-bottom-border pb-4 mb-4">
                                    <h3 class="black">Modifier un code promo</h3>
                                </div>
                                <div class="popup-form-wrapper">
                                    <form class="pt-3" action="#" method="post">
                                        <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                            <div className="col-12 p-0">
                                                <div className="detail-table-cell detail-title-cell pt-1"><h6>Nom<br />du code</h6></div>
                                                <div className="detail-table-cell">{this.props.voucher.code}</div>
                                            </div>
                                        </div>
                                        <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                            <div className="col-12 p-0">
                                                <div className="detail-table-cell detail-title-cell"><h6>Raison</h6></div>
                                                <div className="detail-table-cell"> <input type="text" className="p-textbox" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} /> </div>
                                            </div>
                                        </div>
                                        <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                            <div className="col-12 p-0">
                                                <div className="detail-table-cell detail-title-cell"><h6>Statut</h6></div>
                                                <div className="detail-table-cell">
                                                    <input type="radio" className="s-ezee-radio" name="p-status" id="actif" checked={this.state.status === true} /><label htmlFor="actif" onClick={() => { this.setState({ status: true }) }}>Actif</label>
                                                    <input type="radio" className="s-ezee-radio" name="p-status" id="non-actif" checked={this.state.status === false} /><label htmlFor="non-actif" onClick={() => { this.setState({ status: false }) }}>Non actif</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                            <div className="col-12 p-0">
                                                <div className="detail-table-cell detail-title-cell"><h6>Validité</h6></div>
                                                <div className="detail-table-cell">Du
                                                <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        className="p-textbox datepicker"
                                                        selected={this.state.begin_at}
                                                        onChange={this.onDateFromChange.bind(this)}
                                                        locale="fr"
                                                    /> Au <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        className="p-textbox datepicker"
                                                        selected={this.state.end_at}
                                                        onChange={this.onDateToChange.bind(this)}
                                                        locale="fr"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                            <div className="col-12 p-0">
                                                <div className="detail-table-cell detail-title-cell"><h6>Montant</h6></div>
                                                <div className="detail-table-cell">{this.props.voucher.amount / 100} €</div>
                                            </div>
                                        </div>

                                        <div class="row align-items-center mt-3">
                                            <div class="col-md-12 col-sm-12 align-right">
                                                <input type="button" class="p-submit-btn theme-button1" value="Modifier" onClick={() => this.change()} disabled={!this.formIsValid()} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                    )
                }}

            </Popup>
        );

    }
}

export default VoucherEditPopup;