import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, NavLink } from 'react-router-dom';
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';
import TableComponent from '../TableComponent';
import Pagination from '../Pagination';

class UserTripRatesScreen extends TableComponent {
	componentDidMount() {
		this.reloadData(true);
	}

	reloadData(instant) {

		if (this.props.as === 'driver') {

			super.reloadData(() => {
				EZEEApi.getDriverTripsRates(this.props.driver.id, this.state.qs).then((rates) => {
					this.setState({
						count: rates.count,
						data: rates.rows
					});
				});
			}, instant);

		} else if (this.props.as === 'passenger') {
			super.reloadData(() => {
				EZEEApi.getUserTripsRates(this.props.user.id, this.state.qs).then((rates) => {
					this.setState({
						count: rates.count,
						data: rates.rows
					});
				});
			}, instant);
		}

	}

	render() {
		return (
			<div className="col-md-8">
				<div className="shadow-shape2 white-bg-section mb-4">
					<div className="s-padding-sec">
						<h3>Notes et avis</h3>
						<div className="user-profile-header pt-4">
							<div className="user-profile-pic3 d-inline-block">
								<S3Picture type="user-profil" file={this.props.user.picture} />
							</div>
							<div className="username-rate d-inline-block">
								<h4>
									{this.props.user.firstname} {this.props.user.lastname}
								</h4>
								<h3 className="green-star-rate">
									<img src="/images/star-green.svg" alt="" />
									{this.props.as === 'driver' ? <span>{(this.props.user.driver_rate / 100 * 100).toFixed(2)}</span> : <span>{(this.props.user.passenger_rate / 100 * 100).toFixed(2)}</span>}
								</h3>
							</div>
						</div>
					</div>
				</div>

				{this.state.data.map((rate) => {
					return (
						<div className="shadow-shape2 white-bg-section mb-4">
							<div className="review-block">
								<div className="review-client-icon">
									<S3Picture type="user-profil" file={rate.Passenger.picture} />
								</div>
								<div className="review-header">
									<div className="review-actions">
										<NavLink to={`/trips/${rate.trip_id}`}>
											<img src="/images/eye-icon.svg" alt="Watch" />
										</NavLink>
									</div>
									<h6>
										{rate.Passenger.firstname} {rate.Passenger.lastname}
									</h6>
									<div className="star-rating">
										{Array.apply(0, Array(Math.floor(rate.rate))).map((x, i) => {
											return <span className="sm-star y-star" />;
										})}

										<small>{rate.rate / 10 * 10} sur 5</small>
									</div>
									{/* <small>Le 31/08/18</small> */}
								</div>
								<p>{rate.comment}</p>
							</div>
						</div>
					);
				})}

				<Pagination
					count={this.state.count}
					page={this.state.qs.page}
					limit={this.state.qs.limit}
					onPageChange={(page) => {
						this.onPageChange(page);
					}}
				/>
			</div>
		);
	}
}

export default UserTripRatesScreen;
