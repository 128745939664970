import React, { Component } from 'react';
import Popup from "reactjs-popup";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';

import { toast } from 'react-toastify';

class WithdrawEditPopup extends Component {

    constructor(props) {
        super(props)

        this.sclose = null

        this.state = {
            status: props.withdraw.status,

            bank_reference: (props.withdraw.bank_reference || ''),

            total_amount: props.withdraw.total_amount / 100,
            total_ezee_fees_amount: props.withdraw.total_ezee_fees_amount / 100,
            total_stripe_fees_amount: props.withdraw.total_stripe_fees_amount / 100,

            total_driver_fees_amount: props.withdraw.total_driver_fees_amount / 100,

            fullname: props.withdraw.fullname,
            IBAN: props.withdraw.IBAN,
            BIC: props.withdraw.BIC
        }
    }

    change() {

        let total_driver_fees_amount = this.state.total_driver_fees_amount

        if (typeof total_driver_fees_amount == 'string') {
            total_driver_fees_amount = parseFloat(total_driver_fees_amount.replace(",", "."))

            if (isNaN(total_driver_fees_amount)) {
                return toast.error('Le montant est invalide (Surement un problème de format).', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        }

        let send = {
            status: this.state.status,

            bank_reference: this.state.bank_reference,
            // amount: amount * 100,

            total_driver_fees_amount: total_driver_fees_amount * 100,


            fullname: this.state.fullname,
            IBAN: this.state.IBAN,
            BIC: this.state.BIC
        }

        EZEEApi.putDriverWithdraw(this.props.withdraw.id, send).then(() => {
            this.props.onClose(send)
            this.sclose()
        })

    }

    formIsValid() {

        return (
            this.state.status !== '' &&

            this.state.bank_reference !== '' &&
            this.state.amount !== '' &&

            this.state.fullname !== '' &&
            this.state.IBAN !== '' &&
            this.state.BIC !== ''
        )
    }

    render() {

        return (
            <Popup modal={true} lockScroll={true} trigger={<button style={{ border: 0 }} className={this.props.className} style={{
                overlay: {
                    overflow: 'scroll'
                }
            }}> {this.props.children} </button>} modal>

                {(close) => {
                    this.sclose = close
                    return (

                        <div id="popup-info" class="popup-box align-left">
                            <div class="close-popup popup-info_close"><img src="images/close-icon.svg" alt="Close" onClick={() => { close() }} /></div>
                            <div class="popup-box-inner align-left">
                                <div class="popup-form-wrapper">
                                    <form class="pt-3" action="#" method="post">
                                        <div class="row align-items-center">
                                            <div class="col-md-2 col-sm-12 pb-3"><p>Chauffeur</p></div>
                                            <div class="col-md-10 col-sm-12 pb-3 s-f-user-pic-bg">
                                                <span class="s-f-user-pic">
                                                    <S3Picture
                                                        type="user-profil"
                                                        file={this.props.withdraw.Driver.User.picture}
                                                    />
                                                </span>
                                                <span class="p-textbox" style={{ 'padding-top': '16px', 'padding-left': '65px' }} >{this.props.withdraw.Driver.User.firstname} {this.props.withdraw.Driver.User.lastname}</span>
                                            </div>
                                        </div>


                                        <div class="row align-items-center">
                                            <div class="col-md-2 col-sm-12 pb-3"><p>C.A Généré</p></div>
                                            <div class="col-md-2 col-sm-12 pb-3"><input disabled type="text" class="p-textbox" value={this.state.total_amount} /></div>

                                            <div class="col-md-2 col-sm-12 pb-3"><p>Frais EZEE</p></div>
                                            <div class="col-md-2 col-sm-12 pb-3"><input disabled type="text" class="p-textbox" value={this.state.total_ezee_fees_amount} /></div>

                                            <div class="col-md-2 col-sm-12 pb-3"><p>Frais bancaire</p></div>
                                            <div class="col-md-2 col-sm-12 pb-3"><input disabled type="text" class="p-textbox" value={this.state.total_stripe_fees_amount} /></div>
                                        </div>

                                        <hr></hr>

                                        <div class="row align-items-center">
                                            <div class="col-md-3 col-sm-12 pb-3"><p>Nom sur le compte</p></div>
                                            <div class="col-md-9 col-sm-12 pb-3"><input type="text" class="p-textbox" value={this.state.fullname} onChange={(e) => { this.setState({ fullname: e.target.value }) }} /></div>
                                        </div>
                                        <div class="row align-items-center">
                                            <div class="col-md-3 col-sm-12 pb-3"><p>IBAN / BIC</p></div>
                                            <div class="col-md-6 col-sm-12 pb-3"><input type="text" class="p-textbox" value={this.state.IBAN} onChange={(e) => { this.setState({ IBAN: e.target.value }) }} /></div>
                                            <div class="col-md-3 col-sm-12 pb-3"><input type="text" class="p-textbox" value={this.state.BIC} onChange={(e) => { this.setState({ BIC: e.target.value }) }} /></div>

                                        </div>


                                        <div class="row align-items-center">
                                            <div class="col-md-3 col-sm-12 pb-3"><p>Montant à reversé (€)</p></div>
                                            <div class="col-md-9 col-sm-12 pb-3"><input type="text" class="p-textbox" value={this.state.total_driver_fees_amount} onChange={(e) => { this.setState({ total_driver_fees_amount: e.target.value }) }} /></div>
                                        </div>

                                        <div class="row align-items-center">
                                            <div class="col-md-3 col-sm-12 pb-3"><p>Numéro de <br />virement</p></div>
                                            <div class="col-md-9 col-sm-12 pb-3"><input type="text" class="p-textbox" value={this.state.bank_reference} onChange={(e) => { this.setState({ bank_reference: e.target.value }) }} placeholder="Veuillez entrer le code du virement" /></div>
                                        </div>

                                        <div class="row align-items-center mt-3">
                                            <div class="col-md-12 col-sm-12 align-right">
                                                <input type="button" class="p-submit-btn theme-button1" value="Virement effectué" onClick={() => this.change()} disabled={!this.formIsValid()} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                    )
                }}

            </Popup>
        );

    }
}

export default WithdrawEditPopup;