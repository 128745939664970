import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import EZEEApi from '../../services/EZEEApi';
import S3Picture from '../S3Picture';
import Moment from 'react-moment';

class TransactionsScreen extends Component {

    constructor(props) {
        super(props)

        this.state = {
            voucher: null
        }

    }

    componentDidMount() {
        this.reloadVoucher()
    }

    reloadVoucher() {
        EZEEApi.getVoucher(this.props.match.params.voucherId).then((voucher) => {
            this.setState({voucher: voucher})
        })
    }

  render() {

    if (this.state.voucher == null) {
        return (<div></div>)
    }

    return (
        <div className="content-area-wrapper pt-5 pb-5">

            <div className="body-bg body-bg1"></div>
            <div className="body-bg body-bg2"></div>
            
            <div className="container">
                <div className="row">
                    <div className="visit-trajet">
                        <NavLink to={`/vouchers`} ><img src="/images/arrow-left-f.svg" alt=""/>Retour aux code promo</NavLink>
                    </div>
                </div>
                
                <div className="white-bg-section shadow-shape2 mb-5">
                    <div className="row detail-table-header-border">
                        <div className="col-12">
                            <div className="detail-table-row-header detail-table-header">
                                <h4>Détail de la transaction</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row detail-table-content-wrapper">

                        <div className="col-12">
                            <div className="detail-table full-width">
                                <div className="detail-table-row empty-row row m-0 align-items-center">
                                </div>    
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell pt-1"><h6>Nom<br />du code</h6></div>
                                        <div className="detail-table-cell">XUAIAGJ10</div>
                                    </div>
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Raison</h6></div>
                                        <div className="detail-table-cell">Offre de bienvenue</div>
                                    </div>    
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Statut</h6></div>
                                        <div className="detail-table-cell">Actif</div>
                                    </div>    
                                </div>                                                  
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Pour qui ?</h6></div>
                                        <div className="detail-table-cell">Client</div>
                                        <div className="detail-table-cell detail-title-cell auto-width ml-5"><h6>Nom du client</h6></div>
                                        <div className="detail-table-cell">Luana Clement</div>
                                    </div>  
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Validité</h6></div>
                                        <div className="detail-table-cell">Du <Moment format="DD/MM/YYYY">{this.state.voucher.begin_at}</Moment> Au <Moment format="DD/MM/YYYY">{this.state.voucher.end_at}</Moment></div>
                                    </div>    
                                </div> 
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell pt-1"><h6>Type<br />de code</h6></div>
                                        <div className="detail-table-cell">Montant</div>
                                        <div className="detail-table-cell right"><a href="#" className="theme-button1">Changer le statut</a></div>
                                    </div>    
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Montant &euro;</h6></div>
                                        <div className="detail-table-cell">10</div>
                                    </div>
                                </div>
                                
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell right mt-3 mb-3"><a href="#" className="theme-button1 theme-button-p">Sauvegarder les modifications</a></div>
                                    </div>
                                </div>
                                        
                            </div>
                        </div>

                        {/* <div className="col-12">
                            <div className="detail-table full-width">
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Ref de la commande</h6></div>
                                        <div className="detail-table-cell">{this.state.transaction.id}</div>
                                        <div className="detail-table-cell pt-2 right"><a  ><span className="report-link">Télécharger la facture (NW)</span></a></div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Date</h6></div>
                                        <div className="detail-table-cell">
                                            <Moment format="DD/MM/YYYY">{this.state.transaction.created_at}</Moment>
                                        </div>
                                    </div>    
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Heure</h6></div>
                                        <div className="detail-table-cell">
                                            <Moment format="HH[h]mm">{this.state.transaction.created_at}</Moment>
                                        </div>
                                    </div>    
                                </div>                                                 
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Adresse</h6></div>
                                        <div className="detail-table-cell">
                                            <div className="detail-user-info"><span className="client-route black">Départ</span>
                                                <span className="address-media">{this.state.transaction.Trip.address_from}</span>
                                            </div>
                                            <div className="detail-user-info"><span className=" client-route black">Arrivée</span>
                                                <span className="address-media">{this.state.transaction.Trip.address_to}</span>
                                            </div>
                                        </div>
                                        </div>  
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Montant total</h6></div>
                                        <div className="detail-table-cell">{this.state.transaction.debited_amount / 100} €</div>
                                        <div className="detail-table-cell detail-title-cell auto-width"><h6>Com<br /> HT</h6></div>
                                        <div className="detail-table-cell">{this.state.transaction.fees_pickmiup_vat_excluded / 100} €</div>
                                        <div className="detail-table-cell detail-title-cell auto-width"><h6>Com<br /> TTC</h6></div>
                                        <div className="detail-table-cell">{this.state.transaction.fees_pickmiup_vat_included / 100} €</div>
                                        <div className="detail-table-cell detail-title-cell auto-width"><h6>Frais<br /> Banc</h6></div>
                                        <div className="detail-table-cell">{this.state.transaction.fees_mango_vat_included / 100} €</div>
                                    </div>
                                </div>
                                <div className="detail-table-row detail-table-data-row row m-0 align-items-center">
                                    <div className="col-12 p-0">
                                        <div className="detail-table-cell detail-title-cell"><h6>Statut</h6></div>
                                        <div className="detail-table-cell">{ `${ this.state.transaction.refunded === true ? 'Remboursé' : 'Accepté' } ` }</div>   
                                        
                                        <div className="detail-table-cell detail-transaction-status-cell pt-3 pb-3">
                                            <p>Remboursement par {

                                                this.state.transaction.refund_type == 1 ? 
                                                    'le chauffeur'
                                                : 
                                                    this.state.transaction.refund_type == 2 ? 
                                                        'le passager'
                                                    :
                                                        "l'administrateur"
                                                
                                            } le <Moment format="DD/MM/YYYY">{this.state.transaction.updated_at}</Moment></p>
                                            <p>Remboursement de {this.state.transaction.refunded_amount / 100} €</p>
                                            <p>Raison du remboursement: </p>
                                            <p>{this.state.transaction.Passenger.left_reason}</p>
                                        </div>  
                                        
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>*/}
                </div>                
            </div>
        </div>
    );
  }
}

export default TransactionsScreen;