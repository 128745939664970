import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import EZEEApi from '../../../services/EZEEApi';
import ReactS3Uploader from 'react-s3-uploader';
import { toast } from 'react-toastify';
import Popup from "reactjs-popup";
import S3FilePopup from  '../../popups/s3file'
import S3Picture from '../../S3Picture';
import moment from 'moment';

class BankAccoutScreen extends Component {

    uploaders = {}

    constructor(props) {
        super(props)

        this.state = {
            fullname: '',
            IBAN: '',
            BIC: '',
        }

    }

    componentDidMount() {

        EZEEApi.getDriverBankAccount('me').then((bankaccount) => {

            this.setState({
                fullname: bankaccount.fullname,
                IBAN: bankaccount.IBAN,
                BIC: bankaccount.BIC,
            })

        })

    }

    formIsValid() {

        return (
            this.state.fullname !== '' && 
            this.state.IBAN !== '' &&
            this.state.BIC !== ''
        )

    }

    updateBankaccount() {
        
        EZEEApi.postDriverBankaccount('me', {
            fullname: this.state.fullname,
            IBAN: this.state.IBAN,
            BIC: this.state.BIC
        }).then(() => {
            toast.success('Le compte bancaire a bien été enregistré.', {
                position: toast.POSITION.TOP_RIGHT
            })
        }).catch((data) => {
            toast.error('Une erreur est survenu, merci de réessayer et de vérifier vos informations.', {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }

    render() {

        return (
            <div class="content-area-wrapper pt-5 pb-5">
                <div class="body-bg body-bg1 body-bg-pos"></div>
                <div class="body-bg body-bg2"></div>
                
                
                <div class="container">	
                    <div class="row mb-5">
                        <div class="col-12">
                            <div class="shadow-shape2 user-content-section white-bg-section pb-4 mb-5rem">
                                <div class="s-padding-sec3 heading-bottom-border">
                                    <h3>Coordonnées bancaires</h3>
                                </div>
                                <div class="s-padding-sec3">    
                                    <div class="row align-items-start">
                                        <div class="col-md-3 col-sm-12 pb-3 pt-3"><p>Titulaire</p></div>
                                        <div class="col-md-9 col-sm-12">
                                            <div class="row">
                                                <div class="col-md-12 pb-3">
                                                    <input type="text" 
                                                    className={`p-textbox ${this.state.fullname === '' && 'error-text'}`}
                                                     placeholder="Nom complet" value={this.state.fullname} onChange={(e) => { this.setState({fullname: e.target.value}) }} /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-md-3 col-sm-12 pb-3"><p>IBAN</p></div>
                                        <div class="col-md-9 col-sm-12 pb-3"><input type="text" 
                                        className={`p-textbox ${this.state.IBAN === '' && 'error-text'}`}
                                         placeholder="ex : FR14 2004 1010 0505 0001 3M02 606" value={this.state.IBAN} onChange={(e) => { this.setState({IBAN: e.target.value}) }} /></div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-md-3 col-sm-12 pb-3"><p>BIC</p></div>
                                        <div class="col-md-9 col-sm-12 pb-3"><input 
                                        type="text" className={`p-textbox ${this.state.BIC === '' && 'error-text'}`} placeholder="ex : SOGEFRPP" value={this.state.BIC} onChange={(e) => { this.setState({BIC: e.target.value}) }} /></div>
                                    </div>
                                                                                                    
                                    <div class="row align-items-center mt-5">
                                        <div class="col-md-12 col-sm-12 pb-3 align-right">
                                            <button disabled={!this.formIsValid()} className="theme-button1" onClick={() => { this.updateBankaccount() }}>Sauvegarder les modifications</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                                                    
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BankAccoutScreen;